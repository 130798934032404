import { useEffect, useRef, useState } from 'react';

export const useDebounce = () => {
  const timeOutRef = useRef<NodeJS.Timeout | null>(null);
  const debounce = (fn: () => void, time: number) => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(fn, time);
  };
  const reset = () => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
  };
  return { debounce, reset };
};

export function useDebounceValue<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
}
