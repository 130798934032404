import { observer } from 'mobx-react-lite';
import { useState, FC } from 'react';

interface CalendarProps {
  onSelect: (date: string) => void;
  selectedDay: number | null;
}

const Calendar: FC<CalendarProps> = ({ onSelect, selectedDay }) => {
  const getDaysInMonth = (month: number, year: number): number => {
    return new Date(year, month + 1, 0).getDate();
  };

  const currentDate = new Date();
  const [year, setYear] = useState<number>(currentDate.getFullYear());
  const [month, setMonth] = useState<number>(currentDate.getMonth());
  const [isYearDropdownVisible, setYearDropdownVisible] =
    useState<boolean>(false);

  const daysInMonth = getDaysInMonth(month, year);
  const firstDayOfMonth = new Date(year, month, 1).getDay();

  const renderCalendarDays = () => {
    const calendarDays = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className='w-14 h-14'></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const isCurrentDay =
        new Date().getDate() === day &&
        new Date().getMonth() === month &&
        new Date().getFullYear() === year;
      calendarDays.push(
        <div
          key={day}
          className={`w-10 h-10 flex flex-col justify-center items-center font-medium cursor-pointer 
                    ${
                      selectedDay === day
                        ? 'rounded-[10px] text-white bg-secondary'
                        : ''
                    } 
                    ${isCurrentDay ? 'text-secondary' : 'text-black'}`}
          onClick={() => handleDayClick(day)}
        >
          {day} {isCurrentDay && '•'}
        </div>,
      );
    }
    return calendarDays;
  };

  const handleDayClick = (day: number) => {
    const formattedMonth = (month + 1).toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    onSelect(formattedDate);
  };

  const goToPreviousMonth = () => {
    if (month === 0) {
      setYear(year - 1);
      setMonth(11);
    } else {
      setMonth(month - 1);
    }
  };

  const goToNextMonth = () => {
    if (month === 11) {
      setYear(year + 1);
      setMonth(0);
    } else {
      setMonth(month + 1);
    }
  };

  const toggleYearDropdown = () => {
    setYearDropdownVisible(!isYearDropdownVisible);
  };

  const handleYearSelect = (selectedYear: number) => {
    setYear(selectedYear);
    setYearDropdownVisible(false);
  };

  const renderYearDropdown = () => {
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let i = 2000; i <= currentYear; i++) {
      years.push(i);
    }

    return (
      <div
        className='absolute bg-white border rounded 
      shadow-lg grid grid-cols-3 w-[250px] h-[30vh]
       overflow-y-auto small-scrollbar mt-2'
      >
        {years.map(yr => (
          <div
            key={yr}
            className='px-4 py-2 cursor-pointer hover:bg-gray-200'
            onClick={() => handleYearSelect(yr)}
          >
            {yr}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='flex flex-col items-center'>
      <div className='flex justify-between items-center w-full px-5 pt-6 pb-3'>
        <div
          className='font-medium text-[20px] cursor-pointer text-gray-400'
          onClick={goToPreviousMonth}
        >
          {'<'}
        </div>
        <div className='relative'>
          <h1
            className='text-[20px] font-medium cursor-pointer'
            onClick={toggleYearDropdown}
          >
            {new Date(year, month).toLocaleString('default', {
              month: 'long',
              year: 'numeric',
            })}
          </h1>
          {isYearDropdownVisible && renderYearDropdown()}
        </div>
        <div
          className='font-medium text-[20px] cursor-pointer text-gray-400'
          onClick={goToNextMonth}
        >
          {'>'}
        </div>
      </div>
      <div className='w-full bg-mainGray h-[1px]'></div>
      <div className='grid grid-cols-7 gap-1 py-2 pl-2'>
        <div className='w-10 h-10 flex justify-center items-center font-medium text-gray-400'>
          Su
        </div>
        <div className='w-10 h-10 flex justify-center items-center font-medium text-gray-400'>
          Mo
        </div>
        <div className='w-10 h-10 flex justify-center items-center font-medium text-gray-400'>
          Tu
        </div>
        <div className='w-10 h-10 flex justify-center items-center font-medium text-gray-400'>
          We
        </div>
        <div className='w-10 h-10 flex justify-center items-center font-medium text-gray-400'>
          Th
        </div>
        <div className='w-10 h-10 flex justify-center items-center font-medium text-gray-400'>
          Fr
        </div>
        <div className='w-10 h-10 flex justify-center items-center font-medium text-gray-400'>
          Sa
        </div>
        {renderCalendarDays()}
      </div>
    </div>
  );
};

export default observer(Calendar);
