import { ReactNode, FC } from 'react';

interface WrapperProps {
  children: ReactNode;
  filter?: ReactNode;
  button?: ReactNode;
  title: string;
}

const Wrapper: FC<WrapperProps> = ({ children, title, filter, button }) => {
  return (
    <div className='flex flex-col h-[90vh] bg-white rounded-[6px] border border/50 m-[25px] p-5'>
      <div className='flex justify-between items-center'>
        <span className='text-[24px] leading-[36px] font-[500]'>{title}</span>
        <div className='flex items-center space-x-4'>
          {filter}
          {button}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Wrapper;
