import Device from '../../../../assets/images/iPhone14.png';
import { MdImage } from 'react-icons/md';
import { AppleIcon } from '@/components/atoms/Icons/AppleIcon';
import { AndroidIcon } from '@/components/atoms/Icons/AndroidIcon';
import Button from '@/components/atoms/Button';

import '@grapecity/wijmo.styles/wijmo.css';
// import generateBarcode from 'pdf417';
import QRCodeStyling from 'qr-code-styling';
import { RefObject, useEffect, useRef, useState } from 'react';
import routeNames from '@/routes/routeNames';
import { notify } from '@/helpers/index';
import { loyaltyStore } from '@/state/LoyaltyStore';
import { observer } from 'mobx-react-lite';
import { APP_BASE_URL } from '@/constants/index';

const qrCode = new QRCodeStyling({
  width: 80,
  height: 80,
  dotsOptions: {
    color: 'black',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 20,
  },
});

function DesignDevices() {
  const myStyle = {
    backgroundImage: `url(${Device})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const idParam: string = urlParams.get('id') || '';
  const [url] = useState(
    `${APP_BASE_URL}/${routeNames.appDownload}?id=${idParam}`,
  );

  useEffect(() => {
    qrCode.append(ref.current || undefined);
  }, [loyaltyStore.activeDevice]);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url, loyaltyStore.activeDevice]);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      `${APP_BASE_URL}/${routeNames.appDownload}?id=${idParam}`,
    );
    notify('success', 'Copied to clipboard!');
  };

  return (
    <>
      <div>
        {loyaltyStore.activeDevice === 0 ? (
          <>
            <div className='flex justify-between bg-white rounded-[5px] py-3 px-3 my-2 mx-2'>
              <div ref={ref}></div>
              <div>
                <div className='flex items-center'>
                  <p className='text-[14px] leading-[20px] font-[500] text-hunterLabLight w-[88px] whitespace-nowrap overflow-hidden overflow-ellipsis'>
                    {`${APP_BASE_URL}/${routeNames.appDownload}?id=${idParam}`}
                  </p>
                  <div onClick={handleCopyUrl} className='cursor-pointer'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z'
                      />
                    </svg>
                  </div>
                </div>
                <div className='mt-[20px]'>
                  <Button
                    type='button'
                    text={'Download App'}
                    loadingText='Saving'
                    size='sm'
                    className='bg-lightBlue'
                  />
                </div>
              </div>
            </div>
            <div
              style={myStyle}
              className='flex w-[292.48px] h-[576px] items-center relative'
            >
              <div className='ml-[35px]'>
                <div
                  className='rounded-[5px]  bg-white w-[226px]  overflow-auto py-6'
                  style={{ backgroundColor: loyaltyStore.cardBGcolor }}
                >
                  <div className='flex justify-between px-4'>
                    {loyaltyStore.bannerLogo ? (
                      <img
                        alt='not found'
                        className='flex w-[70px] h-[60px] items-center justify-center rounded-[5px]'
                        src={loyaltyStore.bannerLogo}
                      />
                    ) : (
                      <div className='flex bg-lightWhite h-[60px] items-center justify-center rounded-[5px]'>
                        <div className=''>
                          <div className='w-[70px] justify-center flex'>
                            <MdImage className='w-6 h-6' />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='text-right'>
                      <p
                        className='text-[12px] leading-[20px] font-[400] text-gray-375'
                        style={{ color: loyaltyStore.textLabelColor }}
                      >
                        Balance
                      </p>
                      <p
                        className='text-[18px] leading-[20px] font-[500]'
                        style={{ color: loyaltyStore.textValueColor }}
                      >
                        500
                      </p>
                    </div>
                  </div>

                  <div className='flex bg-lightWhite h-[100px] mx-2 items-center justify-center mt-[30px] rounded-[5px]'>
                    {loyaltyStore.bgImage ? (
                      <img
                        alt='not found'
                        className='flex w-[250px] h-[100px] items-center justify-center rounded-[5px]'
                        src={loyaltyStore.bgImage}
                      />
                    ) : (
                      <div className=''>
                        <div className='w-[107px] justify-center flex'>
                          <MdImage className='w-10 h-10' />
                        </div>
                        <p className='text-[12px] leading-[20px] font-[500] '>
                          Background image
                        </p>
                      </div>
                    )}
                  </div>

                  <div className='flex justify-between px-4 mt-[20px]'>
                    <div className='max-w-[45%] whitespace-nowrap'>
                      <p
                        className='text-[12px] leading-[20px] font-[400] text-gray-375 text-ellipsis overflow-hidden'
                        style={{ color: loyaltyStore.textLabelColor }}
                      >
                        Reward
                      </p>
                      <p
                        className='text-[18px] leading-[20px] font-[500] text-ellipsis overflow-hidden'
                        style={{ color: loyaltyStore.textValueColor }}
                      >
                        {loyaltyStore.fieldObj.Reward
                          ? loyaltyStore.fieldObj.Reward
                          : 'No Data'}
                      </p>
                    </div>
                    <div className='text-right max-w-[45%] whitespace-nowrap'>
                      <p
                        className='text-[12px] leading-[20px] font-[400] text-gray-375 text-ellipsis overflow-hidden'
                        style={{ color: loyaltyStore.textLabelColor }}
                      >
                        Balance
                      </p>
                      <p
                        className='text-[18px] leading-[20px] font-[500] text-ellipsis overflow-hidden'
                        style={{ color: loyaltyStore.textValueColor }}
                      >
                        {loyaltyStore.fieldObj.Balance
                          ? loyaltyStore.fieldObj.Balance
                          : 'No Data'}
                      </p>
                    </div>
                  </div>
                  <div className='flex items-center justify-center mt-[30px]'>
                    {/* <img
                      src={generateBarcode(
                        `${APP_BASE_URL}/${routeNames.appDownload}?id=${idParam}`,
                        2,
                        1,
                      )}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='flex justify-between rounded-[5px]  bg-white overflow-auto py-3 px-4 my-3'>
              <div ref={ref}></div>
              <div>
                <div className='flex items-center'>
                  <p className='text-[14px] leading-[20px] font-[500] text-hunterLabLight w-[88px] whitespace-nowrap overflow-hidden overflow-ellipsis'>
                    {`${APP_BASE_URL}/${routeNames.appDownload}?id=${idParam}`}
                  </p>
                  <div onClick={handleCopyUrl} className='cursor-pointer'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z'
                      />
                    </svg>
                  </div>
                </div>
                <div className='mt-[20px]'>
                  <Button
                    type='button'
                    text={'Download App'}
                    loadingText='Saving'
                    size='sm'
                    className='bg-lightBlue'
                  />
                </div>
              </div>
            </div>

            <div
              className='w-[292.48px] h-[570px] rounded-[25px] pt-5 relative overflow-hidden'
              style={{ backgroundColor: loyaltyStore.cardBGcolor }}
            >
              <div className='flex align-center mx-4 items-center'>
                {loyaltyStore.bannerLogo ? (
                  <img
                    alt='not found'
                    className='flex w-[40px] h-[40px] items-center justify-center rounded-[50%]'
                    src={loyaltyStore.bannerLogo}
                  />
                ) : (
                  <img
                    alt='not found'
                    className='flex w-[40px] h-[40px] items-center justify-center rounded-[50%]'
                    src={
                      'https://storage.googleapis.com/wallet-lab-tools-codelab-artifacts-public/pass_google_logo.jpg'
                    }
                  />
                )}
                <p className='text-[12px] leading-[20px] font-[400] text-black ml-4'>
                  [TEST ONLY] Google I/O &nbsp;22
                </p>
              </div>

              <div className='w-full h-[1px] bg-gray-100 mt-3' />

              <div className='mx-4 mt-3'>
                <p className='text-[14px] leading-[20px] font-[400] text-black'>
                  Attendee
                </p>
                <p className='text-[20px] leading-[30px] font-[400] text-black'>
                  Alex McJacobs
                </p>
              </div>

              <div className='flex justify-between px-4 mt-5'>
                <div className='max-w-[45%] whitespace-nowrap'>
                  <p className='text-[12px] leading-[20px] font-[400] text-black text-ellipsis overflow-hidden'>
                    Reward
                  </p>
                  <p className='text-[18px] leading-[25px] font-[500] text-black text-ellipsis overflow-hidden'>
                    {loyaltyStore.fieldObj.Reward
                      ? loyaltyStore.fieldObj.Reward
                      : 'No Data'}
                  </p>
                </div>
                <div className='text-right max-w-[45%] whitespace-nowrap'>
                  <p className='text-[12px] leading-[20px] font-[400] text-black text-ellipsis overflow-hidden'>
                    Balance
                  </p>
                  <p className='text-[18px] leading-[25px] font-[500] text-black text-ellipsis overflow-hidden'>
                    {loyaltyStore.fieldObj.Balance
                      ? loyaltyStore.fieldObj.Balance
                      : 'No Data'}
                  </p>
                </div>
              </div>

              <div className='flex items-center justify-center w-full h-[49%]'>
                {/* <img
                src={generateBarcode(
                  `${APP_BASE_URL}/${routeNames.appDownload}?id=${idParam}`,
                  2,
                  1,
                )}
              /> */}
              </div>

              <div className='flex align-center justify-center right-0 left-0 bottom-0'>
                {loyaltyStore.bgImage ? (
                  <img
                    alt='not found'
                    className='flex w-full h-[100px] items-center justify-center'
                    src={loyaltyStore.bgImage}
                  />
                ) : (
                  <img
                    alt='not found'
                    className='flex w-full h-[100px] items-center justify-center'
                    src={
                      'https://storage.googleapis.com/wallet-lab-tools-codelab-artifacts-public/google-io-hero-demo-only.png'
                    }
                  />
                )}
              </div>
            </div>
          </>
        )}

        <div className='flex justify-center mt-[20px]'>
          <div
            className={`${
              loyaltyStore.activeDevice === 0
                ? 'bg-white border border-gray-350 flex justify-center items-center p-2 rounded-[16px]'
                : 'flex justify-center items-center p-2'
            } cursor-pointer`}
            onClick={() => loyaltyStore.setActiveDevice(0)}
          >
            <AppleIcon width='40' height='40' className='' />
          </div>
          <div
            className={`${
              loyaltyStore.activeDevice === 1
                ? 'bg-white flex justify-center items-center p-2 rounded-[16px] border border-gray-350 ml-6'
                : 'flex justify-center items-center p-2 ml-6'
            } cursor-pointer`}
            onClick={() => loyaltyStore.setActiveDevice(1)}
          >
            <AndroidIcon width='40' height='40' className='' />
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(DesignDevices);
