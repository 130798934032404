import { useEffect, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import { useLoyalty } from '@/hooks/useLoyalty';
import Modal from '@/components/atoms/modal';
import Text from '@/components/atoms/Text';
import Chat from '../../../../../components/molecules/FilterTabs/chat.png';
import { loyaltyStore } from '@/state/LoyaltyStore';

const SendNotificationPopup = ({
  closeModalSend,
  msg,
  onSubmit,
  loading,
}: {
  closeModalSend: () => void;
  msg: string;
  onSubmit: () => void;
  loading: boolean;
}) => {
  return (
    <div className='px-4 mt-[10px] '>
      <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
        You sending push to customers
      </p>

      <div
        className='flex items-center justify-center rounded-[6px] 
                                    border border-border bg-gray-25 py-4 mt-3'
      >
        <img src={Chat} alt='chat' className='w-[30px] h-[30px]' />

        <div className='ml-3'>
          <p className='text-[14px] font-[400] leading-[20px] text-black'>
            10 Customers
          </p>

          <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
            received your message
          </p>
        </div>
      </div>

      <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words mt-3'>
        Text
      </p>

      <Text size='lg' className='mt-2'>
        {msg}
      </Text>

      <div className='mt-9 text-center flex justify-between space-x-4'>
        <div className='flex flex-1'>
          <Button
            size='sm'
            text='Send'
            type='submit'
            className='hover:bg-primary-medium w-full'
            onClick={onSubmit}
            disabled={loading}
          />
        </div>
        <div className='flex flex-1'>
          <Button
            size='sm'
            text='Cancel'
            onClick={closeModalSend}
            variant='outline'
            className='w-full'
          />
        </div>

        <div className='flex flex-1'>
          <Button
            size='sm'
            text='Save as template'
            className='bg-gray-100'
            variant='outline'
          />
        </div>
      </div>
    </div>
  );
};
const SendNotification = () => {
  const [msg, setMsg] = useState<string>('');
  const [openSendNoti, setOpenSendNoti] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<boolean>(false);

  const { sendNotification } = useLoyalty();

  useEffect(() => {
    return () => {
      loyaltyStore.setNotifyMsg('');
    };
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const idParam: string = urlParams.get('id') || '';
    const req = {
      notificationTitle: msg,
      dePassKitId: idParam,
    };

    sendNotification(req, (res: any) => {
      if (res) {
        setLoading(false);
        setOpenSendNoti(false);
      }
    });
  };

  const openModalSend = () => {
    if (msg.trim() == '') {
      setMsgError(true);
    } else {
      setOpenSendNoti(true);
    }
  };

  const closeModalSend = () => {
    setOpenSendNoti(false);
  };

  return (
    <>
      <div className='w-full auto flex flex-col h-full'>
        <div className='w-full mb-[24px]'>
          <div className='flex flex-col  flex-1'>
            <textarea
              id='textarea'
              value={msg}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setMsg(event.target.value);
                loyaltyStore.setNotifyMsg(event.target.value);
                setMsgError(false);
              }}
              onKeyPress={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                setMsg((event.target as HTMLTextAreaElement).value);
                loyaltyStore.setNotifyMsg(
                  (event.target as HTMLTextAreaElement).value,
                );
                setMsgError(false);
              }}
              className={`outline-none  z-[2]  text-[12px] resize-vertical resize-none rounded-[6px] 
                                    border  leading-[20px] py-[6px] px-[12px] ${
                                      msgError
                                        ? 'border-darkRed  bg-lightRed'
                                        : 'border-border'
                                    }`}
              rows={6}
              placeholder='Message'
              maxLength={1000}
            />
          </div>
        </div>

        <div className='flex mt-[28px] w-full justify-end space-x-4'>
          <Button
            type='button'
            text={'Send'}
            size='sm'
            className='bg-lightBlue'
            onClick={openModalSend}
          />
        </div>
      </div>
      <Modal
        show={openSendNoti}
        openModal={openModalSend}
        closeModal={closeModalSend}
        title='Send push'
        className='z-[10000] inline-block py-[24px] px-[2px] w-[100%] max-w-[550px] text-left align-center  transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <SendNotificationPopup
          closeModalSend={closeModalSend}
          onSubmit={onSubmit}
          msg={msg}
          loading={loading}
        />
      </Modal>
    </>
  );
};
export default observer(SendNotification);
