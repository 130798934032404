export const GreetingIcon = () => {
  return (
    <svg
      width='20'
      height='17'
      viewBox='0 0 20 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 8V2C13 0.90625 12.0938 0 11 0H2C0.875 0 0 0.90625 0 2V8C0 9.125 0.875 10 2 10L3 10.0312V11.7188C3 11.9688 3.28125 12.0938 3.46875 11.9688L6.0625 10.0312L11 10C12.0938 10 13 9.125 13 8ZM18 4H14V8C14 9.65625 12.625 11 11 11H8V12C8 13.125 8.875 14 9.96875 14H13.875L16.4688 15.9375C16.6875 16.0938 17 15.9688 17 15.7188V14H18C19.0938 14 20 13.125 20 12.0312V6C20 4.90625 19.0938 4 18 4Z'
        fill='#2173F0'
      />
    </svg>
  );
};
