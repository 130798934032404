import { FC, useEffect, useMemo, useState } from 'react';
import { UploadIcon } from '@/components/atoms/Icons/UploadIcon';
import { observer } from 'mobx-react-lite';
import ToggleSwitch from '@/components/atoms/ToggleSwitch';
import { useUpload } from '@/hooks/useUpload';
import { loyaltyStore } from '@/state/LoyaltyStore';
import Button from '@/components/atoms/Button';
import Modal from '@/components/atoms/modal';
import CropImage from '@/components/molecules/CropImage';

const PushIcon: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const { uploading, upload, response } = useUpload();
  const [fileError, setFileError] = useState('');
  const [image, setImage] = useState<any>(null);
  const [cropImage, setCropImage] = useState<string>('');
  const [openCropModel, setOpenCropModel] = useState<boolean>(false);

  useEffect(() => {
    setCropImage(loyaltyStore.notificationLogo);
  }, []);

  const onSubmit = () => {
    if (!response?.payload.url && !loyaltyStore.notificationLogo) {
      setFileError('Please select a file.');
      return;
    }
    loyaltyStore.setNotificationLogo(
      response?.payload.url
        ? response?.payload.url
        : loyaltyStore.notificationLogo,
    );
    handleCancel();
  };

  const onClose = () => {
    setCropImage(
      getMode == 'update'
        ? loyaltyStore.loyalty
          ? loyaltyStore.loyalty?.common.notificationLogo
          : ''
        : '',
    );
    loyaltyStore.setNotificationLogo(
      getMode == 'update'
        ? loyaltyStore.loyalty
          ? loyaltyStore.loyalty?.common.notificationLogo
          : ''
        : '',
    );
    handleCancel();
  };

  const handleFileChange = (e: any) => {
    e.preventDefault();
    let files: any;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (!files[0]) {
        setFileError('Please select a file.');
        return;
      }
      const maxSize = 50 * 1024; // 50 KB
      if (files[0].size > maxSize) {
        setFileError('File size exceeds the maximum limit of 50 KB.');
        return;
      } else {
        setImage(reader.result as any);
        loyaltyStore.setShowLogosError(false);
        openModalSend();
        setFileError('');
      }
      e.target.value = null;
    };
    reader.readAsDataURL(files[0]);
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  const openModalSend = () => {
    setOpenCropModel(true);
  };

  const closeModalSend = () => {
    setOpenCropModel(false);
  };

  return (
    <>
      <div className='w-full auto flex flex-col h-full '>
        <div className='relative rounded-[4px] h-[152px]  w-full flex flex-col justify-center items-center  border-[2px] border-border border-dashed bg-white my-[24px]'>
          <label
            htmlFor='logo'
            className='relative  flex flex-col justify-center items-center  cursor-pointer'
          >
            <UploadIcon />
            <p className='text-[14px] font-[500] text-lightBlack mt-[8px] mb-[6px]'>
              <span className='text-[14px] font-[500] text-lightBlue'>
                Upload a logo{' '}
              </span>{' '}
              or drag and drop
            </p>
            <p className='font-[400] text-[12px] text-gray-400'>
              The resolution of the logo must be 50x100
            </p>
            <input
              type='file'
              id='logo'
              onChange={handleFileChange}
              className='absolute left-0 right-0 w-[20px] invisible'
              accept='image/png'
            />
            {cropImage ? (
              <img
                alt='not found'
                className='absolute w-[260px] h-[144px] rounded-[5px] bg-[white]'
                src={cropImage}
              />
            ) : null}
          </label>
        </div>
        {fileError && (
          <p className='mb-[6px]' style={{ color: 'red' }}>
            {fileError}
          </p>
        )}
        <div className='flex flex-row   items-center justify-between bg-gray-150 rounded-[4px] shadow-sm p-[16px]'>
          <p className='font-[600] text-[12px]'>
            Show logo at card issuing form
          </p>
          <ToggleSwitch />
        </div>

        <div className='flex mt-[28px] w-full justify-end space-x-4'>
          <Button
            type='button'
            text='Cancel'
            size='sm'
            onClick={onClose}
            variant='outline'
            disabled={uploading}
          />
          <Button
            type='button'
            text={'Save'}
            loadingText='Saving'
            size='sm'
            className='bg-lightBlue'
            onClick={onSubmit}
            disabled={uploading}
          />
        </div>
      </div>
      <Modal
        show={openCropModel}
        openModal={openModalSend}
        closeModal={closeModalSend}
        title='Crop an image'
        className='z-[10000] inline-block py-[24px] px-[2px] w-[100%] max-w-[550px] text-left align-center justify-center transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <>
          <CropImage
            closeModalSend={closeModalSend}
            setCropImage={setCropImage}
            selectedImage={image}
            uploadImage={(file: any) => upload(file)}
            type='pushIcon'
          />
        </>
      </Modal>
    </>
  );
};
export default observer(PushIcon);
