export const MarketIcon = () => {
  return (
    <svg
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.59375 1.09375C7.9375 1.28125 8.09375 1.75 7.90625 2.125L5.34375 7H12.625L10.0625 2.125C9.875 1.75 10.0312 1.28125 10.375 1.09375C10.75 0.90625 11.2188 1.0625 11.4062 1.40625L14.3125 7H17C17.5312 7 18 7.46875 18 8C18 8.5625 17.5312 9 17 9L15.375 15.5C15.125 16.375 14.3438 17 13.4375 17H4.53125C3.625 17 2.84375 16.375 2.59375 15.5L1 9C0.4375 9 0 8.5625 0 8C0 7.46875 0.4375 7 1 7H3.65625L6.5625 1.40625C6.75 1.0625 7.21875 0.90625 7.59375 1.09375ZM4.5 10.25C4.90625 10.25 5.25 9.9375 5.25 9.5C5.25 9.09375 4.90625 8.75 4.5 8.75C4.0625 8.75 3.75 9.09375 3.75 9.5C3.75 9.9375 4.0625 10.25 4.5 10.25ZM13.5 8.75C13.0625 8.75 12.75 9.09375 12.75 9.5C12.75 9.9375 13.0625 10.25 13.5 10.25C13.9062 10.25 14.25 9.9375 14.25 9.5C14.25 9.09375 13.9062 8.75 13.5 8.75Z'
        fill='#2173F0'
      />
    </svg>
  );
};
