export const AddCodeSnippetIcon = () => {
  return (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2C0 0.90625 0.875 0 2 0H14C15.0938 0 16 0.90625 16 2V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V2ZM3 4C3.53125 4 4 3.5625 4 3C4 2.46875 3.53125 2 3 2C2.4375 2 2 2.46875 2 3C2 3.5625 2.4375 4 3 4ZM14 3C14 2.59375 13.6562 2.25 13.25 2.25H5.75C5.3125 2.25 5 2.59375 5 3C5 3.4375 5.3125 3.75 5.75 3.75H13.25C13.6562 3.75 14 3.4375 14 3Z'
        fill='#2173F0'
      />
    </svg>
  );
};
