import Button from '@/components/atoms/Button';
import EmptyList from '@/components/atoms/EmptyList';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';
import Spinner from '@/components/atoms/Spinner';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { emailMarketingStore } from '@/state/EmailMarketingStore';
import { replaceRouteParam } from '@/utils/routes';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from './Wrapper';

const Templates = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);
  const { getTemplates, deleteTemplate } = useEmailMarketing();
  const templates = emailMarketingStore?.templates;
  // const options: FilterTabOption[] = [
  //   { id: 0, title: 'All', icon: null, type: 'tab' },
  //   { id: 1, title: 'Restaurant', icon: null, type: 'tab' },
  //   { id: 2, title: 'Cannabis', icon: null, type: 'tab' },
  // ];

  // const OPTIONS_FILTERS = [
  //   {
  //     label: 'All',
  //     value: 'All',
  //   },
  //   {
  //     label: 'Restaurant',
  //     value: 'Restaurant',
  //   },
  //   {
  //     label: 'Cannabis',
  //     value: 'Cannabis',
  //   },
  // ];

  // const handleDropdownChange = (index: number) => {
  //   setActiveTab(index);
  // };

  const handleDeleteClick = (id: string) => {
    setTemplateToDelete(id);
    setIsOpen(true);
  };

  const handleConfirmDelete = () => {
    if (templateToDelete) {
      deleteTemplate(templateToDelete);
      setTemplateToDelete(null);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      {emailMarketingStore.loading ? (
        <div className='h-[80vh] flex justify-center bg-white rounded-[16px] m-6'>
          <Spinner color='blue' size={50} />
        </div>
      ) : (
        <Wrapper
          title='Templates'
          button={
            <Button
              onClick={() =>
                navigate(
                  replaceRouteParam(
                    routeNames.dashboard.emailTemplates,
                    'workspaceId',
                    AuthStore.user_workspace_info.active_workspace.workspace
                      ._id,
                  ),
                )
              }
              LeftIcon={
                <Icon
                  icon='heroicons-solid:plus'
                  color='#FFFFFF'
                  className='w-[20px] h-[16px]'
                />
              }
              text='New Template'
              type='button'
              className='w-[150px!important] justify-between items-center'
            />
          }
          filter={
            null
            // <Dropdown
            //   options={options}
            //   currentOption={OPTIONS_FILTERS[activeTab].value}
            //   onChange={handleDropdownChange}
            //   activeTab={activeTab}
            // />
          }
        >
          {!templates?.length ? (
            <div className='mt-4'>
              <EmptyList
                listName={''}
                title='There are no templates'
                subTitle='Add templates to see them here'
                height='70vh'
              />
            </div>
          ) : (
            <div className='flex flex-row flex-wrap gap-y-10 pt-8 items-center overflow-y-auto no-scrollbar'>
              {templates?.map((item, index) => (
                <div
                  key={index}
                  className='relative mb-2 mr-10 bg-mainGray w-[290px] h-[400px] rounded-[5px] overflow-hidden group'
                >
                  <img
                    src={item?.thumbnail}
                    className='relative w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105'
                    alt='template image'
                  />
                  <div className='absolute inset-0 flex flex-col gap-y-2 justify-center items-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-300 ease-in-out'>
                    <span className='text-white text-[18px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out'>
                      {item.name}
                    </span>
                    <Button
                      onClick={() =>
                        navigate(
                          replaceRouteParam(
                            `${routeNames.dashboard.emailTemplates}/${item._id}`,
                            'workspaceId',
                            AuthStore.user_workspace_info.active_workspace
                              .workspace._id,
                          ),
                        )
                      }
                      text='Edit Template'
                      type='button'
                      className='opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out'
                    />
                    <Icon
                      onClick={() => handleDeleteClick(item._id)}
                      icon='ph:trash-thin'
                      className='text-red-500 h-6 w-6 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out'
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Wrapper>
      )}
      <ConfirmationModal
        title='Delete Template'
        content='Are you sure you want to delete this template?'
        show={isOpen}
        setShow={setIsOpen}
        confirmText='Done'
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default observer(Templates);
