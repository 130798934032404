import { FC, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editor.css';

const TextEditor: FC<any> = ({ value, setValue, placeholder }) => {
  const editorRef = useRef(null);

  const handleChange = (content: string) => {
    const cleanedContent = content.trim();
    const normalizedContent = cleanedContent.replace(/<p>\s*<\/p>/g, '');
    if (normalizedContent === '' || normalizedContent === '<p><br></p>') {
      setValue('');
    } else {
      setValue(cleanedContent);
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['link', 'image'],
      [
        { list: 'bullet' },
        { list: 'ordered' },
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
  };

  return (
    <ReactQuill
      theme='snow'
      value={value}
      onChange={handleChange}
      modules={modules}
      ref={editorRef}
      placeholder={placeholder}
    />
  );
};

export default TextEditor;
