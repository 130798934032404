import { AuthStore } from '@/state/AuthenticationStore';
import { replaceRouteParam } from '@/utils/routes';
import { NavigateOptions, useNavigate } from 'react-router-dom';

export const useCustomNavigation = () => {
  const navigate = useNavigate();

  const navigateWithWorkspaceId = (path: string, options?: NavigateOptions) => {
    navigate(
      replaceRouteParam(
        path,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      options,
    );
  };

  return navigateWithWorkspaceId;
};
