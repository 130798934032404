export const SecureDomainsIcon = () => {
  return (
    <svg
      width='15'
      height='17'
      viewBox='0 0 15 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.09375 8.3125L5.40625 11L6.6875 12.3125C7.09375 12.6875 7.09375 13.3438 6.6875 13.7188C6.3125 14.125 5.65625 14.125 5.28125 13.7188L4 12.4375L3.40625 13L4.6875 14.3125C5.09375 14.6875 5.09375 15.3438 4.6875 15.7188C4.3125 16.125 3.65625 16.125 3.28125 15.7188L1.28125 13.7188C0.875 13.3438 0.875 12.6875 1.28125 12.3125L6.6875 6.90625C6.25 6.21875 5.96875 5.40625 5.96875 4.5C5.96875 2.03125 8 0 10.5 0C12.9688 0 15 2.03125 15 4.5C15 7 12.9688 9 10.5 9C9.59375 9 8.78125 8.75 8.09375 8.3125ZM10.5 7C11.875 7 13 5.90625 13 4.5C13 3.125 11.875 2 10.5 2C9.09375 2 8 3.125 8 4.5C8 5.90625 9.09375 7 10.5 7Z'
        fill='#2173F0'
      />
    </svg>
  );
};
