/* eslint-disable @typescript-eslint/no-explicit-any */
import Icon from '@/assets/Icons';
import Icons from '@/assets/Icons/icons.json';
import { ChatIcon, Loyalty, SMS, Whatsapp } from '@/assets/Icons/inbox';
import Text from '@/components/atoms/Text';
import Modal from '@/components/atoms/modal';
import RouteNavItem from '@/components/molecules/RouteNav';
import { NewListForm } from '@/components/templates/forms/NewListForm';
import { useContact } from '@/hooks/useContact';
import { useList } from '@/hooks/useList';
import routeNames from '@/routes/routeNames';
import { contactStore } from '@/state/ContactStore';
import { listStore } from '@/state/ListStore';
import { ViewGridAddIcon } from '@heroicons/react/outline';
import { AiOutlineRadiusSetting, AiOutlineSetting } from 'react-icons/ai';
import { HiOutlineMail } from 'react-icons/hi';
import { HiOutlineHome } from 'react-icons/hi2';

import { InboxIcon } from '@/components/atoms/Icons/InboxIcon';
import { useChatConversation } from '@/hooks/useChatConversation';
import { AuthStore } from '@/state/AuthenticationStore';
import { ConStore } from '@/state/ConversationStore';
import { CONVERSATION_CHANNEL } from '@/types/conversation.types';
import { replaceRouteParam } from '@/utils/routes';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

const renderInboxChannelIcon = (channel: CONVERSATION_CHANNEL) => {
  switch (channel) {
    case CONVERSATION_CHANNEL.SMS:
      return <SMS />;
    case CONVERSATION_CHANNEL.WHATSAPP:
      return <Whatsapp />;
    case CONVERSATION_CHANNEL.CHAT:
      return <ChatIcon />;
    default:
      return null;
  }
};

const Sidebar = () => {
  const { createList, getLists, closeModal, openModal, open } = useList();
  const { get_unread_channel_conversations_count } = useChatConversation();
  const { getContactStats } = useContact();
  const hash = window.location.hash;

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }
  }, [hash]);

  const lists = listStore.lists.map(list => {
    return {
      name: list.name,
      icon: null,
      path: `list/${list._id}`,
      count: list.total_contacts,
      isList: true,
    };
  });

  const routes = [
    {
      name: 'Dashboard',
      icon: <HiOutlineHome size={17} color='white' />,
      path: replaceRouteParam(
        `${routeNames.dashboard.home}`,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      isReady: true,
    },
    {
      name: 'Inbox',
      icon: <InboxIcon />,
      path: replaceRouteParam(
        routeNames.dashboard.chat,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      children: ConStore.unread_channel_conversations_counts.map(channel => ({
        ...channel,
        icon: renderInboxChannelIcon(channel.name as CONVERSATION_CHANNEL),
      })),
      isReady: true,
    },
    {
      name: 'Contacts',
      icon: <Icon icon={Icons.contacts} size={30} color='white' />,
      path: replaceRouteParam(
        routeNames.dashboard.contacts,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      isReady: true,
      children: [
        ...contactStore.contactStats,
        ...lists,
        {
          name: (
            <div className='flex items-center space-x-2'>
              <Icon icon={Icons.plus} color='white' />
              <Text size='sm' color='text-white'>
                Add favorite list
              </Text>
            </div>
          ),
          isButton: true,
        },
      ],
    },
    {
      name: 'Loyalty',
      icon: (
        <span className='w-4 h-4 mr-3.5'>
          <Loyalty />
        </span>
      ),
      path: replaceRouteParam(
        routeNames.dashboard.loyalty,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      children: [
        {
          name: 'Card Type',
          icon: null,
          path: 'card-type',
        },
        // {
        //   name: 'Setting',
        //   icon: null,
        //   path: 'setting',
        // },
        // {
        //   name: 'Design',
        //   icon: null,
        //   path: 'design',
        // },
      ],
      isReady: import.meta.env.VITE_NODE_ENV === 'development',
    },
    {
      name: 'Configurations',
      icon: <AiOutlineRadiusSetting className='w-4 h-4 mr-3.5' color='white' />,
      path: replaceRouteParam(
        routeNames.dashboard.configurations,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      children: [
        { name: 'Styling', icon: null, path: '#styling' },
        { name: 'General', icon: null, path: '#general' },
        { name: 'Offers', icon: null, path: '#offers' },
        { name: 'Setup', icon: null, path: '#setup' },
        { name: 'SparkGPT', icon: null, path: '#sparkgpt' },
      ],
      isReady: true,
    },
    {
      name: 'Email Marketing',
      icon: (
        <HiOutlineMail className='w-4 h-4 mr-3.5 stroke-1.5' color='white' />
      ),
      path: replaceRouteParam(
        routeNames.dashboard.emailMarketing,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      children: [
        { name: 'Analytics', icon: null, path: '' },
        { name: 'Templates', icon: null, path: 'templates' },
        { name: 'Campaigns', icon: null, path: 'campaigns' },
        { name: 'Setup', icon: null, path: 'setup' },
      ],
      isReady: import.meta.env.VITE_NODE_ENV === 'development',
    },
    {
      name: 'Integrations',
      icon: <ViewGridAddIcon className='w-4 h-4' color='white' />,
      path: replaceRouteParam(
        routeNames.dashboard.integration,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ) as any,
      isReady: import.meta.env.VITE_NODE_ENV === 'development',
    },
    {
      name: 'Settings',
      icon: <AiOutlineSetting className='w-4 h-4 mr-3.5' color='white' />,
      path: replaceRouteParam(
        routeNames.dashboard.settings,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      isReady: true,
      children: [
        { name: 'General Settings', icon: null, path: '', count: null },
        { name: 'Teammates', icon: null, path: 'teammates', count: null },
        // TODO: Uncomment when the feature is ready
        // {
        //   name: (
        //     <ToolTip title='Coming soon'>
        //       <Text size='sm' color='text-white'>
        //         Teams & roles
        //       </Text>
        //     </ToolTip>
        //   ),
        // },
        //{ name: 'Office Hours', icon: null, path: 'office-hours', count: null },
        // {
        //   name: 'Blocked people',
        //   icon: null,
        //   path: 'blocked-people',
        //   count: null,
        // },
        {
          name: 'Custom Fields',
          icon: null,
          path: 'custom-fields',
          count: null,
        },
      ],
    },
  ];

  useEffect(() => {
    getLists();
    getContactStats();
    get_unread_channel_conversations_count();
  }, [
    contactStore?.contacts?.length,
    contactStore?.recentViewContacts?.length,
    contactStore?.myContacts?.length,
  ]);
  return (
    <div className='hidden md:flex h-[calc(100vh-49px)]'>
      <RouteNavItem routes={routes as any} handleButtonClick={openModal} />
      <Modal
        show={open}
        openModal={openModal}
        closeModal={closeModal}
        title='Create List'
        className='z-[10000] inline-block py-6 my-8 w-[100%] max-w-[440px] text-left align-center  transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <NewListForm closeModal={closeModal} handleSubmitList={createList} />
      </Modal>
    </div>
  );
};

export default observer(Sidebar);
