import { SOCKET_EVENT_NAMES } from '@/constants/socket.events';
import { notify } from '@/helpers/index';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import {
  EmailCampaignFilter,
  emailMarketingStore,
} from '@/state/EmailMarketingStore';
import { SocketResponse } from '@/types/socketResponse.type';
import { useCustomNavigation } from '../useNavigation';

type GetCampaignsParams = {
  page?: number;
  limit?: number;
  filter?: EmailCampaignFilter;
};

export const useEmailMarketing = () => {
  const navigate = useCustomNavigation();
  const checkDomain = () => {
    emailMarketingStore.setLoading(true);
    emailMarketingStore.setShowSpinner(false);
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.GET_DOMAIN_RECORDS,
      {},
      (response: SocketResponse<any>) => {
        if (response.error) {
          notify('error', response.error);
        } else if (response.data) {
          emailMarketingStore.setSetupResponse(response.data);
          emailMarketingStore.setVerificationStatus(response.data.status);
          if (response.data.verified) {
            emailMarketingStore.setReady();
          }
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const disconnectDomain = () => {
    emailMarketingStore.setShowSpinner(true);
    emailMarketingStore.setLoading(true);
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.DISCONNECT_DOMAIN,
      {},
      (response: SocketResponse<any>) => {
        if (response.error) {
          notify('error', response.error);
        } else {
          emailMarketingStore.setSetupResponse(null);
          emailMarketingStore.setVerificationStatus('awaiting-submission');
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const verifyDomain = (values: { domain: string; senderName: string }) => {
    console.log('Value:', values);
    emailMarketingStore.setLoading(true);
    emailMarketingStore.setShowSpinner(false);
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.VERIFY_DOMAIN,
      { event_name: SOCKET_EVENT_NAMES.VERIFY_DOMAIN, data: values },
      (response: SocketResponse<any>) => {
        if (response.error) {
          notify('error', response.error);
        } else if (response.data) {
          emailMarketingStore.setVerificationStatus('pending');
          emailMarketingStore.setSetupResponse(response.data);
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const saveTemplate = (data: any) => {
    emailMarketingStore.setLoading(true);
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.SAVE_EMAIL_TEMPLATE,
      {
        event_name: SOCKET_EVENT_NAMES.SAVE_EMAIL_TEMPLATE,
        data: data,
      },
      (response: SocketResponse<any>) => {
        if (!response.error) {
          notify('success', 'Saved successfully');
          navigate(
            `${routeNames.dashboard.emailTemplates}/${response.data._id}`,
            { replace: true },
          );
        } else {
          notify('error', response.error);
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const getTemplates = () => {
    emailMarketingStore.setLoading(true);
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.GET_EMAIL_TEMPLATES,
      { event_name: SOCKET_EVENT_NAMES.GET_EMAIL_TEMPLATES, data: null },
      (response: any) => {
        emailMarketingStore.setTemplates(response);
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const deleteTemplate = (id: string) => {
    emailMarketingStore.setLoading(true);
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.DELETE_EMAIL_TEMPLATE,
      { event_name: SOCKET_EVENT_NAMES.DELETE_EMAIL_TEMPLATE, data: { id } },
      (response: SocketResponse<any>) => {
        if (!response.data) {
          getTemplates();
          notify('success', 'Deleted successfully');
        } else if (response.error) {
          notify('error', response.error);
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const createCampaign = (data: any) => {
    emailMarketingStore.setLoading(true);
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.CREATE_EMAIL_CAMPAIGN,
      {
        event_name: SOCKET_EVENT_NAMES.CREATE_EMAIL_CAMPAIGN,
        data: data,
      },
      (response: { data: any; error: string }) => {
        if (!response.error) {
          notify('success', 'Saved successfully');
          getCampaigns({
            filter: EmailCampaignFilter.ALL,
          });
        } else {
          notify('error', response?.error);
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const getCampaigns = ({
    page = 1,
    limit = 10,
    filter = EmailCampaignFilter.ALL,
  }: GetCampaignsParams) => {
    emailMarketingStore.setLoading(true);
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.GET_EMAIL_CAMPAIGNS,
      {
        event_name: SOCKET_EVENT_NAMES.GET_EMAIL_CAMPAIGNS,
        data: { page, limit, filter },
      },
      (response: SocketResponse<any>) => {
        emailMarketingStore.setCampaigns(response?.data?.campaigns ?? []);
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const getCampaign = (id: string) => {
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.GET_EMAIL_CAMPAIGN,
      { event_name: SOCKET_EVENT_NAMES.GET_EMAIL_CAMPAIGN, data: { id } },
      (response: any) => {
        emailMarketingStore.setCampaign(response?.data);
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const updateCampaign = (data: any) => {
    emailMarketingStore.setLoading(true);
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.UPDATE_EMAIL_CAMPAIGN,
      {
        event_name: SOCKET_EVENT_NAMES.UPDATE_EMAIL_CAMPAIGN,
        data: data,
      },
      (response: { data: any; error: string }) => {
        if (response.data) {
          notify('success', 'Saved successfull');
          getCampaigns({
            filter: EmailCampaignFilter.ALL,
          });
        } else {
          notify('error', response?.error);
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };
  const testEmail = (email: any) => {
    emailMarketingStore.setSendingTestEmail(true);
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.SEND_TEST_EMAIL,
      {
        event_name: SOCKET_EVENT_NAMES.SEND_TEST_EMAIL,
        data: email,
      },
      (response: { data: any; error: string }) => {
        if (!response.error) {
          notify('success', 'Sent successfully');
          getCampaigns({
            filter: EmailCampaignFilter.ALL,
          });
        } else {
          notify('error', response?.error);
        }
        emailMarketingStore.setSendingTestEmail(false);
      },
    );
  };

  const saveAndSendCampaign = (data: any) => {
    emailMarketingStore.setLoading(true);
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.SAVE_AND_SEND_EMAIL_CAMPAIGN,
      {
        event_name: SOCKET_EVENT_NAMES.SAVE_AND_SEND_EMAIL_CAMPAIGN,
        data: data,
      },
      (response: SocketResponse<any>) => {
        if (!response.error) {
          notify('loading', 'Campaign will be sent shortly..', {
            id: 'email-campaign-toast',
            duration: Infinity,
          });
          getCampaigns({
            filter: EmailCampaignFilter.ALL,
          });
        } else {
          notify('error', response?.error);
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const getEmailMarketingStats = () => {
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.GET_EMAIL_MARKETING_STATS,
      { event_name: SOCKET_EVENT_NAMES.GET_EMAIL_MARKETING_STATS, data: null },
      (response: SocketResponse<any>) => {
        emailMarketingStore.setEmailMarketingStats(response?.data);
      },
    );
  };

  const deleteCampaign = (id: string) => {
    emailMarketingStore.setLoading(true);
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.DELETE_EMAIL_CAMPAIGN,
      { event_name: SOCKET_EVENT_NAMES.DELETE_EMAIL_CAMPAIGN, data: { id } },
      (response: SocketResponse<any>) => {
        if (!response.error) {
          notify('success', 'Deleted successfully');
          getCampaigns({
            filter: EmailCampaignFilter.ALL,
          });
        } else {
          notify('error', response?.error);
        }
        emailMarketingStore.setLoading(false);
      },
    );
  };

  const getEmailCampaignStatusStats = () => {
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.GET_EMAIL_CAMPAIGN_STATUS_STATS,
      {
        event_name: SOCKET_EVENT_NAMES.GET_EMAIL_CAMPAIGN_STATUS_STATS,
        data: null,
      },
      (response: SocketResponse<any>) => {
        emailMarketingStore.setEmailCampaignStatusStats(response?.data);
      },
    );
  };

  return {
    checkDomain,
    verifyDomain,
    disconnectDomain,
    getTemplates,
    getEmailMarketingStats,
    getEmailCampaignStatusStats,
    deleteTemplate,
    saveTemplate,
    createCampaign,
    getCampaigns,
    getCampaign,
    updateCampaign,
    saveAndSendCampaign,
    testEmail,
    deleteCampaign,
  };
};
