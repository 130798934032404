import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import { SelectInput } from '@/components/atoms/SelectInput';
import { BASE_URL } from '@/constants/index';
import { notify } from '@/helpers/index';
import { TelnyxPhoneNumberResponse } from '@/types/sms.types';
import axios from 'axios';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import * as yup from 'yup';

type TelnyxFormProps = {
  handleTelnyx: (values: TelnyxFormPayload) => void;
};

export const TelnyxFormSchema = yup.object().shape({
  apiKey: yup.string().trim().required('API key is required'),
  phoneNumber: yup.string().trim().required('Phone Number is required'),
});

export type TelnyxFormPayload = yup.InferType<typeof TelnyxFormSchema> & {
  numberId: string;
};

export const TelnyxForm: FC<TelnyxFormProps> = ({ handleTelnyx }) => {
  const [phoneNumberDataList, setPhoneNumberDataList] = useState<
    Partial<TelnyxPhoneNumberResponse>[]
  >([]);
  const [isLoadingPhoneNumberDataList, setIsLoadingPhoneNumberDataList] =
    useState(false);

  const getPhoneNumberDataList = async (apiKey: string) => {
    setIsLoadingPhoneNumberDataList(true);
    const telnyxURL = `${BASE_URL}/api/sms/telnyx-numbers`;

    await axios
      .get<TelnyxPhoneNumberResponse[]>(telnyxURL, {
        params: { apiKey },
      })
      .then(response => {
        const structuredPhoneNumberDataList = response?.data;

        if (structuredPhoneNumberDataList.length === 0) {
          notify('error', 'No valid phone number found');
        } else {
          setPhoneNumberDataList(
            structuredPhoneNumberDataList?.length > 0
              ? structuredPhoneNumberDataList
              : [],
          );
        }
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      });

    setIsLoadingPhoneNumberDataList(false);
  };

  return (
    <div>
      <div className='flex flex-col px-4'>
        <div className='py-2 text-gray-500 2xl:text-[15px] xl:text-[12px]'>
          {`Create the exact solution you need to engage customers at every step of their journey.`}
        </div>
      </div>
      <Formik
        onSubmit={values => {
          const numberId = phoneNumberDataList.find(
            phoneNumberData =>
              phoneNumberData.phone_number === values.phoneNumber,
          )?.id as string;
          handleTelnyx({ ...values, numberId });
        }}
        initialValues={{
          apiKey: '',
          phoneNumber: '',
        }}
        validationSchema={TelnyxFormSchema}
      >
        {({ errors, values, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} className='mt-1 p-4 space-y-4'>
            <Input
              onChange={handleChange('apiKey')}
              onBlur={e => {
                if (phoneNumberDataList.length === 0 && values.apiKey) {
                  getPhoneNumberDataList(values.apiKey);
                }
                handleBlur('apiKey')(e);
              }}
              value={values.apiKey}
              type='text'
              name='apiKey'
              placeholder='API Key'
            />
            {errors.apiKey && (
              <small className='text-red-600'>{errors.apiKey}</small>
            )}

            <SelectInput
              onChange={handleChange('phoneNumber')}
              onBlur={handleBlur('phoneNumber')}
              value={values.phoneNumber}
              name='phoneNumber'
              placeholder='Phone Number'
              error={errors.phoneNumber}
              disabled={phoneNumberDataList.length === 0}
              loading={isLoadingPhoneNumberDataList}
              options={phoneNumberDataList?.map(phoneNumberData => {
                return {
                  value: phoneNumberData.phone_number as string,
                  label: phoneNumberData.phone_number as string,
                };
              })}
              errorMessage={errors.phoneNumber}
            />
            <div className='border border-b-0'></div>
            <div className='flex flex-row-reverse gap-4'>
              <Button
                disabled={!values.apiKey || !values.phoneNumber}
                type='submit'
                text='Save'
                variant='primary'
                className='w-full'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
