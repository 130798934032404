import EmptyList from '@/components/atoms/EmptyList';
import Spinner from '@/components/atoms/Spinner';
import Text from '@/components/atoms/Text';
import FilterTabs, { FilterTabOption } from '@/components/molecules/FilterTabs';
import {
  ChatIcon,
  EmailIcon,
  MessengerIcon,
  SMSIcon,
  WhatsAppIcon,
} from '@/components/molecules/FilterTabs/icons';
import MTab from '@/components/molecules/MTab';
import { useWorkspace } from '@/hooks/useWorkspace';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { CONVERSATION_CHANNEL } from '@/types/conversation.types';
import { Message } from '@/types/message.type';
import { User } from '@/types/user.types';
import TimeAgo from 'javascript-time-ago';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const filterOptions: FilterTabOption[] = [
  { id: 1, title: 'Chat', icon: <ChatIcon /> },
];

if (import.meta.env.VITE_NODE_ENV === 'development') {
  filterOptions.push(
    { id: 2, title: 'SMS', icon: <SMSIcon /> },
    { id: 3, title: 'WhatsApp', icon: <WhatsAppIcon /> },
    { id: 4, title: 'Email', icon: <EmailIcon />, disabled: true },
    { id: 5, title: 'Messenger', icon: <MessengerIcon />, disabled: true },
  );
}

export const UnreadMessage = observer(() => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const { getWorkspaceMessages, isLoading } = useWorkspace();
  const navigate = useNavigate();

  const handleTabChange = (id: number) => {
    setActiveTab(id);
  };

  const getMessageChannel = (tabId: number) => {
    switch (tabId) {
      case 1:
        return CONVERSATION_CHANNEL.CHAT;
      case 2:
        return CONVERSATION_CHANNEL.SMS;
      default:
        return CONVERSATION_CHANNEL.CHAT;
    }
  };

  const getTime = (lastMessage: Message | null) => {
    return lastMessage?.createdAt
      ? new TimeAgo('en-US').format(new Date(lastMessage?.createdAt as any))
      : null;
  };

  useEffect(() => {
    getWorkspaceMessages(getMessageChannel(activeTab));
  }, [activeTab]);

  return (
    <div className='bg-white  md:border border-[#E6E6E6] md:rounded-[4px] w-full'>
      <div className='md:px-6 py-2 flex flex-col'>
        <div className=' md:border-none border border-x-0 border-b-0 border-border mt-2 md:mt-0'>
          <div className='flex justify-between items-center font-medium leading-normal my-4 px-4 md:px-0'>
            <Text color='text-black' size='md' className='gap-2'>
              Messages
            </Text>

            <Link to={routeNames.dashboard.chat} className='gap-2 z-40'>
              <Text color='text-secondary' size='sm' className='cursor-pointer'>
                View all
              </Text>
            </Link>
          </div>
        </div>
        <div className='md:px-0 px-4 mb-4 pb-2 -mt-10 overflow-x-auto overflow-y-visible small-scrollbar'>
          <FilterTabs
            options={filterOptions}
            onChange={handleTabChange}
            activeTab={activeTab}
            mt={52}
          />
        </div>
        {isLoading ? (
          <div className='h-[300px] flex justify-center items-center'>
            <Spinner size={30} />
          </div>
        ) : (
          <>
            {(activeTab === 1 || activeTab === 2 || activeTab === 3) &&
            AuthStore?.workspaceMessages?.length === 0 ? (
              <EmptyList
                title={`No messages ${
                  activeTab === 1
                    ? 'in Chat'
                    : activeTab === 2
                    ? 'in SMS'
                    : 'in WhatsApp'
                }`}
                height='300px'
              />
            ) : activeTab === 1 ? (
              AuthStore?.workspaceMessages?.map(
                ({ last_message, id }, index) => (
                  <MTab
                    key={index}
                    name={(last_message?.sender as User)?.user_name}
                    src={(last_message?.sender as User)?.profile_picture_url}
                    online={false}
                    time={getTime(last_message)}
                    message={last_message?.content?.text as string}
                    onClick={() => {
                      navigate(
                        `${routeNames.dashboard.chat}/?conversationId=${id}`,
                      );
                    }}
                  />
                ),
              )
            ) : activeTab === 2 ? (
              AuthStore?.workspaceMessages?.map(
                ({ last_message, id }, index) => (
                  <MTab
                    key={index}
                    name={(last_message?.sender as User)?.user_name}
                    src={(last_message?.sender as User)?.profile_picture_url}
                    online={false}
                    time={getTime(last_message)}
                    message={last_message?.content?.text as string}
                    onClick={() =>
                      navigate(
                        `${routeNames.dashboard.chat}/sms/?conversationId=${id}`,
                      )
                    }
                  />
                ),
              )
            ) : (
              activeTab === 3 &&
              AuthStore?.workspaceMessages?.map(
                ({ last_message, id }, index) => (
                  <MTab
                    key={index}
                    name={(last_message?.sender as User)?.user_name}
                    src={(last_message?.sender as User)?.profile_picture_url}
                    online={false}
                    time={getTime(last_message)}
                    message={last_message?.content?.text as string}
                    onClick={() =>
                      navigate(
                        `${routeNames.dashboard.chat}/whatsapp/?conversationId=${id}`,
                      )
                    }
                  />
                ),
              )
            )}
            {activeTab === 4 && (
              <div className='mt-20 text-center text-xl font-semibold text-black'>
                Email Content
              </div>
            )}
            {activeTab === 5 && (
              <div className='mt-20 text-center text-xl font-semibold text-black'>
                Messenger Content
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});
