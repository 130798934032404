import { observer } from 'mobx-react-lite';
import React from 'react';

const StylesIcon = () => {
  return (
    <svg
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5 1.59375C13.1875 0.90625 14.2812 0.90625 14.9688 1.59375L16.4062 3.03125C17.0938 3.71875 17.0938 4.8125 16.4062 5.5L14.6562 7.25L10.75 3.34375L12.5 1.59375ZM14.0312 8L12.5938 12.8125C12.4062 13.4375 11.9375 13.9062 11.3125 14.125L3.0625 16.9062C2.6875 17 2.3125 16.9375 2.03125 16.6562L6.84375 11.875C7.03125 11.9688 7.25 12 7.46875 12C8.3125 12 8.96875 11.3438 8.96875 10.5C8.96875 9.6875 8.3125 9 7.46875 9C6.65625 9 5.96875 9.6875 5.96875 10.5C5.96875 10.75 6.03125 10.9688 6.125 11.1562L1.34375 15.9688C1.0625 15.6875 0.96875 15.3125 1.09375 14.9375L3.875 6.6875C4.0625 6.0625 4.5625 5.59375 5.1875 5.40625L9.96875 3.96875L14.0312 8Z'
        fill='#2173F0'
      />
    </svg>
  );
};
export default observer(StylesIcon);
