import { FC, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowDownIcon } from '@heroicons/react/outline';

interface ImagePreviewModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  content: any;
  onDownload: () => void;
}

const ImagePreviewModal: FC<ImagePreviewModalProps> = ({
  content,
  show = false,
  setShow,
  onDownload,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-[99999] overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-[0.5]' />
        </Transition.Child>

        <div className='fixed flex items-center justify-center inset-0 z-10 w-screen overflow-hidden'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative h-full flex flex-col transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='relative bg-[#edeff2] h-full sm:flex sm:flex-col'>
                  <div className='w-full flex items-center pr-6 justify-end h-10 bg-gray-25 absolute'>
                    <ArrowDownIcon
                      onClick={onDownload}
                      className='w-4 h-4 text-gray-375 cursor-pointer'
                    ></ArrowDownIcon>
                  </div>

                  <div className='h-full bg-[#edeff2]'>
                    <img
                      src={content}
                      className='h-auto aspect-square w-full object-contain'
                    />
                  </div>
                </div>
                <div className='bg-gray-25 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    type='button'
                    className='inline-flex w-full h-[20px] justify-center items-center rounded-md bg-[#033EB5] px-3 py-2 text-md font-semibold text-white shadow-sm hover:bg-black/15 sm:ml-3 sm:w-auto'
                    onClick={() => setShow(false)}
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ImagePreviewModal;
