export const RequireEmailIcon = () => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.46875 1.65625C12.2812 0.71875 16.4688 4.375 16.4688 9.03125V9.6875C16.5 11.1562 15.5312 12.5625 14.0938 12.9062C12.6875 13.25 11.4062 12.6875 10.6562 11.7188C10.0625 12.1875 9.3125 12.5 8.5 12.5C6.375 12.5 4.71875 10.6562 5.03125 8.5C5.1875 7.21875 6.125 6.125 7.3125 5.6875C8.3125 5.34375 9.25 5.46875 10 5.84375C10.0625 5.65625 10.25 5.5 10.5 5.5H11.5C11.75 5.5 12 5.71875 12 6V9.75C12 10.4375 12.5312 11 13.25 11C13.9375 11 14.5 10.4375 14.5 9.75V9.25C14.5 6.375 12.375 3.78125 9.53125 3.5C6.25 3.1875 3.5 5.78125 3.5 9C3.5 11.75 5.5 14.0312 8.15625 14.4375C8.625 14.5 9 14.9375 9 15.4062V15.4375C9 16.0312 8.4375 16.5 7.84375 16.4062C3.78125 15.8438 0.78125 11.9062 1.625 7.5C2.21875 4.59375 4.5625 2.25 7.46875 1.65625ZM8.46875 10.5312C9.3125 10.5312 9.96875 9.84375 9.96875 9.03125C9.96875 8.1875 9.28125 7.53125 8.46875 7.53125C7.625 7.53125 6.96875 8.1875 6.96875 9.03125C6.96875 9.84375 7.625 10.5312 8.46875 10.5312Z'
        fill='#2173F0'
      />
    </svg>
  );
};
