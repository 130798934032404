import { ChatSuggestionIcon } from '@/components/atoms/Icons/ChatSuggestionIcon';
import { observer } from 'mobx-react-lite';
import SendNotification from './Cards/SendNotification';

const SendPush = () => {
  return (
    <>
      <div className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'>
        <div className={`border border-white  bg-white rounded-md my-4  p-4`}>
          <div
            className={`flex flex-row items-center justify-between ease-in-out duration-300 mb-6`}
          >
            <div className='flex flex-row items-center h-[52px]'>
              <div className='flex flex-row items-center justify-center  mr-[16px] w-[40px] h-[40px] rounded-full border border-gray-100 bg-aliceBlue flex-none'>
                <ChatSuggestionIcon />
              </div>
              <div className='flex flex-col'>
                <h3
                  className={`text-[16px] font-[500] leading-[20px] text-black text-opacity-90 mb-[8px]`}
                >
                  Send Push
                </h3>
                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80'></p>
              </div>
            </div>
          </div>
          <div className=''>
            <SendNotification />
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(SendPush);
