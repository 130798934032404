export const ChatSuggestionIcon = () => {
  return (
    <svg
      width='17'
      height='14'
      viewBox='0 0 17 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 0C4.5625 0 1 2.9375 1 6.5C1 8.0625 1.65625 9.46875 2.75 10.5938C2.375 12.1875 1.0625 13.5625 1.0625 13.5938C0.96875 13.6562 0.96875 13.7812 1 13.875C1.03125 13.9688 1.125 14 1.25 14C3.3125 14 4.84375 13.0312 5.625 12.4062C6.65625 12.7812 7.78125 13 9 13C13.4062 13 16.9688 10.0938 16.9688 6.5C16.9688 2.9375 13.4062 0 9 0Z'
        fill='#2173F0'
      />
    </svg>
  );
};
