import { useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import EmptyList from '@/components/atoms/EmptyList';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  ArrowUpDown,
  BarChart,
  Calendar,
  ChevronLeft,
  ChevronRight,
  Edit,
  Filter,
  Mail,
  Menu,
  MoreVertical,
  Search,
  Settings,
  Trash,
  X,
  Copy,
  Bell,
  MessageSquare,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ScrollArea } from '@/components/ui/scroll-area';
import { observer } from 'mobx-react-lite';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import Spinner from '@/components/atoms/Spinner';
import { notify } from '@/helpers/index';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import { useList } from '@/hooks/useList';
import {
  EmailCampaignFilter,
  emailMarketingStore,
} from '@/state/EmailMarketingStore';
import { listStore } from '@/state/ListStore';
import { useNavigate } from 'react-router-dom';
import routeNames from '@/routes/routeNames';
import { replaceRouteParam } from '@/utils/routes';
import { AuthStore } from '@/state/AuthenticationStore';
import { SelectSingleEventHandler } from 'react-day-picker';

type Campaign = {
  id: string;
  name: string;
  status: 'draft' | 'scheduled' | 'sent';
  createdDate: string;
  sentDate: string;
  lastEdited: string;
  delivered: number;
  opens: number;
  clicks: number;
  type: string;
  scheduledDate?: string;
  templateId: string;
};

type SortConfig = {
  key: keyof Campaign;
  direction: 'asc' | 'desc';
};
interface CampaignDetails {
  goals: string[];
  title: string;
  templateId: string;
  listId: string;
  status: EmailCampaignFilter;
}
const CampaignManager = () => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [campaignType, setCampaignType] = useState('');
  const [draftDate, setDraftDate] = useState<Date>(new Date());
  const [goals, setGoals] = useState<string[]>([]);
  const [goal, setGoal] = useState('');
  const [details, setDetails] = useState<CampaignDetails>({
    goals: [],
    title: '',
    templateId: '',
    listId: '',
    status: EmailCampaignFilter.DRAFT,
  });
  const { getLists } = useList();
  const {
    getTemplates,
    createCampaign,
    getCampaigns,
    getEmailCampaignStatusStats,
    getCampaign,
    updateCampaign,
    // saveAndSendCampaign,
    deleteCampaign,
  } = useEmailMarketing();

  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig] = useState<SortConfig>({
    key: 'sentDate',
    direction: 'desc',
  });
  const [recentSortConfig, setRecentSortConfig] = useState<SortConfig>({
    key: 'sentDate',
    direction: 'desc',
  });
  const [activeTab, setActiveTab] = useState('list');
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [sidebarOpen, setSidebarOpen] = useState(false);

  const campaigns = emailMarketingStore?.campaigns;

  const filteredCampaigns = campaigns
    .filter(campaign =>
      campaign.title.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    .sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

  const sortedRecentCampaigns = [...campaigns]
    .sort((a: any, b: any) => {
      if (a[recentSortConfig.key] < b[recentSortConfig.key])
        return recentSortConfig.direction === 'asc' ? -1 : 1;
      if (a[recentSortConfig.key] > b[recentSortConfig.key])
        return recentSortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    })
    .slice(0, 5);

  const cleanCampaigns = !searchTerm
    ? sortedRecentCampaigns
    : filteredCampaigns;

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'draft':
        return 'bg-yellow-200 text-yellow-800';
      case 'scheduled':
        return 'bg-blue-200 text-blue-800';
      case 'sent':
        return 'bg-green-200 text-green-800';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  // const handleSort = (key: keyof Campaign) => {
  //   setSortConfig(prevConfig => ({
  //     key,
  //     direction:
  //       prevConfig.key === key && prevConfig.direction === 'asc'
  //         ? 'desc'
  //         : 'asc',
  //   }));
  // };

  const handleRecentSort = (key: keyof Campaign) => {
    setRecentSortConfig(prevConfig => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const renderCalendar = () => {
    const daysInMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0,
    ).getDate();
    const firstDayOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1,
    ).getDay();
    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<td key={`empty-${i}`} className='p-2 border'></td>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        day,
      );
      const campaignsOnDay = campaigns.filter(
        c => c.scheduledDate === currentDate.toISOString().split('T')[0],
      );

      days.push(
        <td key={day} className='p-2 border'>
          <div className='font-bold'>{day}</div>
          {campaignsOnDay.map(campaign => (
            <div
              key={campaign._id}
              className='text-xs bg-blue-100 p-1 mt-1 rounded'
            >
              {campaign.title}
            </div>
          ))}
        </td>,
      );

      if (days.length === 7 || day === daysInMonth) {
        weeks.push(<tr key={`week-${weeks.length}`}>{days}</tr>);
        days = [];
      }
    }

    return (
      <table className='w-full border-collapse'>
        <thead>
          <tr>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <th key={day} className='p-2 border'>
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{weeks}</tbody>
      </table>
    );
  };

  const listOptions = [
    { label: 'All Contacts', value: 'all' },
    { label: 'My Contacts', value: 'my_contacts' },
    ...(listStore.lists?.map(list => ({
      label: list.name,
      value: list._id,
    })) || []),
  ];

  const templates =
    emailMarketingStore.templates?.map(temp => ({
      label: temp.name,
      value: temp._id,
    })) || [];

  const handleChange = useCallback((e: any) => {
    const { name, value } = e.target;
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
  }, []);

  const handleUpdateClick = (selectedCampaign: string) => {
    getCampaign(selectedCampaign);
    setIsEditing(true);
    setIsOpenModal(true);
  };

  const openModal = () => {
    setIsEditing(false);
    setDetails({
      goals: [],
      title: '',
      templateId: '',
      listId: '',
      status: EmailCampaignFilter.DRAFT,
    });
    setGoals([]);
    setIsOpenModal(true);
  };

  const handleUpdate = () => {
    updateCampaign({
      ...details,
      id: emailMarketingStore?.campaign?._id as string,
    });
    setIsOpenModal(false);
  };

  const handlSave = () => {
    createCampaign(details);
    setIsOpenModal(false);
  };

  // const handleSaveAndSend = () => {
  //   if (isEditing && emailMarketingStore?.campaign?._id) {
  //     saveAndSendCampaign({
  //       ...details,
  //       id: emailMarketingStore?.campaign?._id as string,
  //     });
  //     setIsOpenModal(false);
  //   } else {
  //     saveAndSendCampaign(details);
  //     setIsOpenModal(false);
  //   }
  // };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter' && goal.trim() !== '') {
      const goalParts = goal
        .split(',')
        .map(g => g.trim())
        .filter(g => g !== '');
      setGoals(prevGoals => [...prevGoals, ...goalParts]);
      setDetails((prevDetails: any) => ({
        ...prevDetails,
        goals: [...prevDetails.goals, ...goalParts],
      }));
      setGoal('');
    }
  };

  const handleGoalChange = (e: any) => {
    setGoal(e.target.value);
  };

  const handleRemove = (goalToRemove: string) => {
    setGoals(prevGoals => prevGoals.filter(g => g !== goalToRemove));
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      goals: prevDetails.goals.filter((g: any) => g !== goalToRemove),
    }));
  };

  const handleSubmit = () => {
    if (
      !details?.title ||
      details?.goals?.length === 0 ||
      !details.listId ||
      !details.templateId
    ) {
      notify('error', 'Please fill all the details');
      return;
    }
    if (isEditing && emailMarketingStore?.campaign?._id) {
      handleUpdate();
    } else {
      handlSave();
    }
  };

  const templateBuilder = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailTemplates}`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };
  const gotoTemplates = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/templates`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };
  const audience = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.contacts}/`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };
  const next = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/audience`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  const gotoCalendar = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaign-calendar`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  const handleDateChange: SelectSingleEventHandler = (day: any) => {
    if (day) {
      setDraftDate(day);
    }
  };

  useEffect(() => {
    getLists();
    getTemplates();
  }, []);

  useEffect(() => {
    if (isEditing && emailMarketingStore?.campaign) {
      const campaignData = emailMarketingStore.campaign;
      setDetails({
        goals: campaignData?.goals || [],
        title: campaignData?.title || '',
        templateId: campaignData?.template || '',
        listId: campaignData?.listId || '',
        status: campaignData?.status || '',
      });

      setGoals(campaignData?.goals || []);
    }
  }, [emailMarketingStore?.campaign, isEditing]);

  useEffect(() => {
    getCampaigns({
      filter: EmailCampaignFilter.ALL,
    });
    getEmailCampaignStatusStats();
  }, [EmailCampaignFilter.ALL]);

  if (emailMarketingStore.loading) {
    return (
      <div className='h-[80vh] flex justify-center bg-white rounded-[16px] m-6'>
        <Spinner color='blue' size={50} />
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-col md:flex-row bg-gray-100 h-full'>
        {/* Mobile Header */}
        <header className='md:hidden bg-[#2563EB] text-white p-4 flex justify-between items-center'>
          <Button
            variant='ghost'
            size='icon'
            // onClick={() => setSidebarOpen(true)}
          >
            <Menu className='h-6 w-6' />
          </Button>
          <h1 className='text-xl font-bold'>Email Campaigns</h1>
          <Button variant='ghost' size='icon'>
            <Settings className='h-6 w-6' />
          </Button>
        </header>

        {/* Main content */}
        <main className='flex-1 p-4 md:p-8 overflow-auto'>
          <div className='bg-white p-4 md:p-6 rounded-lg shadow-sm'>
            <div className='flex flex-col md:flex-row justify-between items-start md:items-center mb-6'>
              <h1 className='text-2xl md:text-3xl font-bold text-gray-900 mb-4 md:mb-0'>
                All Campaigns
              </h1>
              <div className='flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4'>
                <Button variant='outline' className='w-full md:w-auto'>
                  Options
                </Button>
                <Button
                  onClick={openModal}
                  className='w-full md:w-auto bg-black hover:bg-gray-800 text-white'
                >
                  Create campaign
                </Button>
              </div>
            </div>

            {/* BFCM Banner */}
            <Card className='mb-6 bg-gradient-to-r from-red-500 to-pink-500 text-white relative'>
              <button className='absolute top-2 right-2 text-white hover:text-gray-200'>
                <X className='h-4 w-4' />
              </button>
              <CardHeader>
                <CardTitle>BFCM 2024</CardTitle>
              </CardHeader>
              <CardContent>
                <p>
                  Find how-tos, to-dos, live trainings, proven strategies, and
                  more on our BFCM resource center.
                </p>
              </CardContent>
              <CardFooter>
                <Button
                  variant='outline'
                  className='w-full md:w-auto text-black'
                  onClick={gotoTemplates}
                >
                  Explore templates for BFCM
                </Button>
              </CardFooter>
            </Card>

            <Tabs
              value={activeTab}
              onValueChange={setActiveTab}
              className='mb-6'
            >
              <TabsList className='grid w-full grid-cols-2'>
                <TabsTrigger value='list' className='text-md'>
                  List view
                </TabsTrigger>
                <TabsTrigger
                  onClick={gotoCalendar}
                  value='calendar'
                  className='text-md'
                >
                  Calendar
                </TabsTrigger>
              </TabsList>
            </Tabs>

            <div className='flex flex-col space-y-4 mb-6'>
              <div className='relative flex-grow'>
                <Search className='absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400' />
                <Input
                  type='text'
                  placeholder='Search campaigns...'
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  className='pl-10 pr-4 py-2 w-full focus-visible:outline-none focus-visible:ring-0
                   focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                />
              </div>
              <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4'>
                <Select>
                  <SelectTrigger className='w-full md:w-[200px] focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                    <Settings className='w-4 h-4 mr-2' />
                    <SelectValue placeholder='Active on Site' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value='active'>Active on Site</SelectItem>
                    <SelectItem value='inactive'>Inactive on Site</SelectItem>
                  </SelectContent>
                </Select>
                <Select>
                  <SelectTrigger className='w-full md:w-[200px] focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                    <SelectValue placeholder='Last 30 days' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value='30'>Last 30 days</SelectItem>
                    <SelectItem value='60'>Last 60 days</SelectItem>
                    <SelectItem value='90'>Last 90 days</SelectItem>
                  </SelectContent>
                </Select>
                <Sheet>
                  <SheetTrigger asChild>
                    <Button
                      variant='outline'
                      className='w-full md:w-[120px] bg-gray-100 hover:bg-gray-200'
                    >
                      <Filter className='w-4 h-4 mr-2' />
                      Filters
                    </Button>
                  </SheetTrigger>
                  <SheetContent className='w-[300px] sm:w-[400px] max-h-screen flex flex-col'>
                    <SheetHeader>
                      <SheetTitle>Filters</SheetTitle>
                    </SheetHeader>
                    <ScrollArea className='flex-grow py-4'>
                      <div className='pr-4'>
                        <h3 className='mb-2 font-semibold'>Type</h3>
                        <div className='space-y-2'>
                          <div className='flex items-center'>
                            <Checkbox id='email' />
                            <label htmlFor='email' className='ml-2'>
                              Email
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='sms' />
                            <label htmlFor='sms' className='ml-2'>
                              SMS
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='push' />
                            <label htmlFor='push' className='ml-2'>
                              Push
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='abtest' />
                            <label htmlFor='abtest' className='ml-2'>
                              A/B Test Only
                            </label>
                          </div>
                        </div>
                        <h3 className='mt-4 mb-2 font-semibold'>Status</h3>
                        <div className='space-y-2'>
                          <div className='flex items-center'>
                            <Checkbox id='sent' />
                            <label htmlFor='sent' className='ml-2'>
                              Sent
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='scheduled' />
                            <label htmlFor='scheduled' className='ml-2'>
                              Scheduled
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='draft' />
                            <label htmlFor='draft' className='ml-2'>
                              Draft / Paused
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='canceled' />
                            <label htmlFor='canceled' className='ml-2'>
                              Canceled
                            </label>
                          </div>
                        </div>
                        <h3 className='mt-4 mb-2 font-semibold'>
                          Sending order
                        </h3>
                        <Select>
                          <SelectTrigger
                            className='w-full focus:ring-0 focus:ring-offset-0
                           focus-within:border focus-within:border-secondary'
                          >
                            <SelectValue placeholder='Select order' />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value='asc'>Ascending</SelectItem>
                            <SelectItem value='desc'>Descending</SelectItem>
                          </SelectContent>
                        </Select>
                        <h3 className='mt-4 mb-2 font-semibold'>Tags</h3>
                        <Select>
                          <SelectTrigger
                            className='w-full focus:ring-0 focus:ring-offset-0
                           focus-within:border focus-within:border-secondary'
                          >
                            <SelectValue placeholder='Select tags' />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value='tag1'>Tag 1</SelectItem>
                            <SelectItem value='tag2'>Tag 2</SelectItem>
                          </SelectContent>
                        </Select>
                        <h3 className='mt-4 mb-2 font-semibold'>List</h3>
                        <Select>
                          <SelectTrigger
                            className='w-full focus:ring-0 focus:ring-offset-0
                           focus-within:border focus-within:border-secondary'
                          >
                            <SelectValue placeholder='Select one or more options' />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value='list1'>List 1</SelectItem>
                            <SelectItem value='list2'>List 2</SelectItem>
                          </SelectContent>
                        </Select>
                        <h3 className='mt-4 mb-2 font-semibold'>Date Sent</h3>
                        <div className='space-y-2'>
                          <div className='flex items-center'>
                            <Checkbox id='last7days' />
                            <label htmlFor='last7days' className='ml-2'>
                              Last 7 days
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='last30days' />
                            <label htmlFor='last30days' className='ml-2'>
                              Last 30 days
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='last90days' />
                            <label htmlFor='last90days' className='ml-2'>
                              Last 90 days
                            </label>
                          </div>
                          <div className='flex items-center'>
                            <Checkbox id='customDate' />
                            <label htmlFor='customDate' className='ml-2'>
                              Custom
                            </label>
                          </div>
                        </div>
                        <div className='mt-2 space-y-2'>
                          <Input type='date' placeholder='Start date' />
                          <Input type='date' placeholder='End date' />
                        </div>
                      </div>
                    </ScrollArea>
                    <SheetFooter>
                      <Button className='w-full bg-black text-white'>
                        Apply Filters
                      </Button>
                    </SheetFooter>
                  </SheetContent>
                </Sheet>
              </div>
            </div>
            <Tabs>
              <TabsContent value='tabble-list'>
                <div className='mb-4 flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0'>
                  <div className='flex items-center space-x-4'>
                    <Button onClick={() => setSelectedDate(new Date())}>
                      Today
                    </Button>
                    <Button
                      variant='outline'
                      onClick={() =>
                        setSelectedDate(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth() - 1,
                            1,
                          ),
                        )
                      }
                    >
                      <ChevronLeft className='h-4 w-4' />
                    </Button>
                    <Button
                      variant='outline'
                      onClick={() =>
                        setSelectedDate(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth() + 1,
                            1,
                          ),
                        )
                      }
                    >
                      <ChevronRight className='h-4 w-4' />
                    </Button>
                    <span className='text-lg font-semibold'>
                      {selectedDate.toLocaleString('default', {
                        month: 'long',
                        year: 'numeric',
                      })}
                    </span>
                  </div>
                  <Select defaultValue='month'>
                    <SelectTrigger className='w-[180px]'>
                      <SelectValue placeholder='Select view' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='day'>Day</SelectItem>
                      <SelectItem value='week'>Week</SelectItem>
                      <SelectItem value='month'>Month</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className='overflow-x-auto'>{renderCalendar()}</div>
              </TabsContent>
            </Tabs>
          </div>
          {/* Recent Campaigns Table */}
          <div className='my-10 bg-white p-4 md:p-6 rounded-lg shadow-sm'>
            <h2 className='text-xl md:text-2xl font-bold mb-6'>
              {!searchTerm ? 'Recent Campaigns' : 'All Campaigns'}
            </h2>
            <div className='overflow-x-auto'>
              {!cleanCampaigns?.length ? (
                <div className='flex justify-center items-center'>
                  <EmptyList
                    listName={''}
                    title='There are no campaigns'
                    subTitle='Add campaigns to see them here'
                    height='25vh'
                  />
                </div>
              ) : (
                <Table>
                  <TableHeader className=' text-md'>
                    <TableRow>
                      <TableHead
                        onClick={() => handleRecentSort('name')}
                        className='cursor-pointer'
                      >
                        Name{' '}
                        {recentSortConfig.key === 'name' && (
                          <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                        )}
                      </TableHead>
                      <TableHead
                        onClick={() => handleRecentSort('type')}
                        className='cursor-pointer hidden md:table-cell'
                      >
                        Type{' '}
                        {recentSortConfig.key === 'type' && (
                          <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                        )}
                      </TableHead>
                      <TableHead
                        onClick={() => handleRecentSort('status')}
                        className='cursor-pointer'
                      >
                        Status{' '}
                        {recentSortConfig.key === 'status' && (
                          <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                        )}
                      </TableHead>
                      <TableHead
                        onClick={() => handleRecentSort('sentDate')}
                        className='cursor-pointer hidden md:table-cell'
                      >
                        Sent Date{' '}
                        {recentSortConfig.key === 'sentDate' && (
                          <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                        )}
                      </TableHead>
                      <TableHead
                        onClick={() => handleRecentSort('delivered')}
                        className='cursor-pointer text-right hidden md:table-cell'
                      >
                        Delivered{' '}
                        {recentSortConfig.key === 'delivered' && (
                          <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                        )}
                      </TableHead>
                      <TableHead
                        onClick={() => handleRecentSort('opens')}
                        className='cursor-pointer text-right hidden md:table-cell'
                      >
                        Opens{' '}
                        {recentSortConfig.key === 'opens' && (
                          <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                        )}
                      </TableHead>
                      <TableHead
                        onClick={() => handleRecentSort('clicks')}
                        className='cursor-pointer text-right hidden md:table-cell'
                      >
                        Clicks{' '}
                        {recentSortConfig.key === 'clicks' && (
                          <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                        )}
                      </TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {cleanCampaigns.map((campaign, index) => (
                      <TableRow key={index}>
                        <TableCell className='font-medium text-md !border-0'>
                          {campaign.title || '-'}
                        </TableCell>
                        <TableCell className='hidden md:table-cell text-md !border-0'>
                          {campaign.type || '-'}
                        </TableCell>
                        <TableCell className='text-md !border-0'>
                          <Badge className={getStatusColor(campaign.status)}>
                            {campaign.status.charAt(0).toUpperCase() +
                              campaign.status.slice(1)}
                          </Badge>
                        </TableCell>
                        <TableCell className='hidden md:table-cell text-md !border-0'>
                          {format(new Date(campaign.sendAt), 'PPpp') || '-'}
                        </TableCell>
                        <TableCell className='text-right hidden md:table-cell text-md !border-0'>
                          {campaign.sentCount || '0'}
                        </TableCell>
                        <TableCell className='text-right hidden md:table-cell text-md !border-0'>
                          {campaign.openCount || '0'}
                        </TableCell>
                        <TableCell className='text-right hidden md:table-cell text-md !border-0'>
                          {campaign.clickCount || '0'}
                        </TableCell>
                        <TableCell className='text-md !border-0'>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                variant='outline'
                                size='icon'
                                className='h-8 w-8'
                              >
                                <MoreVertical className='h-4 w-4' />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align='end'>
                              <DropdownMenuItem
                                onClick={() => handleUpdateClick(campaign._id)}
                              >
                                <Edit className='mr-2 h-4 w-4' />
                                Edit
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <Copy className='mr-2 h-4 w-4' />
                                Clone
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <BarChart className='mr-2 h-4 w-4' />
                                Analytics
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={e => {
                                  e.stopPropagation();
                                  emailMarketingStore.setCampaign(campaign);
                                  setIsOpenConfirmationModal(true);
                                }}
                              >
                                <Trash className='mr-2 h-4 w-4' />
                                Delete
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          </div>
        </main>
      </div>
      <Dialog open={isOpenModal} onOpenChange={setIsOpenModal}>
        <DialogContent className='bg-white border-none p-8 w-full max-w-xl max-h-[90vh] overflow-y-auto [&>button]:hidden'>
          <DialogHeader className='flex flex-row justify-between items-center'>
            <DialogTitle className='text-2xl font-semibold text-secondary'>
              Create
            </DialogTitle>
            <DialogClose asChild>
              <Button variant='ghost' size='icon'>
                <X className='h-6 w-6' />
              </Button>
            </DialogClose>
          </DialogHeader>
          <form onSubmit={handleSubmit} className='grid grid-cols-1 gap-4'>
            <div>
              <Label htmlFor='campaignName'>Campaign Name</Label>
              <Input
                id='campaignName'
                name='title'
                placeholder="Enter campaign name (e.g., 'Holiday Promo 2024')"
                value={details.title}
                onChange={handleChange}
                className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                required
              />
            </div>
            <div>
              <Label htmlFor='draftDate'>Draft Date</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !draftDate && 'text-muted-foreground',
                    )}
                  >
                    <Calendar className='mr-2 h-4 w-4' />
                    {draftDate ? format(draftDate, 'PPP') : 'Select a date'}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0'>
                  <CalendarComponent
                    mode='single'
                    selected={draftDate}
                    onSelect={handleDateChange}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className='space-y-2'>
              <Label>Type</Label>
              <RadioGroup
                value={campaignType}
                onValueChange={setCampaignType}
                className='space-y-2'
              >
                {['email', 'sms', 'push'].map(type => (
                  <div
                    key={type}
                    className={`flex items-center space-x-2 rounded-md border p-4 transition-colors ${
                      campaignType === type
                        ? '!bg-secondary text-white'
                        : 'hover:bg-secondary/10'
                    }`}
                  >
                    <RadioGroupItem value={type} id={type} />
                    <Label
                      htmlFor={type}
                      className='flex items-center cursor-pointer'
                    >
                      {type === 'email' && <Mail className='mr-2 h-4 w-4' />}
                      {type === 'sms' && (
                        <MessageSquare className='mr-2 h-4 w-4' />
                      )}
                      {type === 'push' && <Bell className='mr-2 h-4 w-4' />}
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
              <p className='text-md text-muted-foreground mt-1'>
                Push messages will go out from your Sticky Card.
              </p>
            </div>
            <div>
              <Label htmlFor='emailTemplate'>Email Template</Label>
              <Select
                name='templateId'
                value={details.templateId}
                onValueChange={value =>
                  setDetails({ ...details, templateId: value })
                }
                required
              >
                <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                  <SelectValue placeholder='Select an email template' />
                </SelectTrigger>
                <SelectContent>
                  <span
                    className='cursor-pointer flex w-full items-center rounded-sm py-1.5 pl-8 pr-2 text-md  focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                    onClick={templateBuilder}
                  >
                    + New Template
                  </span>
                  {templates?.map(template => (
                    <SelectItem key={template.value} value={template.value}>
                      {template.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <p className='text-md text-muted-foreground mt-1'>
                Choose an email template. Click Preview to see the design.
              </p>
            </div>
            <div>
              <Label htmlFor='recipients'>Recipients</Label>
              <Select
                name='listId'
                value={details.listId}
                onValueChange={value =>
                  setDetails({ ...details, listId: value })
                }
                required
              >
                <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                  <SelectValue placeholder='Select a recipient list' />
                </SelectTrigger>
                <SelectContent>
                  <span
                    className='cursor-pointer flex w-full items-center rounded-sm py-1.5 pl-8 pr-2 text-md outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                    onClick={audience}
                  >
                    + New Audience
                  </span>
                  {listOptions?.map(list => (
                    <SelectItem key={list.value} value={list.value}>
                      {list.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <p className='text-md text-muted-foreground mt-1'>
                Select or create a recipient list for this campaign.
              </p>
            </div>
            <div>
              <Label htmlFor='goals'>Tags</Label>
              <Input
                id='goals'
                name='goals'
                value={goal}
                onChange={handleGoalChange}
                onKeyUp={handleKeyUp}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                  }
                }}
                placeholder='Select or add tags (e.g., Holiday, Promotion)'
                className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
              />
              <div className='flex flex-wrap gap-2 mt-2'>
                {goals.map((tag, index) => (
                  <span
                    key={index}
                    className='bg-primary/10 text-black px-2 py-1 rounded text-md'
                  >
                    {tag}
                    <button
                      type='button'
                      className='ml-2 text-primary'
                      onClick={() => handleRemove(tag)}
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
              <p className='text-md text-muted-foreground mt-1'>
                Add tags to organize and track campaigns.
              </p>
            </div>
            <div className='flex justify-end space-x-2 mt-4'>
              <Button type='button' variant='outline' onClick={handleSubmit}>
                Save draft
              </Button>
              <Button
                type='button'
                className='bg-secondary text-white hover:bg-secondary/80'
                onClick={next}
              >
                Continue
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <ConfirmationModal
        title='Delete Campaign'
        content='Are you sure you want to delete this campaign?'
        show={isOpenConfirmationModal}
        setShow={setIsOpenConfirmationModal}
        confirmText='Done'
        onConfirm={() => {
          deleteCampaign(emailMarketingStore?.campaign?._id as string);
          setIsOpenConfirmationModal(false);
        }}
      />
    </>
  );
};
export default observer(CampaignManager);
