import { useWidgetConfig } from '@/hooks/useWidgetConfig';
import { WidgetConfigStore } from '@/state/WidgetConfigStore';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import ConfigInput from '@/components/atoms/ConfigInput';

const AdvertCardHeading: FC = () => {
  const { publish } = useWidgetConfig();
  const handlePublish = () => {
    publish();
  };

  const handleUpdateAdvertTitle = (e: any) => {
    WidgetConfigStore.updateAdvertTitle(e.target.value);
  };
  const handleUpdateAdvertSubtitle = (e: any) => {
    WidgetConfigStore.updateAdvertSubtitle(e.target.value);
  };

  return (
    WidgetConfigStore.config.value && (
      <div className='mb-[30px]'>
        <div className={` flex flex-col`}>
          <p className='mb-[8px] text-[#656971] text-[14px] font-[500] leading-[20px]'>
            Section title
          </p>
          <div className='flex flex-col w-full mb-6'>
            <ConfigInput
              maxLength={120}
              type='text'
              value={WidgetConfigStore.config.value.ad_headings.title}
              onChange={handleUpdateAdvertTitle}
              onSelectEmoji={(emoji: string) => {
                WidgetConfigStore.updateAdvertTitle(
                  WidgetConfigStore.config.value.ad_headings.title + emoji,
                );
              }}
              placeholder='Enter suggestion'
            />
          </div>
          <p className='mb-[8px] text-[#656971] text-[14px] font-[500] leading-[20px]'>
            Section subtitle
          </p>
          <div className='flex flex-col w-full mb-6'>
            <ConfigInput
              maxLength={120}
              type='text'
              value={WidgetConfigStore.config.value.ad_headings.sub_title}
              onChange={handleUpdateAdvertSubtitle}
              onSelectEmoji={(emoji: string) => {
                WidgetConfigStore.updateAdvertSubtitle(
                  WidgetConfigStore.config.value.ad_headings.sub_title + emoji,
                );
              }}
              placeholder='Enter suggestion'
            />
          </div>
        </div>
        <div className='flex mt-[28px] w-full justify-end space-x-4'>
          <Button
            type='button'
            onClick={handlePublish}
            loading={WidgetConfigStore.config.style?.loading}
            text={
              WidgetConfigStore.config.style?.loading
                ? 'Saving'
                : 'Save and set live'
            }
            loadingText='Saving'
            size='sm'
            className='bg-[#1068EF]'
          />
        </div>
      </div>
    )
  );
};
export default observer(AdvertCardHeading);
