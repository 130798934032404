import Button from '@/components/atoms/Button';
import Text from '@/components/atoms/Text';
import { extractDomain } from '@/helpers/index';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

const AgentDetails = ({ data, id }: any) => {
  const [visibleDetailsCount, setVisibleDetailsCount] = useState(6);
  const [viewMore, setViewMore] = useState(false);

  // Function to toggle the visible details count
  const toggleVisibleDetails = () => {
    if (visibleDetailsCount === 6) {
      setVisibleDetailsCount(publicDetails.length);
      setViewMore(true);
    } else {
      setVisibleDetailsCount(6);
      setViewMore(false);
    }
  };

  const publicDetails = [
    {
      id: 1,
      name: data?.get(id)?.user_name || '-',
      icon: (
        <Icon
          icon='ic:baseline-person'
          width={15}
          height={15}
          color='#898D94'
        />
      ),
      visible: true,
    },
    {
      id: 2,
      name: data?.get(id)?.email || '-',
      icon: (
        <Icon icon='bi:mailbox2-flag' width={15} height={15} color='#898D94' />
      ),
      visible: true,
    },
    {
      id: 3,
      name: 'Active',
      icon: <Icon icon='uit:calender' width={15} height={15} color='#898D94' />,
      visible: true,
    },
    {
      id: 4,
      name:
        data?.get(id)?.country && data?.get(id)?.city
          ? `${data.get(id).country}, ${data.get(id).city}`
          : '-',
      icon: <Icon icon='gis:globe-o' width={15} height={15} color='#898D94' />,
      visible: true,
    },
    {
      id: 5,
      name: data?.get(id)?.job_title || 'No job title yet',
      icon: <Icon icon='mdi:company' width={15} height={15} color='#898D94' />,
      visible: false,
    },
    {
      id: 6,
      name: data?.get(id)?.bio || 'Introduce yourself',
      icon: <Icon icon='oi:chat' width={15} height={15} color='#898D94' />,
      visible: false,
    },
    {
      id: 7,
      name: 'Add a link to your calendar',
      icon: <Icon icon='uit:calender' width={15} height={15} color='#898D94' />,
      visible: false,
    },
    {
      id: 8,
      name:
        data?.get(id)?.twitter_followers !== null
          ? data.get(id)?.twitter_followers
          : 'Add your Twitter',
      icon: (
        <Icon
          icon='hugeicons:new-twitter'
          width={15}
          height={15}
          color='#898D94'
        />
      ),
      visible: false,
    },
  ];

  return (
    <div className='flex flex-col'>
      <div className='flex gap-y-4'>
        <div className='flex justify-between items-center w-full my-2'>
          <Text size='md' color='text-black'>
            Your Details
          </Text>
        </div>
      </div>

      <div className='mt-2'>
        {publicDetails.slice(0, visibleDetailsCount).map(item => (
          <div key={item.id} className='flex items-center mb-4'>
            <span className='mr-2'>{item.icon}</span>
            <p className='mr-2 basis-24 text-md text-gray-500 whitespace-nowrap'>
              {item.name}
            </p>
            <div
              className={`${
                item.name === 'Website' &&
                data?.company_website &&
                'text-secondary'
              } text-md text-gray-400 flex truncate overflow-ellipsis`}
            >
              {item.name === 'Website' && data?.company_website ? (
                <a
                  href={`https://${extractDomain(data.company_website)}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {data.company_website}
                  <Icon
                    icon='solar:link-bold'
                    className='text-secondary text-[18px] ml-2'
                  />
                </a>
              ) : null}
            </div>
          </div>
        ))}
      </div>

      <Button
        text={viewMore ? 'See less' : 'See more'}
        variant='outline'
        className='w-full h-[32px] mt-4'
        onClick={toggleVisibleDetails}
      />
    </div>
  );
};

export default observer(AgentDetails);
