import Container from '@/components/atoms/Container';
import Tile from '@/components/atoms/Tile';
import DateFilter from '@/components/molecules/DateFilter';
import { emailMarketingStore } from '@/state/EmailMarketingStore';
import { observer } from 'mobx-react-lite';

const Stats = () => {
  return (
    <div className='w-full relative md:flex flex-col'>
      <Container>
        <div className='flex justify-between items-center p-[24px]'>
          <div className='flex justify-between items-center space-x-16'>
            <span className='text-xl leading-7 py-4'>Email marketing</span>
          </div>

          <DateFilter />
        </div>
        <div
          className={`grid grid-cols-4 w-full transition-max-h overflow-hidden duration-500 ease-in-out`}
        >
          <Tile
            name='Total emails sent'
            content={emailMarketingStore.emailMarketingStats.sent?.toString()}
            statisticValue={+24.5}
            borderTop={true}
            borderLeft={false}
            borderRight={true}
          />
          <Tile
            name='Total emails opened'
            content={emailMarketingStore.emailMarketingStats.opened?.toString()}
            statisticValue={-21.2}
            borderTop={true}
          />
          <Tile
            name='Total emails clicked'
            content={emailMarketingStore.emailMarketingStats.clicked?.toString()}
            statisticValue={undefined}
            borderTop={true}
            borderLeft={true}
          />
          <Tile
            name='Total emails bounced'
            content='- -'
            borderTop={true}
            borderLeft={true}
            borderRight={false}
          />
        </div>
      </Container>
    </div>
  );
};

export default observer(Stats);
