import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Spinner from '@/components/atoms/Spinner';
import Overview from '@/components/templates/Overview';
import { QuickStart } from '@/components/templates/QuickStart';
import { useWidgetConfig } from '@/hooks/useWidgetConfig';
import { UnreadMessage } from '@/components/templates/UnreadMessages';
import { WidgetConfigStore } from '@/state/WidgetConfigStore';
import { WeeklyView } from '@/components/templates/WeeklyView';
import Text from '@/components/atoms/Text';
import { ConStore } from '@/state/ConversationStore';
import { Tab, TabPanel } from '@/components/molecules/Tab';
import { setCSSVariables } from '@/utils/index';
import { useChatConversation } from '@/hooks/useChatConversation';
import { CONVERSATION_CHANNEL } from '@/types/conversation.types';

const Home = () => {
  const { get_widget_config } = useWidgetConfig();
  const { get_open_conversations, get_closed_conversations } =
    useChatConversation();
  useEffect(() => {
    if (WidgetConfigStore.config.value) {
      setCSSVariables();
    }
  }, [
    WidgetConfigStore.config.value?.colors.header_bg_color,
    WidgetConfigStore.config.value?.colors.header_text_color,
    WidgetConfigStore.config.value?.colors.border_color,
    WidgetConfigStore.config.value?.colors.btn_color,
    WidgetConfigStore.config.value?.colors.btn_text_color,
    WidgetConfigStore.config.value?.colors.header_bg_color,
  ]);

  useEffect(() => {
    if (!WidgetConfigStore.config.value) {
      //  request config here
      get_widget_config();
    }
  }, []);

  useEffect(() => {
    get_open_conversations({
      channel: CONVERSATION_CHANNEL.CHAT,
    });
    get_closed_conversations({
      channel: CONVERSATION_CHANNEL.CHAT,
    });
    ConStore.set_selected_conversation_id(null);
    ConStore.fetching_conversations = true;
  }, [CONVERSATION_CHANNEL]);
  return (
    <div className='flex flex-col w-full mx-auto md:p-[24px] py-[10px] overflow-y-auto no-scrollbar mb-14'>
      {WidgetConfigStore.config.fetching ? (
        <div className='h-screen w-full flex justify-center items-center'>
          <Spinner size={30} color={'#033EB5'} />
        </div>
      ) : (
        <div>
          <div className='hidden md:flex flex-col'>
            <Overview />
            <div className='grid grid-cols-2 mt-10 items-start gap-10'>
              <div className='flex flex-col gap-10'>
                <UnreadMessage />
                <WeeklyView />
              </div>
              <div className='flex flex-col gap-10'>
                <QuickStart />
              </div>
            </div>
          </div>
          <div className='md:hidden h-[100vh!important]'>
            <div className='flex justify-between items-center mb-4 px-4'>
              <Text size='lg' className='text-black'>
                Dashboard
              </Text>
              <Text size='md' className='text-secondary cursor-pointer'>
                Quick start guide
              </Text>
            </div>
            <Tab onTabChange={() => ''}>
              <TabPanel title='Overview'>
                <Overview />
              </TabPanel>
              <TabPanel
                title='Messages'
                isRibbon={true}
                count={ConStore.unread_conversations}
              >
                <UnreadMessage />
              </TabPanel>
              <TabPanel title='My week'>
                <WeeklyView />
              </TabPanel>
            </Tab>
          </div>
        </div>
      )}
    </div>
  );
};
export default observer(Home);
