import { notify } from '@/helpers/index';
import { useState } from 'react';

type FileType = 'text/csv' | 'image/jpeg' | 'image/png';

type UseDragNDropProps = {
  /**
   * @description The file types that are allowed to be dragged and dropped
   * @type FileType[]
   */
  imageTypes?: FileType[];
  /**
   * @description This function will be called when a file is selected. use this to handle the file(s)
   * @param files
   * @returns void
   */
  onFileChange?: (files: FileList) => void;
  /**
   * @description This function will be called when a file is dropped
   * @param files
   * @returns void
   */
  onDrop?: (files: FileList) => void;
};

export const useDragNDrop = ({
  imageTypes = ['image/jpeg', 'image/png', 'text/csv'],
  onFileChange,
  onDrop,
}: UseDragNDropProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isFileOver, setIsFileOver] = useState(false);
  const validationMessage = `Only ${
    imageTypes.join(', ').split('/')[1]
  } file is allowed`;

  const validateFile = (file: File) => {
    if (imageTypes.includes(file.type as FileType)) {
      return true;
    }
    return false;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (onFileChange) {
        onFileChange(e.target.files);
        return;
      }
      const _file = e.target.files[0];

      if (!validateFile(_file)) {
        notify('error', validationMessage);
        return;
      }
      setFile(e.target.files[0]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsFileOver(true);
  };

  const handleDragLeave = () => {
    setIsFileOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFileOver(false);
    if (e.dataTransfer.files) {
      if (onDrop) {
        onDrop(e.dataTransfer.files);
        return;
      }
      const _file = e.dataTransfer.files[0];

      if (!validateFile(_file)) {
        notify('error', validationMessage);
        return;
      }
      setFile(e.dataTransfer.files[0]);
    }
  };

  return {
    file,
    isFileOver,
    setIsFileOver,
    handleFileChange,
    handleDragOver,
    handleDragLeave,
    handleDrop,
  };
};
