export const OfficeHoursIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16ZM7.25 8C7.25 8.25 7.375 8.5 7.5625 8.625L10.5625 10.625C10.9062 10.875 11.375 10.7812 11.5938 10.4375C11.8438 10.0938 11.75 9.625 11.4062 9.375L8.75 7.625V3.75C8.75 3.34375 8.40625 3 7.96875 3C7.5625 3 7.21875 3.34375 7.21875 3.75L7.25 8Z'
        fill='#2173F0'
      />
    </svg>
  );
};
