import { FC, useState } from 'react';
import { useEffect } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import routeNames from '@/routes/routeNames';
import { ConStore } from '@/state/ConversationStore';
import { Conversation } from '@/types/conversation.types';
import { observer } from 'mobx-react-lite';
import UserInfo from '../UserInfo';
import LeadNotes from '../LeadNotes';
import { CONVERSATION_SORT, CONVERSATION_STATUS } from '@/constants/index';
import { useChatConversation } from '@/hooks/useChatConversation';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { User } from '@/types/user.types';
import { AuthStore } from '@/state/AuthenticationStore';

const ConversationProfilePanel: FC<any> = props => {
  const handleBack = () => {
    props.navigation.goBack(0);
  };
  const navigate = useNavigate();
  const [current_conversation, set_current_conversation] = useState<
    Conversation | undefined | null
  >(null);
  const [current_user_info, set_current_info] = useState<User | null>(null);

  const { update_conversation_status, getConversations } =
    useChatConversation();
  // https://www.google.com/maps/place/lat,lng
  const ToogleConversationStatus = () => {
    if (
      ConStore.cons.get(ConStore.selected_conversation_id as string)?.status ===
      CONVERSATION_STATUS.OPEN
    ) {
      update_conversation_status({
        conversation_id: ConStore.selected_conversation_id as string,
        status: CONVERSATION_STATUS.CLOSED,
      });
      getConversations({
        page: 1,
        status: CONVERSATION_STATUS.OPEN,
        sort: ConStore.sorted_by === CONVERSATION_SORT.LATEST ? -1 : 1,
        channel: props.conversationChannel,
      });
      ConStore.set_selected_conversation_id(null);
    } else {
      update_conversation_status({
        conversation_id: ConStore.selected_conversation_id as string,
        status: CONVERSATION_STATUS.OPEN,
      });
      getConversations({
        page: 1,
        status: CONVERSATION_STATUS.CLOSED,
        sort: ConStore.sorted_by === CONVERSATION_SORT.LATEST ? -1 : 1,
        channel: props.conversationChannel,
      });
      ConStore.set_selected_conversation_id(null);
    }
  };
  useEffect(() => {
    const current_conversation_result = ConStore.cons.get(
      ConStore.selected_conversation_id as string,
    );
    if (current_conversation_result) {
      set_current_conversation(current_conversation_result);
    }
    if (current_user_info) {
      set_current_info(current_user_info);
    }
    // set_current_info();
  }, [ConStore.selected_conversation_id, ConStore.reRenderMessages]);

  useEffect(() => {
    const current_conversation = ConStore.cons.get(
      ConStore.selected_conversation_id as string,
    );
    if (current_conversation?.lead) {
      const current_users_info = AuthStore.users_info.get(
        (current_conversation.lead as User)._id,
      );
      if (current_users_info) {
        set_current_info(current_users_info);
      }
    }
  }, [ConStore.selected_conversation_id, AuthStore.users_info]);

  return (
    <div className='shrink-0 md:min-w-[192px] md:w-[20%] w-full h-full bg-[#FBFCFD] flex flex-col'>
      {/* <RightSidePanel className="overflows pb-[250px]" /> */}
      <div className='md:hidden flex flex-col mt-[50px]'>
        <div
          onClick={() => handleBack()}
          className='flex text-secondary cursor-pointer ml-3 space-x-4 py-4'
        >
          <AiOutlineArrowLeft className='w-5 h-5' />
          <span className='text-md'>{current_user_info?.user_name}</span>
        </div>
        <div className=' border border-border border-b-0 w-full mt-[10px]' />
      </div>
      <div className='w-full  shrink-0 border-b flex flex-col justify-between border-[#EFEFEF] pb-6  p-4 3xl:p-4'>
        <div className='flex justify-between w-full mb-[6px]'>
          <p className='text-[12px] 3xl:text-[14.5px] 2xl:text-[13.5px] font-medium text-[#222124]'>
            Conversation details
          </p>
          <FiSettings
            className='text-gray-500 cursor-pointer h-[13px] w-[13px] 3xl:h-[17px] 3xl:w-[17px] 2xl:h-[15px] 2xl:w-[15px] sr-only'
            onClick={() => navigate(routeNames.dashboard.settings)}
          />
        </div>
        <div className='flex flex-row items-center'>
          <p className='text-[12px] 3xl:text-[13px] 2xl:text-[12px]  font-normal text-[#5F5F61] mr-2'>
            ID{' '}
          </p>
          {current_conversation ? (
            <p className='text-[12px] 3xl:text-[13px]  2xl:text-[12px] font-normal text-[#5F5F61] overflow-hidden whitespace-no-wrap truncate'>
              {ConStore.selected_conversation_id}
            </p>
          ) : null}
        </div>
      </div>
      <div className='overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded flex-1  p-4 3xl:p-4'>
        <UserInfo />
        <LeadNotes />
      </div>
      <div className='p-4 3xl:p-4'>
        <div
          className={`w-full justify-center flex flex-row items-center border border-[#DFE1E6]  shadow-sm rounded-[4px] h-[32px] cursor-pointer ${
            ConStore.cons.get(ConStore.selected_conversation_id as string)
              ?.updating_convo
              ? 'pointer-events-none opacity-50'
              : ''
          }`}
          onClick={ToogleConversationStatus}
        >
          <p className='text-[14px] 3xl:text-[13.5px] 2xl:text-[12.5px] font-medium text-[#161518] '>
            {ConStore.cons.get(ConStore.selected_conversation_id as string)
              ?.status === CONVERSATION_STATUS.OPEN
              ? 'Archive Chat'
              : 'Open Chat'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(ConversationProfilePanel);
