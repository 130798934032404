import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import { MdDelete } from 'react-icons/md';
import { Switch } from '@headlessui/react';
import { loyaltyStore } from '@/state/LoyaltyStore';

interface cardFieldProps {
  title: string;
  type: string;
  isRequired: boolean;
  isUnique: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const CardIssue: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const options = [
    {
      value: 'firstName',
      label: 'First Name',
    },
    {
      value: 'lastName',
      label: 'Last Name',
    },
    {
      value: 'phone',
      label: 'Phone',
    },
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'dob',
      label: 'Date of Birth',
    },
  ];

  const initialState = [
    {
      title: 'firstName',
      type: '',
      isRequired: true,
      isUnique: true,
    },
    {
      title: 'lastName',
      type: '',
      isRequired: true,
      isUnique: true,
    },
    {
      title: 'phone',
      type: '',
      isRequired: true,
      isUnique: true,
    },
    {
      title: 'email',
      type: '',
      isRequired: true,
      isUnique: true,
    },
    {
      title: 'dob',
      type: '',
      isRequired: true,
      isUnique: true,
    },
  ];
  const [cardField, setCardField] = useState<cardFieldProps[]>(initialState);
  const [fileError, setFileError] = useState<string>('');

  useEffect(() => {
    const cardArr = loyaltyStore.cardIssueObj.filter(_ => _.title !== '');
    const result = [...cardArr, ...initialState].filter(
      (elem, index, self) =>
        self.findIndex(t => {
          return t.title === elem.title;
        }) === index,
    );

    setCardField(result || []);
  }, []);

  const handleOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    cardField[index].type = e.target.value;
    cardField[index].title = e.target.value;
    if (e.target.value == 'phone' || e.target.value == 'email') {
      cardField[index].isUnique = cardField[index].isUnique || true;
    } else {
      cardField[index].isUnique = false;
    }

    setCardField([...cardField]);
    loyaltyStore.setShowCardIssueError(false);
  };

  const handleToggle = (type: string, flag: boolean, index: number) => {
    const newArray = [...cardField];
    newArray[index] = { ...newArray[index], [type]: !flag };
    setCardField(newArray);
  };

  const deleteIssue = (index: number) => {
    const newArray = [...cardField];
    newArray[index] = {
      title: '',
      type: '',
      isRequired: false,
      isUnique: false,
    };
    setCardField(newArray);
  };

  const onClose = () => {
    loyaltyStore.setCardIssueObj(
      getMode == 'update' ? loyaltyStore.loyalty?.metaData || [] : initialState,
    );
    setCardField(
      getMode == 'update' ? loyaltyStore.loyalty?.metaData || [] : initialState,
    );
    handleCancel();
  };

  const onSumbit = () => {
    if (cardField.every((e: any) => e.type === '')) {
      setFileError(
        'Form must include at least one unique field Phone or Email',
      );
    } else if (
      cardField.some(e => e.type == 'email') &&
      cardField.some(e => e.type == 'phone')
    ) {
      if (
        cardField.some(e => e.type == 'email' && e.isUnique == true) ||
        cardField.some(e => e.type == 'phone' && e.isUnique == true)
      ) {
        loyaltyStore.setCardIssueObj(cardField);
        setFileError('');
        handleCancel();
      } else {
        setFileError(
          'Form must include at least one unique field Phone or Email',
        );
      }
    } else {
      loyaltyStore.setCardIssueObj(cardField);
      setFileError('');
      handleCancel();
    }
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full '>
      <div className='flex flex-row w-full mb-[24px] '>
        <div className='flex flex-col mt-[15px] mr-[24px] flex-1'>
          <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80'>
            Field
          </p>
        </div>

        <div className='flex flex-col mt-[15px] mr-[24px] flex-1 items-center'>
          <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80'>
            Required
          </p>
        </div>

        <div className='flex flex-col mt-[15px]  mr-[24px]  flex-1 items-center'>
          <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80'>
            Unique
          </p>
        </div>

        <div className='flex flex-col mt-[15px]  flex-1 items-center'></div>
      </div>

      {options.map((item, index) => {
        return (
          <div
            className='flex flex-row w-full mb-[24px] items-center'
            key={index}
          >
            <div className='flex flex-col mt-[15px] mr-[24px] flex-1 mt-[9px]'>
              <div className={`flex flex-col`}>
                <div
                  className={`relative flex flex-row items-center rounded-md border`}
                >
                  <select
                    id='select_input_time_zone'
                    value={cardField[index].type}
                    onChange={(e: any) => handleOption(e, index)}
                    className={`relative flex items-center border rounded-[4px!important] space-x-2 text-md px-2  py-2 w-full outline-none border-gray-50`}
                  >
                    <option value=''></option>

                    {options.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item.value}
                          disabled={!!cardField.find(f => f.type == item.value)}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className='flex flex-col mt-[15px] mr-[24px] flex-1 items-center'>
              <Switch
                checked={cardField[index].isRequired}
                onChange={() =>
                  handleToggle('isRequired', cardField[index].isRequired, index)
                }
                className={classNames(
                  cardField[index].isRequired ? 'bg-green' : 'bg-gray-400',
                  'relative inline-flex  p-1 flex-shrink-0 2xl:h-[28px] 2xl:w-14  xl:w-12 xl:h-[24px] rounded-[4px] border-transparent  cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                )}
              >
                <span className='sr-only'>On</span>
                <span
                  className={classNames(
                    cardField[index].isRequired
                      ? '2xl:translate-x-6 xl:translate-x-5 translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none relative inline-block h-full w-[50%] rounded-[3px] bg-white transform ring-0 transition ease-in-out duration-200',
                  )}
                >
                  <span
                    className={classNames(
                      cardField[index].isRequired
                        ? 'opacity-0 ease-out duration-100'
                        : 'opacity-100 ease-in duration-200',
                      'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                    )}
                    aria-hidden='true'
                  ></span>
                  <span
                    className={classNames(
                      cardField[index].isRequired
                        ? 'opacity-100 ease-in duration-200'
                        : 'opacity-0 ease-out duration-100',
                      'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                    )}
                    aria-hidden='true'
                  ></span>
                </span>
                <span
                  className={` font-bold 2xl:text-[13px] xl:text-[10px] text-white absolute left-1`}
                >
                  On
                </span>
                <span
                  className={` font-bold 2xl:text-[13px] xl:text-[10px] text-white mx-1`}
                >
                  Off
                </span>
              </Switch>
            </div>

            <div className='flex flex-col mt-[15px] mr-[24px] flex-1 items-center'>
              {cardField[index].type === 'firstName' ? null : cardField[index]
                  .type === 'lastName' ? null : cardField[index].type ===
                'dob' ? null : (
                <Switch
                  checked={cardField[index].isUnique}
                  onChange={() =>
                    handleToggle('isUnique', cardField[index].isUnique, index)
                  }
                  className={classNames(
                    cardField[index].isUnique ? 'bg-green' : 'bg-gray-400',
                    'relative inline-flex p-1 flex-shrink-0 2xl:h-[28px] 2xl:w-14  xl:w-12 xl:h-[24px] rounded-[4px] border-transparent  cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                  )}
                >
                  <span className='sr-only'>On</span>
                  <span
                    className={classNames(
                      cardField[index].isUnique
                        ? '2xl:translate-x-6 xl:translate-x-5 translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none relative inline-block h-full w-[50%] rounded-[3px] bg-white transform ring-0 transition ease-in-out duration-200',
                    )}
                  >
                    <span
                      className={classNames(
                        cardField[index].isUnique
                          ? 'opacity-0 ease-out duration-100'
                          : 'opacity-100 ease-in duration-200',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                      )}
                      aria-hidden='true'
                    ></span>
                    <span
                      className={classNames(
                        cardField[index].isUnique
                          ? 'opacity-100 ease-in duration-200'
                          : 'opacity-0 ease-out duration-100',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                      )}
                      aria-hidden='true'
                    ></span>
                  </span>
                  <span
                    className={` font-bold 2xl:text-[13px] xl:text-[10px] text-white absolute left-1`}
                  >
                    On
                  </span>
                  <span
                    className={` font-bold 2xl:text-[13px] xl:text-[10px] text-white mx-1`}
                  >
                    Off
                  </span>
                </Switch>
              )}
            </div>

            <div className='flex flex-col mt-[15px]  flex-1 items-center'>
              <div
                className='px-1 py-1 bg-white rounded
                          shadow border border-zinc-200 justify-start
                          items-center gap-3 cursor-pointer flex'
                onClick={() => deleteIssue(index)}
              >
                <MdDelete className='w-5 h-5 text-warning' />
              </div>
            </div>
          </div>
        );
      })}

      {fileError && (
        <p className='mb-[6px]' style={{ color: 'red' }}>
          {fileError}
        </p>
      )}

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          loadingText='Saving'
          size='sm'
          className='bg-lightBlue'
          onClick={onSumbit}
        />
      </div>
    </div>
  );
};
export default observer(CardIssue);
