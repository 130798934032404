export const ServiceIcon = () => {
  return (
    <svg
      width='17'
      height='20'
      viewBox='0 0 17 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5598 4.21801L9.95115 0.609353C9.86914 0.527338 9.78712 0.5 9.67777 0.5C9.45906 0.5 9.29503 0.66403 9.29503 0.882737V3.7806C9.29503 4.38204 9.78712 4.87413 10.3886 4.87413H13.2864C13.5051 4.87413 13.6692 4.7101 13.6692 4.4914C13.6692 4.38204 13.6418 4.30003 13.5598 4.21801ZM13.6692 7.0612C13.6692 6.76048 13.4231 6.51443 13.1224 6.51443H9.29503C8.39287 6.51443 7.65473 5.7763 7.65473 4.87413V1.04677C7.65473 0.746045 7.40869 0.5 7.10796 0.5H1.6403C0.738135 0.5 0 1.23813 0 2.1403V15.2627C0 16.1649 0.738135 16.903 1.6403 16.903C1.6403 16.903 6.75257 16.903 7.18998 16.903C7.62739 16.903 7.73675 16.6023 7.70941 16.1375C7.68207 15.6728 7.51804 13.6771 8.88496 12.0915C10.2519 10.5058 12.4936 10.3965 12.8217 10.3965C13.1497 10.3965 13.6965 10.3691 13.6692 9.90438C13.6418 9.43963 13.6692 7.0612 13.6692 7.0612ZM1.91368 3.94463L3.25326 3.75326C3.2806 3.75326 3.33528 3.72592 3.33528 3.69858L3.93672 2.46836C3.9914 2.38634 4.10075 2.38634 4.15543 2.46836L4.75687 3.69858C4.78421 3.72592 4.81155 3.75326 4.83888 3.75326L6.17846 3.94463C6.26048 3.97197 6.31515 4.08132 6.23314 4.136L5.24896 5.09284C5.22162 5.12018 5.22162 5.14752 5.22162 5.20219L5.44033 6.54177C5.46767 6.62379 5.35831 6.7058 5.2763 6.65112L4.07341 6.02234C4.04607 5.995 3.9914 5.995 3.96406 6.02234L2.76117 6.65112C2.67916 6.7058 2.5698 6.62379 2.59714 6.54177L2.81585 5.20219C2.81585 5.17485 2.81585 5.12018 2.78851 5.09284L1.80433 4.136C1.77699 4.08132 1.83167 3.97197 1.91368 3.94463ZM6.83458 13.0756C6.83458 13.3764 6.58854 13.6224 6.28782 13.6224H2.73383C2.43311 13.6224 2.18707 13.3764 2.18707 13.0756V12.5289C2.18707 12.2281 2.43311 11.9821 2.73383 11.9821H6.28782C6.58854 11.9821 6.83458 12.2281 6.83458 12.5289V13.0756ZM9.8418 9.79503C9.8418 10.0958 9.59575 10.3418 9.29503 10.3418H2.73383C2.43311 10.3418 2.18707 10.0958 2.18707 9.79503V9.24826C2.18707 8.94754 2.43311 8.7015 2.73383 8.7015H9.29503C9.59575 8.7015 9.8418 8.94754 9.8418 9.24826V9.79503Z'
        fill='#2173F0'
      />
      <path
        d='M12.7941 11.6807C10.6344 11.6807 8.88477 13.4303 8.88477 15.59C8.88477 17.7498 10.6344 19.4994 12.7941 19.4994C14.9539 19.4994 16.7035 17.7498 16.7035 15.59C16.7035 13.4303 14.9265 11.6807 12.7941 11.6807ZM13.4776 15.9454C13.3683 15.9454 13.2589 15.9181 13.1495 15.8908L11.6459 17.367C11.5366 17.4764 11.4272 17.5037 11.3179 17.5037C11.1812 17.5037 11.0992 17.4764 10.9898 17.367C10.8258 17.203 10.8258 16.9023 10.9898 16.7109L12.4934 15.2073C12.4661 15.1253 12.4387 15.0159 12.4387 14.9066C12.3841 14.2231 12.9308 13.5943 13.6143 13.5943C13.7236 13.5943 13.833 13.6217 13.9424 13.649C13.997 13.649 13.997 13.7037 13.9697 13.7584L13.3136 14.4418C13.2589 14.4692 13.2589 14.5785 13.3136 14.6059L13.7783 15.0706C13.833 15.1253 13.915 15.1253 13.9697 15.0706L14.6258 14.4145C14.6532 14.3872 14.7352 14.3872 14.7352 14.4418C14.7625 14.5512 14.7898 14.6605 14.7898 14.7699C14.7625 15.4534 14.1884 16.0001 13.4776 15.9454Z'
        fill='#2173F0'
      />
    </svg>
  );
};
