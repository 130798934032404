import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import RadioInput from '@/components/atoms/RadioInput';
import { loyaltyStore } from '@/state/LoyaltyStore';

interface PointEarnedProps {
  earnedType: string;
  earnPoints: number;
  pointToGet: number;
}

const PointsEarned: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const [earn, setEarn] = useState<PointEarnedProps>({
    earnedType: 'Spend',
    earnPoints: 0,
    pointToGet: 0,
  });

  useEffect(() => {
    setEarn(loyaltyStore.pointEarnsObj);
  }, []);

  const onSubmit = () => {
    loyaltyStore.setPointsEarnsObj(earn);
    handleCancel();
  };

  const onClose = () => {
    setEarn(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[1].value
        : {
            earnedType: 'Spend',
            earnPoints: 0,
            pointToGet: 0,
          },
    );
    loyaltyStore.setPointsEarnsObj(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[1].value
        : {
            earnedType: 'Spend',
            earnPoints: 0,
            pointToGet: 0,
          },
    );
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='w-full mb-[24px]'>
        <div className='flex flex-1'>
          <RadioInput
            label='Spend'
            key={0}
            checked={earn.earnedType == 'Spend'}
            onChange={() => setEarn({ ...earn, earnedType: 'Spend' })}
          />
          <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 ml-1'>
            (Give points based on the customer spend)
          </p>
        </div>
      </div>

      <div className='w-full mb-[24px]'>
        <div className='flex flex-1'>
          <RadioInput
            label='Visit'
            key={0}
            checked={earn.earnedType == 'Visit'}
            onChange={() => setEarn({ ...earn, earnedType: 'Visit' })}
          />
          <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 ml-1'>
            (Give points based on the customer spend)
          </p>
        </div>
      </div>

      <div className='w-full mb-[24px]'>
        <div className='flex flex-1'>
          <RadioInput
            label='Points'
            key={0}
            checked={earn.earnedType == 'Points'}
            onChange={() => setEarn({ ...earn, earnedType: 'Points' })}
          />
          <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 ml-1'>
            (Give points based on the customer spend)
          </p>
        </div>
      </div>

      <div className='w-full mb-[24px]'>
        <p className='text-[14px] leading-[20px] text-black'>
          How do your customers earn points?
        </p>

        <div className='flex items-center mt-4'>
          <div className='flex flex-col mr-[24px] flex-1'>
            <p className='text-[16px] leading-[20px] text-gray-400 text-opacity-80'>
              {earn.earnedType}
            </p>
            <Input
              value={earn.earnPoints}
              placeholder='$10'
              type='text'
              className='mt-2'
              onChange={e => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setEarn({ ...earn, earnPoints: Number(e.target.value) });
                }
              }}
              maxLength={16}
            />
          </div>

          <div className='mr-[24px] mt-2'> = </div>

          <div className='flex flex-col flex-1 '>
            <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80'>
              Points to get
            </p>

            <Input
              value={earn.pointToGet}
              placeholder='1000'
              type='text'
              className='mt-2'
              onChange={e => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setEarn({ ...earn, pointToGet: Number(e.target.value) });
                }
              }}
              maxLength={16}
            />
          </div>
        </div>
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};
export default observer(PointsEarned);
