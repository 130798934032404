import { ConStore } from '@/state/ConversationStore';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, useState } from 'react';

interface TabProps {
  height?: number;
  onTabChange: void | any;
  children?: ReactNode;
}

interface TabPanelProps {
  children?: ReactNode;
  title: string;
  disabled?: boolean;
  isRibbon?: boolean;
  isIcon?: boolean;
  icon?: ReactNode;
  count?: number;
  conversationChannel?: string;
}

const Tab: FC<TabProps> = ({ height, children, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber + 1);
    ConStore.set_selected_conversation_id(null);
    onTabChange(tabNumber);
  };

  return (
    <div>
      <div
        className={`w-full h-${height} md:bg-gray-100 bg-white md:p-[2px] px-4 rounded-[8px] flex-1`}
      >
        <div className='flex items-center w-full space-x-2 md:space-x-0  overflow-x-auto'>
          {React.Children.map(children, (child: any, index) => {
            const isActive = index + 1 === activeTab;
            const tabPanel = child;

            return (
              <div
                onClick={() =>
                  !tabPanel.props.disabled && handleTabClick(index)
                }
                className={`flex-1 flex-nowrap ${
                  tabPanel.props.disabled ? 'cursor-pointer' : 'cursor-pointer'
                }`}
              >
                <span
                  className={`${
                    isActive
                      ? 'md:bg-white bg-gray-100 md:shadow md:border md:border-black md:border-opacity-20'
                      : ''
                  } cursor-pointer flex items-center justify-center text-center p-2 rounded-[7px] w-[100%]`}
                >
                  <p className='text-[12px] font-medium leading-4 flex-none'>
                    {tabPanel.props.title}
                  </p>
                  {tabPanel.props.isRibbon && tabPanel.props.count > 0 && (
                    <span
                      className='bg-warning flex flex-col justify-center items-center 
                   text-white text-[10px] rounded-[4px] w-[20px] h-[17px] p-[0.5px]  ml-2 shadow-sm'
                    >
                      {tabPanel.props.count >= 10
                        ? '10+'
                        : tabPanel.props.count}
                    </span>
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {React.Children.map(children, (child: any, index: number) => {
          const tabPanel = child;

          if (index + 1 === activeTab) {
            return tabPanel.props.children;
          }
          return null;
        })}
      </div>
    </div>
  );
};

const TabPanel: FC<TabPanelProps> = observer(({ children }) => {
  return (
    <div className='flex flex-col justify-center items-center'>{children}</div>
  );
});

export { Tab, TabPanel };
