import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import { loyaltyStore } from '@/state/LoyaltyStore';

const ColorOnCard: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const [bgColor, setBgColor] = useState<string>('#FFFFFF');
  const [labelColor, setLabelColor] = useState<string>('#4285f4');
  const [valueColor, setValueColor] = useState<string>('#7C7C7C');

  useEffect(() => {
    setBgColor(loyaltyStore.cardBGcolor || '');
    setLabelColor(loyaltyStore.textLabelColor || '');
    setValueColor(loyaltyStore.textValueColor || '');
  }, []);

  const onSumbit = () => {
    loyaltyStore.setCardBGcolor(bgColor);
    loyaltyStore.setTextLabelColor(labelColor);
    loyaltyStore.setTextValueColor(valueColor);
    handleCancel();
  };

  const onClose = () => {
    setBgColor(
      getMode == 'update'
        ? loyaltyStore.loyalty?.googlePayload.hexBackgroundColor || ''
        : '',
    );
    setLabelColor(
      getMode == 'update'
        ? loyaltyStore.loyalty?.applePayload.labelColor || ''
        : '',
    );
    setValueColor(
      getMode == 'update'
        ? loyaltyStore.loyalty?.applePayload.foregroundColor || ''
        : '',
    );
    loyaltyStore.setCardBGcolor(
      getMode == 'update'
        ? loyaltyStore.loyalty?.googlePayload.hexBackgroundColor || ''
        : '',
    );
    loyaltyStore.setTextLabelColor(
      getMode == 'update'
        ? loyaltyStore.loyalty?.applePayload.labelColor || ''
        : '',
    );
    loyaltyStore.setTextValueColor(
      getMode == 'update'
        ? loyaltyStore.loyalty?.applePayload.foregroundColor || ''
        : '',
    );
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full '>
      <div className='flex flex-row w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px] mr-[24px] flex-1'>
          <span className='font-[500] text-[14px] leading-[18px] pb-[10px] text-gray-400 text-opacity-90'>
            Card background
          </span>
          <div className='rounded-[4px] border-gray-300 bg-white border-[1px] border-b-[2px] flex   h-[40px] font-medium p-3'>
            <div
              className='flex items-center justify-center rounded-full  w-[16px] h-[16px] shrink-0 border border-gray-300 mr-[8px]'
              style={{ backgroundColor: bgColor }}
            >
              <input
                onChange={event => setBgColor(event.target.value)}
                type='color'
                id='cardBack'
                name='cardBack'
                value={bgColor}
                className='opacity-0 rounded-full w-full h-full bg-transparent'
              />
            </div>
            <input
              type='text'
              onChange={event => setBgColor(event.target.value)}
              value={bgColor}
              className='w-full h-full outline-none uppercase border-0 bg-transparent font-[500] text-[14px]'
            />
          </div>
        </div>

        {loyaltyStore.activeDevice == 0 && (
          <div className='flex flex-col mt-[15px]  flex-1'>
            <span className='font-[500] text-[14px] leading-[18px] pb-[10px] text-gray-400 text-opacity-90'>
              Text Label color
            </span>
            <div className='rounded-[4px] border-gray-300 bg-white border-[1px] border-b-[2px] flex  items-center justify-center h-[40px] font-medium p-3'>
              <div
                className='flex items-center justify-center rounded-full w-[16px] h-[16px] shrink-0 border  border-gray-300 mr-[8px]'
                style={{ backgroundColor: labelColor }}
              >
                <input
                  onChange={event => setLabelColor(event.target.value)}
                  type='color'
                  id='head'
                  name='head'
                  value={labelColor}
                  className='opacity-0 rounded-full w-full h-full bg-transparent'
                />
              </div>
              <input
                type='text'
                onChange={event => setLabelColor(event.target.value)}
                value={labelColor}
                className='w-full h-full outline-none uppercase  border-0  bg-transparent font-[500] text-[14px]'
              />
            </div>
          </div>
        )}
      </div>
      {loyaltyStore.activeDevice == 0 && (
        <div className='flex flex-row w-[51%] mb-6'>
          <div className='flex flex-col mt-[15px] mr-[24px] flex-1'>
            <span className='font-[500] text-[14px] leading-[18px] pb-[10px] text-gray-400 text-opacity-90'>
              Text Value color
            </span>
            <div className='rounded-[4px] border-gray-300 bg-white border-[1px] border-b-[2px] flex  items-center justify-center h-[40px] font-medium p-3'>
              <div
                className='flex items-center justify-center rounded-full w-[16px] h-[16px] shrink-0 border border-gray-300  mr-[8px]'
                style={{ backgroundColor: valueColor }}
              >
                <input
                  onChange={event => setValueColor(event.target.value)}
                  type='color'
                  id='head'
                  name='head'
                  value={valueColor}
                  className='opacity-0 rounded-full w-full h-full bg-transparent'
                />
              </div>
              <input
                type='text'
                onChange={event => setValueColor(event.target.value)}
                value={valueColor}
                className='w-full h-full outline-none uppercase  border-0  bg-transparent font-[500] text-[14px]'
              />
            </div>
          </div>
        </div>
      )}
      <div className='flex flex-row   items-center justify-between bg-gray-150 rounded-[4px] shadow-sm p-[16px]'>
        <p className='font-[600] text-[12px]'>
          Show background color on card issuing form
        </p>
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          loadingText='Saving'
          size='sm'
          className='bg-lightBlue'
          onClick={onSumbit}
        />
      </div>
    </div>
  );
};
export default observer(ColorOnCard);
