import Avatar from '@/components/atoms/Avatar';
import Button from '@/components/atoms/Button';
import EmptyList from '@/components/atoms/EmptyList';
import Spinner from '@/components/atoms/Spinner';
import Modal from '@/components/atoms/modal';
import { DataTable } from '@/components/templates/DataTable';
import { teamMateStore } from '@/state/TeammateStore';
import { Invitation } from '@/types/teammate.types';
import { User } from '@/types/user.types';
import { getInitialsFromName } from '@/utils/index';
import { createColumnHelper } from '@tanstack/react-table';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';

type InvitationTableProps = {
  invitations: Invitation[];
  loading: boolean;
  handleResendVerificationEmail?: (email: string) => void;
  deleteInvite: (inviteId: string) => void;
};

const columnHelper = createColumnHelper<Invitation>();

const InvitesTable: FC<InvitationTableProps> = ({
  invitations,
  loading,
  handleResendVerificationEmail,
  deleteInvite,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedInvitation, setSelectedInvitation] =
    useState<Invitation | null>();

  const closeResendModal = () => {
    setIsOpen(false);
  };

  const openResendModal = (selectedInvite: Invitation) => {
    setSelectedInvitation(selectedInvite);
    setIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const openDeleteModal = (selectedInvite: Invitation) => {
    setSelectedInvitation(selectedInvite);
    setDeleteModal(true);
  };

  const invitationColumns = useMemo(
    () => [
      columnHelper.accessor('inviter', {
        id: 'name',
        cell: info => (
          <div className='flex items-center'>
            <Avatar
              alt={getInitialsFromName((info.getValue() as User)?.email)}
              src=''
              size='md'
            />
            <div className='ml-2'>
              <div className='font-medium text-[14px] text-gray-900'>
                {(info.getValue() as User)?.email}
              </div>
              <div className='text-gray-500'>{info.row.original.status}</div>
            </div>
          </div>
        ),
        header: () => <span>Name</span>,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: info => <div>{info.getValue()}</div>,
        header: () => <span>Status</span>,
      }),
      // columnHelper.accessor('teams', {
      //   id: 'teams',
      //   cell: info => <div>{info.getValue()}</div>,
      //   header: () => <span>Teams</span>,
      // }),
      columnHelper.accessor('_id', {
        id: 'actions',
        cell: info => {
          const invitation = info.row.original;
          return (
            <div className='flex gap-3'>
              <Button
                className='bg-warning hover:bg-warning/80'
                onClick={() => openDeleteModal(invitation)}
                text='Delete'
              />
              {invitation?.status === 'PENDING' && (
                <Button
                  onClick={() => openResendModal(invitation)}
                  text='Resend'
                />
              )}
            </div>
          );
        },
        header: () => <span>Actions</span>,
      }),
    ],
    [columnHelper, invitations],
  );

  return loading ? (
    <div className='h-full w-full flex flex-row justify-center items-center'>
      <Spinner size={40} color={'#033EB5'} />
    </div>
  ) : (
    <>
      <DataTable
        columns={invitationColumns as any}
        data={invitations}
        headerClassName='bg-gray-100 text-lightBlack font-medium text-md first:w-[initial] last:w-[200px] py-2'
        showPagination={invitations.length > 0}
        noDataComponent={
          <EmptyList
            listName={'Invites sent'}
            title='There are no'
            subTitle='Add '
            height='70vh'
          />
        }
      />

      <Modal
        show={isOpen}
        openModal={() => setIsOpen(true)}
        closeModal={closeResendModal}
        title='Are you sure you want to resend'
        className='inline-block py-6 my-8 w-[500px] overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <div className='flex flex-col px-4'>
          <div className='py-4 text-gray-500 2xl:text-[15px] xl:text-[12px]'>
            {'By clicking "Confirm" we will send an email to'}{' '}
            <span className='font-bold'>{selectedInvitation?.email}</span> to
            verify their email address.
          </div>
        </div>

        <div className='border border-b-0'></div>
        <div className='pt-4 px-4 flex gap-3 flex-row-reverse'>
          <Button
            type='button'
            text='Confirm'
            size='sm'
            loading={teamMateStore.sendingInvite}
            onClick={() => {
              handleResendVerificationEmail?.(
                selectedInvitation?.email as string,
              );
              closeResendModal();
            }}
          />

          <Button
            onClick={closeResendModal}
            type='button'
            text='Cancel'
            size='sm'
            variant='outline'
          />
        </div>
      </Modal>

      <Modal
        show={deleteModal}
        closeModal={closeDeleteModal}
        title='Are you sure you want to delete this teammate?'
        className='inline-block py-6 my-8 w-[500px] overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <div className='flex flex-col px-4'>
          <div className='py-4 text-gray-500 2xl:text-[15px] xl:text-[12px]'>
            {'By clicking "Confirm"'}{' '}
            <span className='font-bold'>
              {(selectedInvitation?.inviter as User)?.user_name}
            </span>{' '}
            will be removed from the workspace and will no longer have access to
            any data
          </div>
        </div>

        <div className='border border-b-0'></div>
        <div className='pt-4 px-4 flex gap-3 flex-row-reverse'>
          <Button
            type='button'
            text='Confirm'
            size='sm'
            loading={teamMateStore.sendingInvite}
            onClick={() => {
              deleteInvite(selectedInvitation?._id as string);
              closeDeleteModal();
            }}
          />

          <Button
            onClick={closeDeleteModal}
            type='button'
            text='Cancel'
            size='sm'
            variant='outline'
          />
        </div>
      </Modal>
    </>
  );
};
export default observer(InvitesTable);
