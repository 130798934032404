import MainChatUi from '@/components/templates/MainChatUi';

const Messenger = () => {
  return (
    <>
      <MainChatUi conversationChannel='MESSENGER' title='Messenger' />
    </>
  );
};
export default Messenger;
