import Button from '@/components/atoms/Button';
import { useLoyalty } from '@/hooks/useLoyalty';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { loyaltyStore } from '@/state/LoyaltyStore';
import { replaceRouteParam } from '@/utils/routes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RewardIcon from '../../../../assets/images/Reward.png';

function CardType() {
  const { getCardType } = useLoyalty();
  const navigate = useNavigate();

  const [type, setType] = useState<string>('');

  useEffect(() => {
    getCardType(type);
  }, []);

  const getCardDetails = (id: string) => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.home}/loyalty/design?mode=update&id=${id}`,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      ),
    );
  };

  return (
    <div className='w-full flex flex-row max-h-[94vh] py-3'>
      <div className='pl-6 py-6 overflow-y-auto small-scrollbar w-[40%]'>
        <div className='w-[100%]'>
          <div className='flex flex-row flex-wrap gap-8 py-2 cursor-pointer '>
            <div
              className='flex bg-white items-center justify-center py-3 rounded-[9px] w-[180px] h-[180px]'
              onClick={() => setType('reward')}
            >
              <div className='text-center'>
                <div className='flex w-auto h-[50px] justify-center'>
                  <img src={RewardIcon} className=' w-[40px] h-[40px]' />
                </div>
                <h2 className=''>Reward</h2>
                <div className='bg-gray-25 py-1 px-3 rounded-[30px] mt-[12px]'>
                  <p className='text-[13px] leading-[25px]'>High retention</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex py-6 w-[60%]'>
        <div className='flex  ml-6 overflow-y-auto flex-wrap gap-4 py-2'>
          <div className='flex w-[180px] h-[180px]'>
            <Button
              type='button'
              text={'Create New Card'}
              size='sm'
              className='bg-lightBlue w-full h-full'
              onClick={() =>
                navigate(
                  replaceRouteParam(
                    `${routeNames.dashboard.home}/loyalty/design?mode=create`,
                    'workspaceId',
                    AuthStore?.user_workspace_info?.active_workspace?.workspace
                      ?._id,
                  ),
                )
              }
            />
          </div>
          {loyaltyStore.cardTypeList.length
            ? loyaltyStore.cardTypeList.map((item, index) => {
                return (
                  <div
                    className='flex bg-white items-center justify-center py-3 rounded-[9px] w-[180px] h-[180px] cursor-pointer'
                    key={index}
                    onClick={() => getCardDetails(item?._id || '')}
                  >
                    <div className='text-center'>
                      <div className='flex w-auto h-[50px] justify-center'>
                        <img src={RewardIcon} className=' w-[40px] h-[40px]' />
                      </div>
                      <h2 className=''>{item.dePassName || 'Demo'}</h2>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default observer(CardType);
