import { useEffect } from 'react';
import Spinner from '@/components/atoms/Spinner';
import Text from '@/components/atoms/Text';
import WidgetUi from '@/components/organisms/WidgetUi';
import { useWidgetConfig } from '@/hooks/useWidgetConfig';
import { WidgetConfigStore } from '@/state/WidgetConfigStore';
import { observer } from 'mobx-react-lite';
import Styling from '../Styling';
import General from '../General';
import Offers from '../Offers';
import Setup from '../Setup';
import SparkGPTPage from '../SparkGPTPage';
import { setCSSVariables } from '@/utils/index';

const Configurations = () => {
  const { get_widget_config } = useWidgetConfig();
  useEffect(() => {
    if (WidgetConfigStore.config.value) {
      setCSSVariables();
    }
  }, [
    WidgetConfigStore.config.value?.colors.header_bg_color,
    WidgetConfigStore.config.value?.colors.header_text_color,
    WidgetConfigStore.config.value?.colors.border_color,
    WidgetConfigStore.config.value?.colors.btn_color,
    WidgetConfigStore.config.value?.colors.btn_text_color,
    WidgetConfigStore.config.value?.colors.header_bg_color,
  ]);

  useEffect(() => {
    if (!WidgetConfigStore.config.value) {
      //  request config here
      get_widget_config();
    }
  }, [WidgetConfigStore.config.value]);

  if (WidgetConfigStore.config.fetching) {
    return (
      <div className='h-full w-full flex justify-center items-center'>
        <Spinner size={30} color={'#033EB5'} />
      </div>
    );
  }

  return (
    <div className='w-full flex flex-row  h-full max-h-[94vh]'>
      <div className='flex-1 pl-6 py-8 space-y-16 overflow-y-auto no-scrollbar'>
        <div className='flex flex-col scroll-mt-[100px]' id='#styling'>
          <Text size='sm' color='gray-100' className='ml-1 font-semibold mb-2'>
            Styling
          </Text>
          <Styling />
        </div>
        <div className='flex flex-col scroll-mt-[100px]' id='#general'>
          <Text size='sm' color='gray-100' className='ml-1 font-semibold mb-2'>
            General
          </Text>
          <General />
        </div>
        <div className='flex flex-col scroll-mt-[100px]' id='#offers'>
          <Text size='sm' color='gray-100' className='ml-1 font-semibold mb-2'>
            Offers
          </Text>
          <Offers />
        </div>
        <div className='flex flex-col scroll-mt-[100px]' id='#setup'>
          <Text size='sm' color='gray-100' className='ml-1 font-semibold mb-2'>
            Setup
          </Text>
          <Setup />
        </div>
        <div className='flex flex-col scroll-mt-[200px]' id='#sparkgpt'>
          <Text size='sm' color='gray-100' className='ml-1 font-semibold mb-2'>
            SparkGPT
          </Text>
          <SparkGPTPage />
        </div>
      </div>
      <div className='w-auto min-w-[376px] flex max-h-[94vh] items-center  overflow-hidden flex-shrink-0'>
        <WidgetUi />
      </div>
    </div>
  );
};
export default observer(Configurations);
