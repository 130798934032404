import React, { createRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button from '@/components/atoms/Button';
import { useUpload } from '@/hooks/useUpload';
import { dataURLtoFile } from '@/utils/imageManipulation';

export interface CropImageProps {
  closeModalSend: () => void;
  setCropImage: React.Dispatch<React.SetStateAction<string>>;
  selectedImage: string;
  uploadImage: (file: any) => void;
  type: string;
}

function CropImage({
  closeModalSend,
  setCropImage,
  selectedImage,
  uploadImage,
  type,
}: CropImageProps) {
  const cropperRef = createRef<ReactCropperElement>();
  const { uploading } = useUpload();
  const [zoomeState, setZoomState] = useState(0.5);

  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const file = dataURLtoFile(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
        'logo' + Math.random() + 1 + '.png',
      );
      await setCropImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
      );
      await uploadImage(file);
      await closeModalSend();
    }
  };

  return (
    <>
      <div className=''>
        <div className='flex justify-center'>
          <Cropper
            ref={cropperRef}
            style={{ height: 400, width: '100%' }}
            zoomTo={zoomeState}
            aspectRatio={type == 'pushIcon' ? 1 / 1 : 16 / 9}
            src={selectedImage}
            viewMode={1}
            minCropBoxHeight={260}
            minCropBoxWidth={260}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
          />
        </div>
        <div className='flex justify-center mt-3'>
          <div className='flex w-[80px] justify-between'>
            <div
              className='flex h-8 w-8 items-center justify-center bg-black rounded-[4px] text-white cursor-pointer'
              onClick={() => setZoomState(zoomeState + 0.1)}
            >
              +
            </div>
            <div
              className='flex h-8 w-8 items-center justify-center bg-black rounded-[4px] text-white cursor-pointer'
              onClick={() => setZoomState(zoomeState - 0.1)}
            >
              -
            </div>
          </div>
        </div>
        <div className='flex justify-center mt-7'>
          <h1>
            <Button
              disabled={uploading}
              type='button'
              size='sm'
              text={'Save'}
              className='bg-lightBlue'
              onClick={getCropData}
            />
          </h1>
        </div>
      </div>
    </>
  );
}

export default CropImage;
