export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr: any = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const resizeBase64Image = (
  base64: string,
  width: number,
  height: number,
): Promise<string> => {
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  const image = new Image();
  image.setAttribute('crossorigin', 'anonymous');
  image.src = base64;
  return new Promise<string>((resolve, reject) => {
    image.onload = () => {
      const aspectRatio = image.width / image.height;
      if (width / height > aspectRatio) {
        canvas.width = height * aspectRatio;
        canvas.height = height;
      } else {
        canvas.width = width;
        canvas.height = width / aspectRatio;
      }
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL());
      } else {
        reject(new Error('Failed to get canvas context.'));
      }
    };
    image.onerror = () => reject(new Error('Failed to load the image.'));
  });
};

export const toDataUrl = (url: string): Promise<string> => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.src = url;
  return new Promise(resolve => {
    img.onload = function () {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      ctx?.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL();
      resolve(dataURL);
    };
  });
};
