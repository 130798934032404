import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import generateBarcode from 'pdf417';
import { FieldDefault, LoyaltyProps } from '@/types/loyalty.types';
import { useLoyalty } from '@/hooks/useLoyalty';
import { useLocation } from 'react-router-dom';
import { MdNotifications } from 'react-icons/md';
import Text from '@/components/atoms/Text';
import { onMessage } from 'firebase/messaging';
import { deviceToken, messaging } from '@/libs/firebase';
import toast from 'react-hot-toast';
import { APP_BASE_URL } from '@/constants/index';
import { resizeBase64Image, toDataUrl } from '@/utils/imageManipulation';

interface RewardProps {
  name: string;
  pointEarn: string;
  type: string;
  value: string;
}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: ReadonlyArray<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

function LoyaltyPWA() {
  const { passDownloadForHome, enableNotification } = useLoyalty();
  const [show, setShow] = useState<boolean>(false);
  const [installPromptEvent, setInstallPromptEvent] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [cardDetail, setCardDetail] = useState<LoyaltyProps | null>(null);
  const { pathname, search } = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isEnable, setIsEnable] = useState<boolean>(true);
  const [notifyLoader, setNotifyLoader] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  const handleEnableNotifications = () => {
    // Add logic to enable notifications
    // console.log('Notifications enabled');
  };

  // Function to handle removing a card
  const handleRemoveCard = () => {
    // Add logic to remove the card
    // console.log('Card removed');
  };

  useEffect(() => {
    const getManifest = async () => {
      if (cardDetail) {
        const base64Img = await toDataUrl(
          cardDetail?.common.notificationLogo || '',
        );

        const myDynamicManifest = {
          short_name: 'React App',
          name: cardDetail?.dePassName,
          icons: [
            {
              src: await resizeBase64Image(base64Img, 48, 48),
              type: 'image/png',
              sizes: '48x48',
            },
            {
              src: await resizeBase64Image(base64Img, 72, 72),
              type: 'image/png',
              sizes: '72x72',
            },
            {
              src: await resizeBase64Image(base64Img, 96, 96),
              type: 'image/png',
              sizes: '96x96',
            },
            {
              src: await resizeBase64Image(base64Img, 144, 144),
              type: 'image/png',
              sizes: '144x144',
            },
            {
              src: await resizeBase64Image(base64Img, 168, 168),
              type: 'image/png',
              sizes: '168x168',
            },
            {
              src: await resizeBase64Image(base64Img, 180, 180),
              type: 'image/png',
              sizes: '180x180',
            },
            {
              src: await resizeBase64Image(base64Img, 192, 192),
              type: 'image/png',
              sizes: '192x192',
            },
            {
              src: await resizeBase64Image(base64Img, 256, 256),
              type: 'image/png',
              sizes: '256x256',
            },
            {
              src: await resizeBase64Image(base64Img, 512, 512),
              type: 'image/png',
              sizes: '512x512',
            },
          ],
          start_url: `${APP_BASE_URL}${pathname}${search}`,
          display: 'standalone',
          theme_color: '#000000',
          background_color: '#ffffff',
          scope: `${APP_BASE_URL}${pathname}${search}`,
        };

        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document
          .querySelector('#my-manifest-placeholder')
          ?.setAttribute('href', manifestURL);
      }
    };

    getManifest();
  }, [cardDetail]);

  async function getToken() {
    let fcmToken = '';

    fcmToken = await deviceToken();
    setToken(fcmToken);
    return fcmToken;
  }
  useEffect(() => {
    if (Notification.permission == 'granted') {
      getToken();
    }
  }, []);

  useEffect(() => {
    const getDetails = async () => {
      const trackPassIdParam: string[] = search.split('trackpassId=') || '';
      const fcmToken =
        Notification.permission == 'granted' ? await getToken() : '';
      if (trackPassIdParam[1] !== 'undefined') {
        passDownloadForHome(
          trackPassIdParam[1],
          {
            pushToken: fcmToken,
          },
          (res: any) => {
            if (res.data) {
              setCardDetail(res.data.dePassKitId);
              setIsEnable(res.pushRegistered);
            }
          },
        );
      }
    };
    getDetails();
  }, []);

  useEffect(() => {
    const trackPassIdParam: string[] = search.split('trackpassId=') || '';
    if (Notification.permission == 'default') {
      Notification.requestPermission().then(async permission => {
        if (permission === 'granted') {
          const fcmToken = await getToken();
          if (trackPassIdParam[1] !== 'undefined') {
            await enableNotification(trackPassIdParam[1], {
              FCMToken: fcmToken,
            });
          }
          setIsEnable(true);
        } else if (permission == 'denied' || permission == 'default') {
          setToken('');
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      const beforeInstallPromptEvent = event as BeforeInstallPromptEvent;
      beforeInstallPromptEvent.preventDefault();
      setInstallPromptEvent(beforeInstallPromptEvent);
    };

    window?.addEventListener(
      'beforeinstallprompt',
      handleBeforeInstallPrompt as EventListener,
    );

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  useEffect(() => {
    if (installPromptEvent) {
      installPromptEvent.prompt();
      installPromptEvent.userChoice.then(() => {
        //..
      });
    }
  }, [installPromptEvent]);

  const fieldObj = useMemo(() => {
    if (cardDetail) {
      const secondaryFields: FieldDefault[] =
        cardDetail?.applePayload.secondaryFields || [];

      const obj = {
        Reward:
          secondaryFields[0].label == 'Reward'
            ? secondaryFields[0].value
            : secondaryFields[1].value,
        Balance:
          secondaryFields[0].label == 'Balance'
            ? secondaryFields[0].value
            : secondaryFields[1].value,
      };
      return obj;
    }
  }, [cardDetail]);

  const notifyMe = async () => {
    const trackPassIdParam: string[] = search.split('trackpassId=') || '';

    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    } else if (Notification.permission === 'default') {
      // We need to ask the user for permission
      Notification.requestPermission().then(async data => {
        if (data === 'granted') {
          setNotifyLoader(true);
          const fcmToken = await getToken();
          await enableNotification(trackPassIdParam[1], {
            FCMToken: fcmToken,
          });
          setIsEnable(true);
          toast('pushed enable', {
            duration: 4000,
            position: 'top-center',
            style: {
              background: '#4ec52a',
              color: '#ffff',
            },
          });
          setNotifyLoader(false);
        } else if (data == 'default' || data == 'denied') {
          passDownloadForHome(
            trackPassIdParam[1],
            {
              pushToken: '',
            },
            (res: any) => {
              if (res.data) {
                setIsEnable(res.pushRegistered);
              }
            },
          );
        }
      });
    } else if (Notification.permission == 'denied') {
      toast('Please, allow notifications in your browser', {
        duration: 4000,
        position: 'top-center',
        style: {
          background: '#f1c40f',
          color: '#ffff',
        },
      });
    } else if (Notification.permission == 'granted') {
      enableNotification(trackPassIdParam[1], {
        FCMToken: token,
      });
      setIsEnable(true);
      toast('pushed enable', {
        duration: 4000,
        position: 'top-center',
        style: {
          background: '#4ec52a',
          color: '#ffff',
        },
      });
    }
  };

  onMessage(messaging, (payload: any) => {
    toast(payload?.notification?.title, {
      duration: 4000,
      position: 'top-center',
      style: {
        background: '#4ec52a',
        color: '#ffff',
      },
    });
  });

  return (
    <div
      className={`h-screen overflow-y-auto flex bg-hunterLab ${
        show ? '' : 'justify-center'
      }`}
    >
      <div className='w-full'>
        <div
          className={`w-[400px]  mx-auto  ${show ? 'mt-[22px]' : 'mt-[22px]'}`}
        >
          {notifyLoader && (
            <div className='flex space-x-2 justify-center items-center my-3 mt-3'>
              <div className='h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.3s]'></div>
              <div className='h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.15s]'></div>
              <div className='h-2 w-2 bg-white rounded-full animate-bounce'></div>
            </div>
          )}

          {!isEnable && !notifyLoader && (
            <div
              className='flex items-center justify-center cursor-pointer'
              onClick={notifyMe}
            >
              <MdNotifications className='w-5 h-5 mr-3' color='white' />
              <Text
                size='sm'
                color='text-white'
                className='text-center my-3 mt-3 text-[18px] leading-[10px] font-[600]'
              >
                Enable notifications
              </Text>
            </div>
          )}
        </div>
        <div
          className={`w-[400px] bg-white py-0 my-0 mx-auto rounded-[5px] overflow-hidden`}
        >
          <div className='w-[100%]'>
            {!show ? (
              <div
                className='py-5'
                style={{
                  backgroundColor: cardDetail?.applePayload.backgroundColor,
                }}
              >
                <div className='flex justify-between px-4'>
                  <img
                    alt='not found'
                    className='flex w-[70px] h-[60px] items-center justify-center rounded-[5px]'
                    src={cardDetail?.common.passLogo}
                  />

                  <div className='text-right'>
                    <p
                      className='text-[12px] leading-[20px] font-[400] text-gray-375'
                      style={{ color: cardDetail?.applePayload.labelColor }}
                    >
                      Balance
                    </p>
                    <p
                      className='text-[18px] leading-[20px] font-[500]'
                      style={{
                        color: cardDetail?.applePayload.foregroundColor,
                      }}
                    >
                      500
                    </p>
                  </div>
                </div>

                <div className='flex bg-lightWhite h-[100px] mx-2 items-center justify-center mt-[30px] rounded-[5px]'>
                  <img
                    alt='not found'
                    className='flex w-[250px] h-[100px] items-center justify-center rounded-[5px]'
                    src={cardDetail?.common.passBanner}
                  />
                </div>

                <div className='flex justify-between px-4 mt-[20px] '>
                  <div className='max-w-[45%] whitespace-nowrap'>
                    <p
                      className='text-[12px] leading-[20px] font-[400] text-gray-375 text-ellipsis overflow-hidden'
                      style={{ color: cardDetail?.applePayload.labelColor }}
                    >
                      Reward
                    </p>
                    <p
                      className='text-[18px] leading-[20px] font-[500] text-ellipsis overflow-hidden'
                      style={{
                        color: cardDetail?.applePayload.foregroundColor,
                      }}
                    >
                      {fieldObj?.Reward ? fieldObj.Reward : 'No Data'}
                    </p>
                  </div>
                  <div className='text-right max-w-[45%] whitespace-nowrap'>
                    <p
                      className='text-[12px] leading-[20px] font-[400] text-gray-375 text-ellipsis overflow-hidden'
                      style={{ color: cardDetail?.applePayload.labelColor }}
                    >
                      Balance
                    </p>
                    <p
                      className='text-[18px] leading-[20px] font-[500] text-ellipsis overflow-hidden'
                      style={{
                        color: cardDetail?.applePayload.foregroundColor,
                      }}
                    >
                      {fieldObj?.Balance ? fieldObj.Balance : 'No Data'}
                    </p>
                  </div>
                </div>
                <div className='flex items-center justify-center mt-[94px]'>
                  <img src={generateBarcode('Hiren Test', 2, 1)} />
                </div>
              </div>
            ) : (
              <div className='bg-mainGray overflow-auto py-5 relative'>
                <div className='flex justify-end mb-[10px] mr-[20px]'>
                  <button
                    type='button'
                    className='rounded-full w-8 h-8 border border-gray-300 flex items-center justify-center'
                    onClick={() => {
                      setIsPopupOpen(!isPopupOpen);
                    }}
                  >
                    <svg
                      className='Icon_icon__GZ8ae'
                      fill='currentColor'
                      viewBox='0 0 24 24'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M8 12.5V11.5C8 10.6716 7.32843 10 6.5 10H5.5C4.67157 10 4 10.6716 4 11.5V12.5C4 13.3284 4.67157 14 5.5 14H6.5C7.32843 14 8 13.3284 8 12.5ZM14 12.5V11.5C14 10.6716 13.3284 10 12.5 10H11.5C10.6716 10 10 10.6716 10 11.5V12.5C10 13.3284 10.6716 14 11.5 14H12.5C13.3284 14 14 13.3284 14 12.5ZM20 12.5V11.5C20 10.6716 19.3284 10 18.5 10H17.5C16.6716 10 16 10.6716 16 11.5V12.5C16 13.3284 16.6716 14 17.5 14H18.5C19.3284 14 20 13.3284 20 12.5ZM18.5 11C18.7761 11 19 11.2239 19 11.5V12.5C19 12.7761 18.7761 13 18.5 13H17.5C17.2239 13 17 12.7761 17 12.5V11.5C17 11.2239 17.2239 11 17.5 11H18.5ZM13 11.5C13 11.2239 12.7761 11 12.5 11H11.5C11.2239 11 11 11.2239 11 11.5V12.5C11 12.7761 11.2239 13 11.5 13H12.5C12.7761 13 13 12.7761 13 12.5V11.5ZM7 11.5C7 11.2239 6.77614 11 6.5 11H5.5C5.22386 11 5 11.2239 5 11.5V12.5C5 12.7761 5.22386 13 5.5 13H6.5C6.77614 13 7 12.7761 7 12.5V11.5Z'
                      ></path>
                    </svg>
                  </button>
                  {isPopupOpen && (
                    <div className='flex justify-end absolute right-6 top-14'>
                      <div className='overflow-hidden bg-dropdown-background rounded-[0.80rem] shadow  bg-black text-white flex flex-col items-end relative h-[90px] w-[220px] justify-evenly'>
                        <button
                          type='button'
                          className='flex mr-[5px] gap-1'
                          onClick={handleEnableNotifications}
                        >
                          <div className='text-[14px]'>
                            Enable notifications
                          </div>
                          <div className='w-6 h-6'>
                            <svg
                              className='Icon_icon__GZ8ae'
                              fill='currentColor'
                              viewBox='0 0 24 24'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M12.0006 21C13.2872 21 14.4122 20.1832 14.8298 19H16.4231C16.7729 19 17.1188 18.9266 17.4384 18.7845C18.7001 18.2238 19.2684 16.7464 18.7076 15.4847L18.0006 13.8939V11C18.0006 8.38756 16.331 6.16508 14.0006 5.34141V5C14.0006 3.89543 13.1052 3 12.0006 3C10.896 3 10.0006 3.89543 10.0006 5V5.34141C7.67022 6.16508 6.0006 8.38756 6.0006 11V13.8939L5.2936 15.4847C5.15153 15.8043 5.07812 16.1502 5.07812 16.5C5.07812 17.8807 6.19741 19 7.57812 19H9.17133C9.58772 20.1802 10.7112 21 12.0006 21ZM10.2677 19H13.7335C13.3842 19.6045 12.7314 20 12.0006 20C11.2685 20 10.6164 19.6033 10.2677 19ZM13.0006 5.08296V5C13.0006 4.44772 12.5529 4 12.0006 4C11.4483 4 11.0006 4.44772 11.0006 5V5.08296C11.3258 5.0284 11.6599 5 12.0006 5C12.3413 5 12.6754 5.0284 13.0006 5.08296ZM7.0006 11C7.0006 8.23858 9.23918 6 12.0006 6C14.762 6 17.0006 8.23858 17.0006 11V14L17.0437 14.2031L17.7938 15.8908C18.1303 16.6478 17.7893 17.5343 17.0323 17.8707C16.8405 17.956 16.633 18 16.4231 18H7.57812C6.7497 18 6.07812 17.3284 6.07812 16.5C6.07812 16.2901 6.12217 16.0826 6.20741 15.8908L6.95751 14.2031L7.0006 14V11Z'
                              ></path>
                            </svg>
                          </div>
                        </button>
                        <hr className='h-[0.5px] bg-gray-700 w-full border-0 bg-border'></hr>
                        <button
                          type='button'
                          className='flex mr-[5px] gap-1 text-darkRed'
                          onClick={handleRemoveCard}
                        >
                          <div className='text-[14px]'>Remove card</div>
                          <div className='w-6 h-6'>
                            <svg
                              className='Icon_icon__GZ8ae'
                              fill='currentColor'
                              viewBox='0 0 24 24'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M12.5 23C6.70101 23 2 18.299 2 12.5C2 6.70101 6.70101 2 12.5 2C18.299 2 23 6.70101 23 12.5C23 18.299 18.299 23 12.5 23ZM12.5 22C17.7467 22 22 17.7467 22 12.5C22 7.25329 17.7467 3 12.5 3C7.25329 3 3 7.25329 3 12.5C3 17.7467 7.25329 22 12.5 22ZM8 12.5C8 12.7761 8.22386 13 8.5 13H16.5C16.7761 13 17 12.7761 17 12.5C17 12.2239 16.7761 12 16.5 12H8.5C8.22386 12 8 12.2239 8 12.5Z'
                              ></path>
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className='bg-white mx-auto w-[250px] shadow-md'
                  style={{
                    backgroundColor: cardDetail?.applePayload.backgroundColor,
                  }}
                >
                  <div className='flex w-[240px] justify-between m-auto pt-1'>
                    <img
                      alt='not found'
                      className='flex w-[20px] h-[20px] items-center justify-center rounded-[20px]'
                      src={cardDetail?.common.passLogo}
                    />

                    <div className='text-right'>
                      <p
                        className='text-[6px] leading-[10px] font-[400] text-gray-375'
                        style={{ color: cardDetail?.applePayload.labelColor }}
                      >
                        Balance
                      </p>
                      <p
                        className='text-[9px] leading-[10px] font-[500]'
                        style={{
                          color: cardDetail?.applePayload.foregroundColor,
                        }}
                      >
                        500
                      </p>
                    </div>
                  </div>

                  <div className='flex my-1 items-center justify-center rounded-[5px]'>
                    <img
                      alt='not found'
                      className='flex w-[250px] h-[45px] items-center justify-center rounded-[3px]'
                      src={cardDetail?.common.passBanner}
                    />
                  </div>

                  <div className='flex w-[240px] justify-between m-auto'>
                    <div className='max-w-[45%] whitespace-nowrap'>
                      <p
                        className='text-[6px] leading-[10px] font-[400] text-gray-375 text-ellipsis overflow-hidden'
                        style={{ color: cardDetail?.applePayload.labelColor }}
                      >
                        Reward
                      </p>
                      <p
                        className='text-[9px] leading-[10px] font-[500] text-ellipsis overflow-hidden'
                        style={{
                          color: cardDetail?.applePayload.foregroundColor,
                        }}
                      >
                        {fieldObj?.Reward ? fieldObj.Reward : 'No Data'}
                      </p>
                    </div>
                    <div className='text-right max-w-[45%] whitespace-nowrap'>
                      <p
                        className='text-[6px] leading-[10px] font-[400] text-gray-375 text-ellipsis overflow-hidden'
                        style={{ color: cardDetail?.applePayload.labelColor }}
                      >
                        Balance
                      </p>
                      <p
                        className='text-[9px] leading-[10px] font-[500] text-ellipsis overflow-hidden'
                        style={{
                          color: cardDetail?.applePayload.foregroundColor,
                        }}
                      >
                        {fieldObj?.Balance ? fieldObj.Balance : 'No Data'}
                      </p>
                    </div>
                  </div>
                </div>
                <hr className='w-[100px] mx-auto bg-red h-[1px] border-0 bg-red ' />

                <h2 className='text-center font-[600] mt-2'>
                  {cardDetail?.dePassName}
                </h2>
              </div>
            )}

            <>
              <div
                className={`transition-[max-height] overflow-hidden	 ${
                  show
                    ? 'py-8 px-4 max-h-[950px] duration-300 ease-in'
                    : 'max-h-0 duration-200 ease-out '
                }`}
              >
                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Program Name
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[0].value}
                </p>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Points Earned
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[1].value.earnedType &&
                  cardDetail?.backFields[1].value.pointToGet
                    ? cardDetail?.backFields[1].value.earnedType +
                      ' (' +
                      cardDetail?.backFields[1].value.pointToGet +
                      ')'
                    : '-'}
                </p>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Rewards Offered
                </p>

                {cardDetail?.backFields[2].value.map(
                  (reward: RewardProps, index: number) => {
                    return (
                      <div key={index}>
                        <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                          {reward.name}
                        </p>

                        {reward.pointEarn && reward.type && reward.value && (
                          <div className='flex justify-between'>
                            <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words w-full'>
                              {reward.pointEarn}
                            </p>
                            <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words w-full text-center'>
                              {reward.type == 'off'
                                ? 'Order ($OFF)'
                                : 'Order ($ON)'}
                            </p>
                            <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words w-full text-end'>
                              {reward.value}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  },
                )}

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Number of points when issuing a card
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[3].value}
                </p>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Referral Program
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[4].value == 0 ? 'Active' : 'Inactive'}
                </p>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Number of points for the referrer
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[5].value}
                </p>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Number of points for the referral
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[6].value}
                </p>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Active links
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[7].value.link}
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[7].value.text}
                </p>

                <>
                  <p className='text-[14px] font-[300] leading-[20px] text-black mt-4'>
                    Terms of use
                  </p>

                  <div>
                    <p
                      className='text-[10px] leading-[20px] text-gray-400 text-opacity-80 break-words'
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {cardDetail?.backFields[8].value.termsData
                        ? cardDetail?.backFields[8].value.termsData
                        : ''}
                    </p>
                  </div>
                </>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Company name
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[9].value.companyName}
                </p>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Company email
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[9].value.companyEmail}
                </p>

                <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                  Company phone number
                </p>

                <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                  {cardDetail?.backFields[9].value.companyNo}
                </p>
              </div>
            </>
          </div>
        </div>

        <div className='py-3 w-[400px] mx-auto'>
          <button
            type='button'
            className='w-full justify-center rounded-md bg-hunterLabLight px-3 py-4 text-md font-semibold text-white shadow-sm ring-inset sm:mt-0'
            onClick={() => setShow(!show)}
          >
            {!show ? 'Details' : 'Back'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoyaltyPWA;
