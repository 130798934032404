import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import { CreatePayload } from '@/hooks/useContact';
import { contactStore } from '@/state/ContactStore';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import * as yup from 'yup';

const EMAIL_REGX =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

const newContactScheme = yup.object().shape({
  user_name: yup.string().trim().required('Name is required'),
  email: yup
    .string()
    .required('Email is required')
    .matches(EMAIL_REGX, 'Invalid email'),
});

type NewContactFormProps = {
  handleCreateContact: (data: CreatePayload) => void;
  closeModal: () => void;
};

export const NewContactForm: FC<NewContactFormProps> = observer(
  ({ handleCreateContact, closeModal }) => {
    return (
      <Formik
        initialValues={{
          user_name: '',
          email: '',
          contact_type: 'User',
        }}
        validationSchema={newContactScheme}
        onSubmit={handleCreateContact}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          handleSubmit,
        }) => (
          <form className='px-4' onSubmit={handleSubmit}>
            <Input
              placeholder='eg. Lusa M. Smith'
              label='Name'
              type='text'
              name='user_name'
              onChange={handleChange('user_name')}
              value={values.user_name}
              error={
                errors.user_name && touched.user_name ? errors.user_name : ''
              }
            />
            <div className='mt-2'>
              <Input
                placeholder='name@example.com'
                label='Email'
                type='email'
                name='email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email && touched.email ? errors.email : ''}
              />
            </div>
            <div className='mt-5 text-center flex justify-between space-x-4'>
              <div className='flex flex-1'>
                <Button
                  size='sm'
                  text='Cancel'
                  onClick={closeModal}
                  variant='outline'
                  className='w-full'
                  type='button'
                />
              </div>

              <div className='flex flex-1'>
                <Button
                  size='sm'
                  text='Create a contact'
                  type='submit'
                  className='hover:bg-primary-medium w-full'
                  disabled={contactStore.creatingContact}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  },
);
