import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import { loyaltyStore } from '@/state/LoyaltyStore';

interface TermUseProps {
  termsData: string;
  termsLink: string;
  legalAge: number;
}

const TermsOfUse: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const [term, setTerm] = useState<TermUseProps>({
    termsData: '',
    termsLink: '',
    legalAge: 0,
  });
  const [termDataError, setTermDataError] = useState<string>('');

  useEffect(() => {
    setTerm(loyaltyStore.termsObj);
  }, []);

  const onSubmit = () => {
    if (term.termsData.trim() == '') {
      setTermDataError('Term is required');
    } else {
      setTermDataError('');
      loyaltyStore.setTermsObj(term);
      handleCancel();
    }
  };

  const onClose = () => {
    setTerm(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[8].value
        : {
            termsData: '',
            termsLink: '',
            legalAge: 0,
          },
    );
    loyaltyStore.setTermsObj(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[8].value
        : {
            termsData: '',
            termsLink: '',
            legalAge: 0,
          },
    );
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='w-full mb-[24px]'>
        <div className='flex flex-col  flex-1'>
          <textarea
            id='textarea'
            value={term.termsData}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setTerm({ ...term, termsData: event.target.value });
              loyaltyStore.setShowTermDataError(false);
            }}
            onKeyPress={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
              setTerm({
                ...term,
                termsData: (event.target as HTMLTextAreaElement).value,
              });
              loyaltyStore.setShowTermDataError(false);
            }}
            className='outline-none  z-[2]  text-[12px] resize-vertical resize-none rounded-[6px] 
                                    border border-border leading-[20px] py-[6px] px-[12px]'
            rows={6}
            placeholder='Terms'
            maxLength={1000}
          />
        </div>

        {termDataError && (
          <p className='mb-[6px] mt-1' style={{ color: 'red' }}>
            {termDataError}
          </p>
        )}
      </div>

      <div className='w-full mb-[24px]'>
        <p>Link to full terms and conditions (optional)</p>

        <div className='flex flex-col mt-3 flex-1'>
          <Input
            value={term.termsLink}
            placeholder='Enter the url address of the link to the rules of using the service on your site'
            type='text'
            onChange={e => {
              setTerm({ ...term, termsLink: e.target.value });
            }}
          />
        </div>
      </div>

      <div className='w-full mb-[24px]'>
        <p>Legal Cannabis Age</p>

        <div className='flex flex-col mt-3 flex-1'>
          <Input
            value={term.legalAge}
            placeholder='age'
            type='text'
            onChange={e => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setTerm({ ...term, legalAge: Number(e.target.value) });
              }
            }}
          />
        </div>
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
export default observer(TermsOfUse);
