import { fetchRequestWithToken } from '@/config/axios';
import { notify } from '@/helpers/index';
import { AuthStore } from '@/state/AuthenticationStore';
import { integrationStore } from '@/state/IntegrationStore';
import { Integration } from '@/types/integration.types';
import { Workspace } from '@/types/workspace.type';
import { isAxiosError } from 'axios';
import { useState } from 'react';

export const useIntegration = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVonageModalOpen, setIsVonageModalOpen] = useState(false);
  const [isTelnyxModalOpen, setIsTelnyxModalOpen] = useState(false);
  const [showWebhookUrlModal, setShowWebhookUrlModal] = useState(false);

  const getIntegrations = async () => {
    try {
      integrationStore.setLoading(true);
      const integrations: { data: Integration[] } = await fetchRequestWithToken(
        {
          url: `/api/integrations`,
        },
      );
      integrationStore.setIntegrations(integrations.data);
    } catch (error) {
      notify('error', 'Error while fetching integration');
    } finally {
      integrationStore.setLoading(false);
    }
  };

  const addIntegration = async (data: any) => {
    try {
      integrationStore.setCreating(true);
      const activeWorkspace: { data: Workspace } = await fetchRequestWithToken({
        url: `/api/integrations`,
        method: 'POST',
        data,
      });

      AuthStore.set_user_workspace_info({
        ...AuthStore.user_workspace_info,
        active_workspace: {
          workspace: activeWorkspace.data,
          permission: AuthStore.user_workspace_info.active_workspace.permission,
        },
      });

      integrationStore.setIntegrations(
        integrationStore.integrations.map(integration => {
          if (integration._id !== data.integrationId) {
            return {
              ...integration,
              isDisabled: true,
            };
          }
          return integration;
        }),
      );
      notify('success', 'Integration added successfully');
      closeModal();
      if (data.integrationType === 'Vonage') {
        setShowWebhookUrlModal(true);
      }
      setIsTelnyxModalOpen(false);
    } catch (error) {
      if (isAxiosError(error)) {
        notify(
          'error',
          `Error while adding integration: ${error.response?.data.message}`,
        );
      }
    } finally {
      integrationStore.setCreating(false);
    }
  };

  const updateIntegration = (id: string, data: string) => {
    fetchRequestWithToken({
      url: `/api/integrations/${id}`,
      method: 'PUT',
      data,
    });
  };

  const disconnectIntegration = async (integrationId: string) => {
    try {
      integrationStore.setCreating(true);
      const activeWorkspace: { data: Workspace } = await fetchRequestWithToken({
        url: `/api/integrations/${integrationId}`,
        method: 'DELETE',
      });

      AuthStore.set_user_workspace_info({
        ...AuthStore.user_workspace_info,
        active_workspace: {
          workspace: activeWorkspace.data,
          permission: AuthStore.user_workspace_info.active_workspace.permission,
        },
      });

      integrationStore.setIntegrations(
        integrationStore.integrations.map(integration => {
          return {
            ...integration,
            isDisabled: false,
          };
        }),
      );
      notify('success', 'Integration disconnected successfully');
    } catch (error) {
      if (isAxiosError(error)) {
        notify(
          'error',
          `Error while disconnecting integration: ${error.response?.data.message}`,
        );
      }
    } finally {
      integrationStore.setCreating(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsVonageModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  return {
    isModalOpen,
    showWebhookUrlModal,
    isVonageModalOpen,
    isTelnyxModalOpen,
    closeModal,
    openModal,
    setIsVonageModalOpen,
    setShowWebhookUrlModal,
    setIsTelnyxModalOpen,
    setIsModalOpen,
    addIntegration,
    getIntegrations,
    updateIntegration,
    disconnectIntegration,
  };
};
