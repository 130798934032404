import Device from '../../../../assets/images/iPhone14.png';
import '@grapecity/wijmo.styles/wijmo.css';
import { loyaltyStore } from '@/state/LoyaltyStore';
import { observer } from 'mobx-react-lite';

function SettingDevices() {
  const myStyle = {
    backgroundImage: `url(${Device})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <div>
        <div
          style={myStyle}
          className='flex w-[320.48px] h-[630px] items-end justify-center '
        >
          <div className='rounded-[33px]  bg-white w-[86%] h-[84%] mb-[16px] overflow-auto  overflow-y-auto no-scrollbar relative'>
            <div
              className='w-[276px] p-4  bg-white sticky top-0  border-b border-gray-100'
              style={{
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}
            >
              <p className='text-[16px] font-[400] leading-[20px] text-black '>
                DETAILS
              </p>
            </div>

            <div className='pb-8 px-4'>
              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Program Name
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.programName ? loyaltyStore.programName : '-'}
              </p>

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Points Earned
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.pointEarnsObj.earnedType &&
                loyaltyStore.pointEarnsObj.pointToGet
                  ? loyaltyStore.pointEarnsObj.earnedType +
                    ' (' +
                    loyaltyStore.pointEarnsObj.pointToGet +
                    ')'
                  : '-'}
              </p>

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Rewards Offered
              </p>

              {loyaltyStore.rewardOfferedObj.map((reward, index) => {
                return (
                  <div key={index}>
                    <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                      {reward.name}
                    </p>

                    {reward.pointEarn && reward.type && reward.value && (
                      <div className='flex justify-between'>
                        <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words w-full'>
                          {reward.pointEarn}
                        </p>
                        <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words w-full text-center'>
                          {reward.type == 'off'
                            ? 'Order ($OFF)'
                            : 'Order ($ON)'}
                        </p>
                        <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80 break-words w-full text-end'>
                          {reward.value}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Number of points when issuing a card
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.numberIssueCard}
              </p>

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Referral Program
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.referralProgramType == 0 ? 'Active' : 'Inactive'}
              </p>

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Number of points for the referrer
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.numberForReferrer}
              </p>

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Number of points for the referral
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.numberForReferral}
              </p>

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Active links
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.activeLinkObj.link}
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.activeLinkObj.text}
              </p>

              {loyaltyStore.showTermUse && (
                <>
                  <p className='text-[14px] font-[300] leading-[20px] text-black mt-4'>
                    Terms of use
                  </p>

                  <div>
                    <p
                      className='text-[10px] leading-[20px] text-gray-400 text-opacity-80 break-words'
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {loyaltyStore.termsObj.termsData
                        ? loyaltyStore.termsObj.termsData
                        : ''}
                    </p>
                  </div>
                </>
              )}

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Company name
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.contactObj.companyName}
              </p>

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Company email
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.contactObj.companyEmail}
              </p>

              <p className='text-[12px] font-[400] leading-[20px] text-black mt-4'>
                Company phone number
              </p>

              <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 break-words'>
                {loyaltyStore.contactObj.companyNo}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(SettingDevices);
