import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import { SelectInput } from '@/components/atoms/SelectInput';
import { BASE_URL } from '@/constants/index';
import { notify } from '@/helpers/index';
import { VonagePhoneNumberResponse } from '@/types/sms.types';
import axios from 'axios';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import * as yup from 'yup';

type VonageFormProps = {
  handleVonage: (values: VonageFormPayload) => void;
};

export const VonageFormSchema = yup.object().shape({
  apiKey: yup.string().trim().required('API key is required'),
  apiSecret: yup.string().trim().required('Secret Key is required'),
  phoneNumber: yup.string().trim().required('Phone Number is required'),
});

export type VonageFormPayload = yup.InferType<typeof VonageFormSchema>;

export const VonageForm: FC<VonageFormProps> = ({ handleVonage }) => {
  const [phoneNumberDataList, setPhoneNumberDataList] = useState<
    Partial<VonagePhoneNumberResponse>[]
  >([]);
  const [isLoadingPhoneNumberDataList, setIsLoadingPhoneNumberDataList] =
    useState(false);

  const getPhoneNumberDataList = async (apiKey: string, apiSecret: string) => {
    setIsLoadingPhoneNumberDataList(true);
    const vonageURL = `${BASE_URL}/api/sms/vonage-numbers`;

    await axios
      .get<VonagePhoneNumberResponse[]>(vonageURL, {
        params: {
          apiKey,
          apiSecret,
        },
      })
      .then(response => {
        const structuredPhoneNumberDataList = response?.data;

        if (structuredPhoneNumberDataList.length === 0) {
          notify('error', 'No valid phone number found');
        } else {
          setPhoneNumberDataList(structuredPhoneNumberDataList);
        }
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      });

    setIsLoadingPhoneNumberDataList(false);
  };

  return (
    <div>
      <div className='flex flex-col px-4'>
        <div className='py-2 text-gray-500 2xl:text-[15px] xl:text-[12px]'>
          {`Create the exact solution you need to engage customers at every step of their journey.`}
        </div>
      </div>
      <Formik
        onSubmit={handleVonage}
        initialValues={{
          apiKey: '',
          apiSecret: '',
          phoneNumber: '',
        }}
        validationSchema={VonageFormSchema}
      >
        {({ errors, values, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} className='mt-1 p-4 space-y-4'>
            <Input
              onChange={handleChange('apiKey')}
              onBlur={e => {
                if (
                  phoneNumberDataList.length === 0 &&
                  values.apiKey &&
                  values.apiSecret
                ) {
                  getPhoneNumberDataList(values.apiKey, values.apiSecret);
                }
                handleBlur('apiKey')(e);
              }}
              value={values.apiKey}
              type='text'
              name='apiKey'
              placeholder='API Key'
            />
            {errors.apiKey && (
              <small className='text-red-600'>{errors.apiKey}</small>
            )}
            <Input
              onChange={handleChange('apiSecret')}
              onBlur={e => {
                if (
                  phoneNumberDataList.length === 0 &&
                  values.apiKey &&
                  values.apiSecret
                ) {
                  getPhoneNumberDataList(values.apiKey, values.apiSecret);
                }
                handleBlur('apiSecret')(e);
              }}
              value={values.apiSecret}
              type='text'
              name='apiSecret'
              placeholder='Secret Key'
            />
            {errors.apiSecret && (
              <small className='text-red-600'>{errors.apiSecret}</small>
            )}
            <SelectInput
              onChange={handleChange('phoneNumber')}
              onBlur={handleBlur('phoneNumber')}
              value={values.phoneNumber}
              name='phoneNumber'
              placeholder='Phone Number'
              error={errors.phoneNumber}
              disabled={phoneNumberDataList.length === 0}
              loading={isLoadingPhoneNumberDataList}
              options={phoneNumberDataList?.map(phoneNumberData => {
                return {
                  value: phoneNumberData.phoneNumber as string,
                  label: phoneNumberData.phoneNumber as string,
                };
              })}
              errorMessage={errors.phoneNumber}
            />
            <div className='border border-b-0'></div>
            <div className='flex flex-row-reverse gap-4'>
              <Button
                disabled={
                  !values.apiKey || !values.apiSecret || !values.phoneNumber
                }
                type='submit'
                text='Save'
                variant='primary'
                className='w-full'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
