import Button from '@/components/atoms/Button';
import { ChatSuggestionIcon } from '@/components/atoms/Icons/ChatSuggestionIcon';
import { GreetingIcon } from '@/components/atoms/Icons/GreetingIcon';
import StylesIcon from '@/components/atoms/Icons/StylesIcon';
import Accordion from '@/components/molecules/Accordion';
import { convertToRGB } from '@/helpers/index';
import { useLoyalty } from '@/hooks/useLoyalty';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { loyaltyStore } from '@/state/LoyaltyStore';
import { FieldDefault, LoyaltyProps } from '@/types/loyalty.types';
import { replaceRouteParam } from '@/utils/routes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import SendPush from '../SendPush';
import NotificationDevices from '../SendPush/NotificationDevices';
import Setting from '../Setting';
import SettingDevices from '../Setting/SettingDevices';
import BackgroundImage from './Cards/BackgroundImage';
import BannerLogo from './Cards/BannerLogo';
import CardDescr from './Cards/CardDescr';
import CardIssue from './Cards/CardIssue';
import CardName from './Cards/CardName';
import ColorOnCard from './Cards/ColorOnCard';
import FieldOnCard from './Cards/FieldOnCard';
import PushIcon from './Cards/PushIcon';
import DesignDevices from './DesignDevices';

const Design = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [cardId, setCardId] = useState<string | undefined>('');
  const navigate = useNavigate();
  const { updateLoyalty, createLoyalty, getLoyalty } = useLoyalty();

  const accord1 = [
    {
      Title: 'Logo',
      Phrase: `Recommended size: 660x660 pixels. PNG format. Max size: 150KB`,
      selected: false,
      Content: BannerLogo,
      Icon: StylesIcon,
      section: 0,
    },
    {
      Title: 'Push Icon',
      Phrase:
        'Upload Recommended size: 58x58 pixels. PNG format. Max size: 50KB',
      selected: false,
      Content: PushIcon,
      Icon: GreetingIcon,
      section: 1,
    },
    {
      Title: 'Background Image',
      Phrase: 'Recommended size: 624x246 pixels. PNG format. Max size: 450KB',
      selected: false,
      Content: BackgroundImage,
      Icon: ChatSuggestionIcon,
      section: 2,
    },
  ];

  const accord2 = [
    {
      Title: 'Colors on card',
      Phrase: 'Card background & text colors',
      selected: false,
      Content: ColorOnCard,
      Icon: ChatSuggestionIcon,
      section: 3,
    },
  ];

  const accord3 = [
    {
      Title: 'Fields on card',
      Phrase: '',
      selected: false,
      Content: FieldOnCard,
      Icon: ChatSuggestionIcon,
      section: 4,
    },
  ];

  const accord4 = [
    {
      Title: 'Card issuing form',
      Phrase: 'Details to collect about your customers',
      selected: false,
      Content: CardIssue,
      Icon: ChatSuggestionIcon,
      section: 5,
    },
  ];

  const accord5 = [
    {
      Title: 'Card Title',
      Phrase: '',
      selected: false,
      Content: CardName,
      Icon: ChatSuggestionIcon,
      section: 6,
    },
    {
      Title: 'Card Description',
      Phrase: '',
      selected: false,
      Content: CardDescr,
      Icon: ChatSuggestionIcon,
      section: 7,
    },
  ];

  const getMode = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  };

  useEffect(() => {
    if (getMode() == 'update') {
      const urlParams = new URLSearchParams(window.location.search);
      const idParam: string = urlParams.get('id') || '';
      getLoyalty(idParam, () => {
        //..
      });
    }
  }, [cardId]);

  useEffect(() => {
    if (loyaltyStore.loyalty && getMode() == 'update') {
      loyaltyStore.setCardBGcolor(
        loyaltyStore.loyalty?.googlePayload.hexBackgroundColor || '',
      );
      loyaltyStore.setTextLabelColor(
        loyaltyStore.loyalty?.applePayload.labelColor || '',
      );
      loyaltyStore.setTextValueColor(
        loyaltyStore.loyalty?.applePayload.foregroundColor || '',
      );

      const secondaryFields: FieldDefault[] =
        loyaltyStore.loyalty?.applePayload.secondaryFields || [];

      const obj = {
        Reward:
          secondaryFields[0].label == 'Reward'
            ? secondaryFields[0].value
            : secondaryFields[1].value,
        Balance:
          secondaryFields[0].label == 'Balance'
            ? secondaryFields[0].value
            : secondaryFields[1].value,
      };
      loyaltyStore.setFieldObj(obj);
      loyaltyStore.setCardIssueObj(loyaltyStore.loyalty?.metaData || []);
      loyaltyStore.setCardName(loyaltyStore.loyalty.dePassName);
      loyaltyStore.setCardDescr(loyaltyStore.loyalty.dePassDesc);
      loyaltyStore.setProgramName(loyaltyStore.loyalty?.backFields[0].value);
      loyaltyStore.setPointsEarnsObj(loyaltyStore.loyalty?.backFields[1].value);
      loyaltyStore.setRewardOfferedObj(
        loyaltyStore.loyalty?.backFields[2].value,
      );
      loyaltyStore.setNumberIssueCard(
        loyaltyStore.loyalty?.backFields[3].value,
      );
      loyaltyStore.setReferralProgramType(
        loyaltyStore.loyalty?.backFields[4].value,
      );
      loyaltyStore.setNumberForReferrer(
        loyaltyStore.loyalty?.backFields[5].value,
      );
      loyaltyStore.setNumberForReferral(
        loyaltyStore.loyalty?.backFields[6].value,
      );
      loyaltyStore.setActiveLinkObj(loyaltyStore.loyalty?.backFields[7].value);
      loyaltyStore.setTermsObj(loyaltyStore.loyalty?.backFields[8].value);
      loyaltyStore.setContactObj(loyaltyStore.loyalty?.backFields[9].value);
      loyaltyStore.setBgImage(loyaltyStore.loyalty?.common.passBanner);
      loyaltyStore.setBannerLogo(loyaltyStore.loyalty?.common.passLogo);
      loyaltyStore.setNotificationLogo(
        loyaltyStore.loyalty?.common.notificationLogo,
      );
    } else {
      loyaltyStore.setCardBGcolor('#FFFFFF');
      loyaltyStore.setTextLabelColor('#7C7C7C');
      loyaltyStore.setTextValueColor('#000000');
      loyaltyStore.setFieldObj({});
      loyaltyStore.setCardIssueObj([
        {
          title: '',
          type: '',
          isRequired: false,
          isUnique: false,
        },
        {
          title: '',
          type: '',
          isRequired: false,
          isUnique: false,
        },
        {
          title: '',
          type: '',
          isRequired: false,
          isUnique: false,
        },
        {
          title: '',
          type: '',
          isRequired: false,
          isUnique: false,
        },
        {
          title: '',
          type: '',
          isRequired: false,
          isUnique: false,
        },
      ]);
      loyaltyStore.setCardName('');
      loyaltyStore.setCardDescr('');
      loyaltyStore.setProgramName('');
      loyaltyStore.setPointsEarnsObj({
        earnedType: 'Spend',
        earnPoints: 0,
        pointToGet: 0,
      });
      loyaltyStore.setRewardOfferedObj([
        {
          name: '',
          pointEarn: '',
          type: 'off',
          value: '25.00',
        },
        {
          name: '',
          pointEarn: '',
          type: 'off',
          value: '25.00',
        },
        {
          name: '',
          pointEarn: '',
          type: 'off',
          value: '25.00',
        },
        {
          name: '',
          pointEarn: '',
          type: 'off',
          value: '25.00',
        },
        {
          name: '',
          pointEarn: '',
          type: 'off',
          value: '25.00',
        },
      ]);
      loyaltyStore.setNumberIssueCard(0);
      loyaltyStore.setReferralProgramType(0);
      loyaltyStore.setNumberForReferrer(0);
      loyaltyStore.setNumberForReferral(0);
      loyaltyStore.setActiveLinkObj({
        type: '',
        link: '',
        text: '',
      });
      loyaltyStore.setTermsObj({
        termsData: '',
        termsLink: '',
        legalAge: 0,
      });
      loyaltyStore.setContactObj({
        companyName: '',
        companyEmail: '',
        companyNo: '',
      });
      loyaltyStore.setBgImage('');
      loyaltyStore.setBannerLogo('');
      loyaltyStore.setNotificationLogo('');
    }
    return () => {
      loyaltyStore.setShowLogosError(false);
      loyaltyStore.setShowCardTitleError(false);
      loyaltyStore.setShowCardDescripError(false);
      loyaltyStore.setShowCardIssueError(false);
      loyaltyStore.setShowProgramNameError(false);
      loyaltyStore.setShowTermDataError(false);
      loyaltyStore.setShowCompanyNameError(false);
      loyaltyStore.setShowCompanyEmailError(false);
      loyaltyStore.setShowCompanyNoError(false);
    };
  }, [loyaltyStore.loyalty]);

  const onSubmit = async () => {
    if (!loyaltyStore.cardName) {
      loyaltyStore.setShowCardTitleError(true);
      setSelectedIndex(6);
      document
        .getElementById('cardDetail')
        ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      setTab(0);
      return;
    }
    if (!loyaltyStore.cardDescr) {
      loyaltyStore.setShowCardDescripError(true);
      setSelectedIndex(7);
      setTab(0);
      document
        .getElementById('cardDetail')
        ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      return;
    }
    if (
      !loyaltyStore.bannerLogo ||
      !loyaltyStore.notificationLogo ||
      !loyaltyStore.bgImage
    ) {
      loyaltyStore.setShowLogosError(true);
      if (!loyaltyStore.bannerLogo) {
        setSelectedIndex(0);
      } else if (!loyaltyStore.notificationLogo) {
        setSelectedIndex(1);
      } else if (!loyaltyStore.bgImage) {
        setSelectedIndex(2);
      }
      setTab(0);
      document
        .getElementById('logoId')
        ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      return;
    }

    if (loyaltyStore.cardIssueObj.every((e: any) => e.type === '')) {
      loyaltyStore.setShowCardIssueError(true);
      setSelectedIndex(5);
      setTab(0);
      document
        .getElementById('cardIssue')
        ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      return;
    }

    if (!loyaltyStore.programName) {
      setTab(1);
      loyaltyStore.setShowProgramNameError(true);
      return;
    }

    if (!loyaltyStore.termsObj.termsData) {
      setTab(1);
      loyaltyStore.setShowTermDataError(true);
      return;
    }

    if (
      !loyaltyStore.contactObj.companyName ||
      !loyaltyStore.contactObj.companyEmail ||
      !loyaltyStore.contactObj.companyNo
    ) {
      setTab(1);
      if (!loyaltyStore.contactObj.companyName) {
        loyaltyStore.setShowCompanyNameError(true);
      }
      if (!loyaltyStore.contactObj.companyEmail) {
        loyaltyStore.setShowCompanyEmailError(true);
      }
      if (!loyaltyStore.contactObj.companyNo) {
        loyaltyStore.setShowCompanyNoError(true);
      }
      return;
    }
    setLoading(true);
    if (getMode() == 'create') {
      createLoyalty(
        {
          userId: AuthStore.currentUser?._id,
          nfcEnabled: false,
          nfcMessage: [],
          backFields: [
            {
              id: '1',
              key: uuid(),
              label: 'Program Name',
              value: loyaltyStore.programName,
              textAlignment: '',
              isEditable: true,
              type: 'string',
            },
            {
              id: '2',
              key: uuid(),
              label: 'Points Earned',
              value: loyaltyStore.pointEarnsObj,
              textAlignment: '',
              isEditable: true,
              type: 'object',
            },
            {
              id: '3',
              key: uuid(),
              label: 'Rewards Offered',
              value: loyaltyStore.rewardOfferedObj,
              textAlignment: '',
              isEditable: true,
              type: 'array',
            },
            {
              id: '4',
              key: uuid(),
              label: 'Number of points when issuing a card',
              value: loyaltyStore.numberIssueCard,
              textAlignment: '',
              isEditable: true,
              type: 'number',
            },
            {
              id: '5',
              key: uuid(),
              label: 'Referral program',
              value: loyaltyStore.referralProgramType,
              textAlignment: '',
              isEditable: true,
              type: 'number',
            },
            {
              id: '6',
              key: uuid(),
              label: 'Number of points for the referrer',
              value: loyaltyStore.numberForReferrer,
              textAlignment: '',
              isEditable: true,
              type: 'number',
            },
            {
              id: '7',
              key: uuid(),
              label: 'Number of points for the referral',
              value: loyaltyStore.numberForReferral,
              textAlignment: '',
              isEditable: true,
              type: 'number',
            },
            {
              id: '8',
              key: uuid(),
              label: 'Active links',
              value: loyaltyStore.activeLinkObj,
              textAlignment: '',
              isEditable: true,
              type: 'object',
            },
            {
              id: '9',
              key: uuid(),
              label: 'Terms of use',
              value: loyaltyStore.termsObj,
              textAlignment: '',
              isEditable: true,
              type: 'object',
            },
            {
              id: '10',
              key: uuid(),
              label: 'Contact Information',
              value: loyaltyStore.contactObj,
              textAlignment: '',
              isEditable: true,
              type: 'object',
            },
          ],
          dePassName: loyaltyStore.cardName,
          dePassDesc: loyaltyStore.cardDescr,
          googlePayload: {
            hexBackgroundColor: loyaltyStore.cardBGcolor,
            barcode: {
              type: 'string',
              alternateText: 'string',
              value: [
                {
                  id: 'string',
                  key: 'string',
                  label: 'string',
                  value: 'string',
                  textAlignment: 'string',
                  isEditable: false,
                  type: 'string',
                },
              ],
            },
            rotatingQR: false,
            securityAnimation: false,
            googleHeader: [],
            googlePrimary: [
              {
                id: '1',
                key: '1',
                label: 'Reward',
                value: loyaltyStore.fieldObj.Reward,
                textAlignment: '',
                isEditable: true,
                type: 'number',
              },
              {
                id: '2',
                key: '2',
                label: 'Balance',
                value: loyaltyStore.fieldObj.Balance,
                textAlignment: '',
                isEditable: true,
                type: 'number',
              },
            ],
          },
          applePayload: {
            backgroundColor: loyaltyStore.cardBGcolor.includes('rgb')
              ? loyaltyStore.cardBGcolor
              : convertToRGB(loyaltyStore.cardBGcolor),
            foregroundColor: loyaltyStore.textValueColor.includes('rgb')
              ? loyaltyStore.textValueColor
              : convertToRGB(loyaltyStore.textValueColor),
            labelColor: loyaltyStore.textLabelColor.includes('rgb')
              ? loyaltyStore.textLabelColor
              : convertToRGB(loyaltyStore.textLabelColor),
            barcode: {
              format: 'string',
              altText: 'string',
              value: [
                {
                  id: 'string',
                  key: 'string',
                  label: 'string',
                  value: 'string',
                  textAlignment: 'string',
                  isEditable: false,
                  type: 'string',
                },
              ],
              messageEncoding: 'iso-8859-1',
            },
            location: [],
            headerFields: [],
            secondaryFields: [
              {
                id: '1',
                key: '1',
                label: 'Reward',
                value: loyaltyStore.fieldObj.Reward,
                textAlignment: '',
                isEditable: true,
                type: 'number',
              },
              {
                id: '2',
                key: '2',
                label: 'Balance',
                value: loyaltyStore.fieldObj.Balance,
                textAlignment: '',
                isEditable: true,
                type: 'number',
              },
            ],
            primaryFields: [],
          },
          common: {
            passBanner: loyaltyStore.bgImage,
            passLogo: loyaltyStore.bannerLogo,
            notificationLogo: loyaltyStore.notificationLogo,
          },
          metaData: loyaltyStore.cardIssueObj.filter(c => c.type !== ''),
        },
        (res: LoyaltyProps) => {
          setLoading(false);
          navigate(
            replaceRouteParam(
              `${routeNames.dashboard.home}/loyalty/design?mode=update&id=${res._id}`,
              'workspaceId',
              AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
            ),
          );
          setCardId(res?._id);
        },
      );
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const idParam: string = urlParams.get('id') || '';
      updateLoyalty(
        idParam,
        {
          nfcEnabled: false,
          nfcMessage: [],
          backFields: [
            {
              id: '1',
              key: loyaltyStore.loyalty?.backFields[0].key
                ? loyaltyStore.loyalty?.backFields[0].key
                : '',
              label: 'Program Name',
              value: loyaltyStore.programName,
              textAlignment: '',
              isEditable: true,
              type: 'string',
            },
            {
              id: '2',
              key: loyaltyStore.loyalty?.backFields[1].key
                ? loyaltyStore.loyalty?.backFields[1].key
                : '',
              label: 'Points Earned',
              value: loyaltyStore.pointEarnsObj,
              textAlignment: '',
              isEditable: true,
              type: 'object',
            },
            {
              id: '3',
              key: loyaltyStore.loyalty?.backFields[2].key
                ? loyaltyStore.loyalty?.backFields[2].key
                : '',
              label: 'Rewards Offered',
              value: loyaltyStore.rewardOfferedObj,
              textAlignment: '',
              isEditable: true,
              type: 'array',
            },
            {
              id: '4',
              key: loyaltyStore.loyalty?.backFields[3].key
                ? loyaltyStore.loyalty?.backFields[3].key
                : '',
              label: 'Number of points when issuing a card',
              value: loyaltyStore.numberIssueCard,
              textAlignment: '',
              isEditable: true,
              type: 'number',
            },
            {
              id: '5',
              key: loyaltyStore.loyalty?.backFields[4].key
                ? loyaltyStore.loyalty?.backFields[4].key
                : '',
              label: 'Referral program',
              value: loyaltyStore.referralProgramType,
              textAlignment: '',
              isEditable: true,
              type: 'number',
            },
            {
              id: '6',
              key: loyaltyStore.loyalty?.backFields[5].key
                ? loyaltyStore.loyalty?.backFields[5].key
                : '',
              label: 'Number of points for the referrer',
              value: loyaltyStore.numberForReferrer,
              textAlignment: '',
              isEditable: true,
              type: 'number',
            },
            {
              id: '7',
              key: loyaltyStore.loyalty?.backFields[6].key
                ? loyaltyStore.loyalty?.backFields[6].key
                : '',
              label: 'Number of points for the referral',
              value: loyaltyStore.numberForReferral,
              textAlignment: '',
              isEditable: true,
              type: 'number',
            },
            {
              id: '8',
              key: loyaltyStore.loyalty?.backFields[7].key
                ? loyaltyStore.loyalty?.backFields[7].key
                : '',
              label: 'Active links',
              value: loyaltyStore.activeLinkObj,
              textAlignment: '',
              isEditable: true,
              type: 'object',
            },
            {
              id: '9',
              key: loyaltyStore.loyalty?.backFields[8].key
                ? loyaltyStore.loyalty?.backFields[8].key
                : '',
              label: 'Terms of use',
              value: loyaltyStore.termsObj,
              textAlignment: '',
              isEditable: true,
              type: 'object',
            },
            {
              id: '10',
              key: loyaltyStore.loyalty?.backFields[9].key
                ? loyaltyStore.loyalty?.backFields[9].key
                : '',
              label: 'Contact Information',
              value: loyaltyStore.contactObj,
              textAlignment: '',
              isEditable: true,
              type: 'object',
            },
          ],
          dePassName: loyaltyStore.cardName,
          dePassDesc: loyaltyStore.cardDescr,
          googlePayload: {
            hexBackgroundColor: loyaltyStore.cardBGcolor,
            barcode: {
              type: 'string',
              alternateText: 'string',
              value: [
                {
                  id: 'string',
                  key: 'string',
                  label: 'string',
                  value: 'string',
                  textAlignment: 'string',
                  isEditable: false,
                  type: 'string',
                },
              ],
            },
            rotatingQR: false,
            securityAnimation: false,
            googleHeader: [],
            googlePrimary: [
              {
                id: '1',
                key: '1',
                label: 'Reward',
                value: loyaltyStore.fieldObj.Reward,
                textAlignment: '',
                isEditable: true,
                type: 'number',
              },
              {
                id: '2',
                key: '2',
                label: 'Balance',
                value: loyaltyStore.fieldObj.Balance,
                textAlignment: '',
                isEditable: true,
                type: 'number',
              },
            ],
          },
          applePayload: {
            backgroundColor: loyaltyStore.cardBGcolor.includes('rgb')
              ? loyaltyStore.cardBGcolor
              : convertToRGB(loyaltyStore.cardBGcolor),
            foregroundColor: loyaltyStore.textValueColor.includes('rgb')
              ? loyaltyStore.textValueColor
              : convertToRGB(loyaltyStore.textValueColor),
            labelColor: loyaltyStore.textLabelColor.includes('rgb')
              ? loyaltyStore.textLabelColor
              : convertToRGB(loyaltyStore.textLabelColor),
            barcode: {
              format: 'string',
              altText: 'string',
              value: [
                {
                  id: 'string',
                  key: 'string',
                  label: 'string',
                  value: 'string',
                  textAlignment: 'string',
                  isEditable: false,
                  type: 'string',
                },
              ],
              messageEncoding: 'iso-8859-1',
            },
            location: [],
            headerFields: [],
            secondaryFields: [
              {
                id: '1',
                key: '1',
                label: 'Reward',
                value: loyaltyStore.fieldObj.Reward,
                textAlignment: '',
                isEditable: true,
                type: 'number',
              },
              {
                id: '2',
                key: '2',
                label: 'Balance',
                value: loyaltyStore.fieldObj.Balance,
                textAlignment: '',
                isEditable: true,
                type: 'number',
              },
            ],
            primaryFields: [],
          },
          common: {
            passBanner: loyaltyStore.bgImage,
            passLogo: loyaltyStore.bannerLogo,
            notificationLogo: loyaltyStore.notificationLogo,
          },
          metaData: loyaltyStore.cardIssueObj.filter(c => c.type !== ''),
        },
        () => {
          setLoading(false);
        },
      );
    }
  };

  const Tab = () => {
    return (
      <div className='flex  bg-gray-100 p-[2px] rounded-md h-[38px] text-[12px] mb-5'>
        <div
          onClick={() => setTab(0)}
          className={`${
            tab === 0
              ? 'bg-white shadow border border-black border-opacity-20 '
              : ''
          } cursor-pointer flex items-center justify-center text-center px-5 py-1 rounded-md w-[100%]`}
        >
          Design
        </div>

        <div
          onClick={() => setTab(1)}
          className={`${
            tab === 1
              ? 'bg-white shadow border border-black border-opacity-20 '
              : ''
          } cursor-pointer flex items-center justify-center text-center px-5 py-1 rounded-md w-[100%]`}
        >
          Setting
        </div>

        {getMode() !== 'create' && (
          <div
            onClick={() => setTab(2)}
            className={`${
              tab === 2
                ? 'bg-white shadow border border-black border-opacity-20 '
                : ''
            } cursor-pointer flex items-center justify-center text-center px-5 py-1 rounded-md w-[100%]`}
          >
            Send Push
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className='w-full flex flex-row  h-full max-h-[94vh] py-3'>
        <div className='flex-1 pl-6 py-6 overflow-y-auto small-scrollbar'>
          <Tab />

          {tab == 0 ? (
            <>
              <div
                id='cardDetail'
                className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded'
              >
                {accord5.map((item, index: number) => {
                  const isLastItem = accord5.length - 1 === index;
                  return (
                    <>
                      <Accordion
                        key={item.section}
                        Content={
                          <item.Content
                            handleCancel={() =>
                              selectedIndex === item.section
                                ? setSelectedIndex(null)
                                : setSelectedIndex(item.section)
                            }
                          />
                        }
                        Icon={<item.Icon />}
                        payload={{
                          ...item,
                          index,
                          selected: selectedIndex === item.section,
                        }}
                        handleClick={() =>
                          selectedIndex === item.section
                            ? setSelectedIndex(null)
                            : setSelectedIndex(item.section)
                        }
                        containerClass={
                          loyaltyStore.showCardTitleError &&
                          index == 0 &&
                          !loyaltyStore.cardName
                            ? 'border-darkRed  bg-lightRed'
                            : loyaltyStore.showCardDescripError &&
                              index == 1 &&
                              !loyaltyStore.cardDescr
                            ? 'border-darkRed  bg-lightRed'
                            : ''
                        }
                      />
                      {!isLastItem && (
                        <div className='border border-borderLight w-full h-[1px]'></div>
                      )}
                    </>
                  );
                })}
              </div>

              <div
                id='logoId'
                className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'
              >
                {accord1.map((item, index: number) => {
                  const isLastItem = accord1.length - 1 === index;
                  return (
                    <>
                      <Accordion
                        key={item.section}
                        Content={
                          <item.Content
                            handleCancel={() =>
                              selectedIndex === item.section
                                ? setSelectedIndex(null)
                                : setSelectedIndex(item.section)
                            }
                          />
                        }
                        Icon={<item.Icon />}
                        payload={{
                          ...item,
                          index,
                          selected: selectedIndex === item.section,
                        }}
                        handleClick={() =>
                          selectedIndex === item.section
                            ? setSelectedIndex(null)
                            : setSelectedIndex(item.section)
                        }
                        containerClass={
                          loyaltyStore.showLogosError
                            ? index == 0 && !loyaltyStore.bannerLogo
                              ? 'border-darkRed  bg-lightRed'
                              : index == 1 && !loyaltyStore.notificationLogo
                              ? 'border-darkRed  bg-lightRed'
                              : index == 2 && !loyaltyStore.bgImage
                              ? 'border-darkRed  bg-lightRed'
                              : ''
                            : ''
                        }
                      />
                      {!isLastItem && (
                        <div className='border border-borderLight w-full h-[1px]'></div>
                      )}
                    </>
                  );
                })}
              </div>

              <div className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'>
                {accord2.map((item, index: number) => {
                  return (
                    <>
                      <Accordion
                        key={item.section}
                        Content={
                          <item.Content
                            handleCancel={() =>
                              selectedIndex === item.section
                                ? setSelectedIndex(null)
                                : setSelectedIndex(item.section)
                            }
                          />
                        }
                        Icon={<item.Icon />}
                        payload={{
                          ...item,
                          index,
                          selected: selectedIndex === item.section,
                        }}
                        handleClick={() =>
                          selectedIndex === item.section
                            ? setSelectedIndex(null)
                            : setSelectedIndex(item.section)
                        }
                      />
                    </>
                  );
                })}
              </div>

              <div className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'>
                {accord3.map((item, index: number) => {
                  return (
                    <>
                      <Accordion
                        key={item.section}
                        Content={
                          <item.Content
                            handleCancel={() =>
                              selectedIndex === item.section
                                ? setSelectedIndex(null)
                                : setSelectedIndex(item.section)
                            }
                          />
                        }
                        Icon={<item.Icon />}
                        payload={{
                          ...item,
                          index,
                          selected: selectedIndex === item.section,
                        }}
                        handleClick={() =>
                          selectedIndex === item.section
                            ? setSelectedIndex(null)
                            : setSelectedIndex(item.section)
                        }
                      />
                    </>
                  );
                })}
              </div>

              <div
                id='cardIssue'
                className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8 mb-[50px]'
              >
                {accord4.map((item, index: number) => {
                  return (
                    <>
                      <Accordion
                        key={item.section}
                        Content={
                          <item.Content
                            handleCancel={() =>
                              selectedIndex === item.section
                                ? setSelectedIndex(null)
                                : setSelectedIndex(item.section)
                            }
                          />
                        }
                        Icon={<item.Icon />}
                        payload={{
                          ...item,
                          index,
                          selected: selectedIndex === item.section,
                        }}
                        handleClick={() =>
                          selectedIndex === item.section
                            ? setSelectedIndex(null)
                            : setSelectedIndex(item.section)
                        }
                        containerClass={
                          loyaltyStore.showCardIssueError
                            ? 'border-darkRed  bg-lightRed'
                            : ''
                        }
                      />
                    </>
                  );
                })}
              </div>
            </>
          ) : tab == 1 ? (
            <Setting />
          ) : (
            <SendPush />
          )}
          {tab !== 2 && (
            <div className='flex mt-[20px] w-full justify-center space-x-4'>
              <Button
                type='button'
                text='Cancel'
                size='sm'
                onClick={() =>
                  replaceRouteParam(
                    `${routeNames.dashboard.home}/loyalty/card-type`,
                    'workspaceId',
                    AuthStore?.user_workspace_info?.active_workspace?.workspace
                      ?._id,
                  )
                }
                variant='outline'
              />
              <Button
                type='button'
                text={getMode() == 'create' ? 'Create' : 'Update'}
                size='sm'
                className='bg-lightBlue'
                onClick={onSubmit}
                loading={loading}
              />
            </div>
          )}
        </div>
        <div className='w-auto min-w-[376px] flex max-h-[94vh] overflow-auto flex-shrink-0 justify-center'>
          {tab == 1 ? (
            <SettingDevices />
          ) : tab == 0 ? (
            <DesignDevices />
          ) : (
            <NotificationDevices />
          )}
        </div>
      </div>
    </>
  );
};
export default observer(Design);
