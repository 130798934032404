import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import { loyaltyStore } from '@/state/LoyaltyStore';

const CardName: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');

  useEffect(() => {
    setName(loyaltyStore.cardName);
  }, []);

  const onSubmit = () => {
    if (name.trim() == '') {
      setNameError('Card name is required');
    } else {
      setNameError('');
      loyaltyStore.setCardName(name.trim());
      handleCancel();
    }
  };

  const onClose = () => {
    setName(getMode == 'update' ? loyaltyStore.loyalty?.dePassName || '' : '');
    loyaltyStore.setCardName(
      getMode == 'update' ? loyaltyStore.loyalty?.dePassName || '' : '',
    );
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px]  flex-1'>
          <Input
            value={name}
            placeholder='Card Name'
            type='text'
            onChange={e => {
              setName(e.target.value);
              loyaltyStore.setShowCardTitleError(false);
            }}
          />
        </div>
        {nameError && (
          <p className='mb-[6px] mt-1' style={{ color: 'red' }}>
            {nameError}
          </p>
        )}
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};
export default observer(CardName);
