export const ReferralIcon = () => {
  return (
    <svg
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.4106 10.801C14.3629 10.801 14.3206 10.8136 14.2711 10.8154L11.5463 6.15587C12.0528 5.65373 12.3988 5.01242 12.5402 4.31333C12.6817 3.61424 12.6123 2.88889 12.3407 2.22932C12.0692 1.56976 11.6079 1.00573 11.0153 0.608819C10.4227 0.211904 9.72549 0 9.01223 0C8.29897 0 7.60179 0.211904 7.00918 0.608819C6.41656 1.00573 5.95522 1.56976 5.68371 2.22932C5.41221 2.88889 5.34277 3.61424 5.48422 4.31333C5.62567 5.01242 5.97163 5.65373 6.47818 6.15587L3.76146 10.8163C3.71017 10.8136 3.66337 10.801 3.61208 10.801C2.93832 10.7987 2.27743 10.9855 1.70459 11.3402C1.13175 11.6948 0.669958 12.2032 0.371754 12.8074C0.0735501 13.4115 -0.0490948 14.0873 0.0177723 14.7577C0.0846394 15.4282 0.338334 16.0664 0.749994 16.5997C1.16165 17.1331 1.71475 17.5403 2.34636 17.7748C2.97797 18.0094 3.66274 18.062 4.32276 17.9266C4.98278 17.7912 5.59155 17.4733 6.07982 17.009C6.56809 16.5448 6.91625 15.9528 7.0847 15.3004H10.938C11.1073 15.9509 11.4556 16.5409 11.9434 17.0033C12.4311 17.4658 13.0388 17.7822 13.6974 17.9166C14.356 18.051 15.0391 17.9981 15.6691 17.7638C16.2991 17.5295 16.8507 17.1232 17.2614 16.5911C17.6721 16.059 17.9253 15.4223 17.9924 14.7535C18.0594 14.0847 17.9375 13.4105 17.6406 12.8075C17.3437 12.2045 16.8837 11.6968 16.3127 11.3421C15.7417 10.9874 15.0827 10.8 14.4106 10.801ZM10.938 13.5006H7.0847C6.85617 12.6158 6.29918 11.8513 5.52701 11.3625L8.04036 7.05214C8.67318 7.25192 9.35218 7.25192 9.98499 7.05214L12.5046 11.3589C11.728 11.8465 11.1675 12.6128 10.938 13.5006Z'
        fill='#2173F0'
      />
    </svg>
  );
};
