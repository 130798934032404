import { DeviceIconProps } from '@/types/icon.type';
import { FC } from 'react';

export const AndroidIcon: FC<DeviceIconProps> = ({
  className,
  width,
  height,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 35 38'
      className={className}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.0488 4.49123L25.3866 1.02868C25.5898 0.727817 25.5097 0.31552 25.209 0.112339C24.9081 -0.09077 24.4958 -0.010742 24.2926 0.29005L21.8782 3.86613C20.4841 3.22989 18.9346 2.87533 17.3027 2.87533C15.6708 2.87533 14.1213 3.22989 12.7272 3.8662L10.3127 0.29005C10.1096 -0.010742 9.69727 -0.09077 9.39648 0.112339C9.09561 0.31552 9.01566 0.727744 9.21876 1.02868L11.5565 4.49123C8.62152 6.28564 6.58797 9.41042 6.30273 13.0277H28.3028C28.0175 9.41042 25.9838 6.28578 23.0488 4.49123ZM12.8129 9.6953C11.9624 9.6953 11.2729 9.00587 11.2729 8.1553C11.2729 7.30473 11.9624 6.6153 12.8129 6.6153C13.6634 6.6153 14.3529 7.30473 14.3529 8.1553C14.3529 9.00587 13.6634 9.6953 12.8129 9.6953ZM21.7925 9.6953C20.942 9.6953 20.2525 9.00587 20.2525 8.1553C20.2525 7.30473 20.942 6.6153 21.7925 6.6153C22.643 6.6153 23.3325 7.30473 23.3325 8.1553C23.3325 9.00587 22.643 9.6953 21.7925 9.6953Z'
        fill='black'
      />
      <path
        d='M6.13281 17.6696V30.3916C6.13281 31.3904 6.94901 32.2066 7.94784 32.2066H10.9487V35.1766C10.9487 36.204 11.7891 37.0466 12.8187 37.0466C13.846 37.0466 14.6887 36.204 14.6887 35.1766V32.2066H19.9688V35.1766C19.9688 36.204 20.8092 37.0466 21.8388 37.0466C22.8662 37.0466 23.7088 36.204 23.7088 35.1766V32.2066H26.318C27.3146 32.2066 28.133 31.3904 28.133 30.3916V17.6696V14.9746H6.13281V17.6696Z'
        fill='black'
      />
      <path
        d='M2.1609 14.9746C0.972348 14.9746 0 15.947 0 17.1355V24.2537C0 25.4422 0.97242 26.4146 2.1609 26.4146C3.34938 26.4146 4.3218 25.4422 4.3218 24.2537V17.1355C4.3218 15.947 3.34938 14.9746 2.1609 14.9746Z'
        fill='black'
      />
      <path
        d='M32.4422 14.9746C31.2537 14.9746 30.2812 15.947 30.2812 17.1355V24.2537C30.2812 25.4422 31.2537 26.4146 32.4422 26.4146C33.6306 26.4146 34.6031 25.4422 34.6031 24.2537V17.1355C34.603 15.947 33.6306 14.9746 32.4422 14.9746Z'
        fill='black'
      />
    </svg>
  );
};
