import Logo from '@/components/atoms/Logo';
import Search from '@/components/atoms/Search';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { replaceRouteParam } from '@/utils/routes';
import { Popover, Transition } from '@headlessui/react';
import { UserIcon, ViewGridAddIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Fragment } from 'react';
import { AiOutlineRadiusSetting, AiOutlineSetting } from 'react-icons/ai';
import { GoInbox } from 'react-icons/go';
import { HiOutlineHome } from 'react-icons/hi2';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {
  function MobileNavLink({ to, children }: any) {
    const location = useLocation();
    const isActive = location.pathname === to;
    return (
      <Popover.Button
        as={NavLink}
        to={to}
        className={`${isActive ? 'text-secondary' : 'text-[#000000!important]'} 
         flex items-center justify-start space-x-2 w-full p-2`}
      >
        {children}
      </Popover.Button>
    );
  }

  function MobileNavIcon({ open }: any) {
    return (
      <svg
        aria-hidden='true'
        className='h-3.5 w-3.5 overflow-visible stroke-slate-700'
        fill='none'
        strokeWidth={2}
        strokeLinecap='round'
      >
        <path
          d='M0 1H14M0 7H14M0 13H14'
          className={clsx(
            'origin-center transition',
            open && 'scale-90 opacity-0',
          )}
        />
        <path
          d='M2 2L12 12M12 2L2 12'
          className={clsx(
            'origin-center transition',
            !open && 'scale-90 opacity-0',
          )}
        />
      </svg>
    );
  }

  function MobileNavigation() {
    return (
      <Popover>
        <Popover.Button
          className='relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none'
          aria-label='Toggle Navigation'
        >
          {({ open }) => <MobileNavIcon open={open} />}
        </Popover.Button>
        <Transition.Root>
          <Transition.Child
            as={Fragment}
            enter='duration-150 ease-out'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='duration-150 ease-in'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Popover.Overlay className='fixed inset-0 bg-slate-300/50' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='duration-150 ease-out'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='duration-100 ease-in'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Popover.Panel
              as='div'
              className='z-[999999!important] absolute inset-x-2  mt-4 flex origin-top flex-col rounded-xl bg-white p-4 text-lg tracking-tight  shadow-xl ring-1 ring-slate-900/5'
            >
              <MobileNavLink
                to={replaceRouteParam(
                  `${routeNames.dashboard.home}`,
                  'workspaceId',
                  AuthStore?.user_workspace_info?.active_workspace?.workspace
                    ?._id,
                )}
              >
                <HiOutlineHome className='w-4 h-4' />
                <p>Dashboard</p>
              </MobileNavLink>
              <MobileNavLink
                to={replaceRouteParam(
                  `${routeNames.dashboard.chat}`,
                  'workspaceId',
                  AuthStore?.user_workspace_info?.active_workspace?.workspace
                    ?._id,
                )}
              >
                <GoInbox className='w-4 h-4' />
                <p>Inbox</p>
              </MobileNavLink>
              <MobileNavLink
                to={replaceRouteParam(
                  `${routeNames.dashboard.contacts}`,
                  'workspaceId',
                  AuthStore?.user_workspace_info?.active_workspace?.workspace
                    ?._id,
                )}
              >
                <UserIcon className='w-4 h-4' />
                <p>Contacts</p>
              </MobileNavLink>
              <MobileNavLink
                to={replaceRouteParam(
                  `${routeNames.dashboard.configurations}`,
                  'workspaceId',
                  AuthStore?.user_workspace_info?.active_workspace?.workspace
                    ?._id,
                )}
              >
                <AiOutlineRadiusSetting className='w-4 h-4' />
                <p>Configurations</p>
              </MobileNavLink>
              <MobileNavLink
                to={replaceRouteParam(
                  `${routeNames.dashboard.integration}`,
                  'workspaceId',
                  AuthStore?.user_workspace_info?.active_workspace?.workspace
                    ?._id,
                )}
              >
                <ViewGridAddIcon className='w-4 h-4' />
                <p>Integrations</p>
              </MobileNavLink>
              <MobileNavLink
                to={replaceRouteParam(
                  `${routeNames.dashboard.settings}`,
                  'workspaceId',
                  AuthStore?.user_workspace_info?.active_workspace?.workspace
                    ?._id,
                )}
              >
                <AiOutlineSetting className='w-4 h-4' />
                <p>Settings</p>
              </MobileNavLink>
              <hr className='m-2 border-slate-300/40' />
            </Popover.Panel>
          </Transition.Child>
        </Transition.Root>
      </Popover>
    );
  }

  return (
    <div>
      <div className='hidden z-[999999!important] md:flex justify-between items-center px-[15px] bg-white w-full py-2 border-b border-border'>
        <Logo size='md' />

        <div className='sm:w-[40%] md:w-[25%] hidden'>
          <Search placeholder='Search' />
        </div>
      </div>
      <div className='md:hidden fixed -top-0 w-full flex z-[999999!important] justify-between items-center px-[15px] bg-white  py-2'>
        <MobileNavigation />
        <Logo size='md' />
      </div>
    </div>
  );
};
export default Header;
