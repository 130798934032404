import {
  hoursOptions,
  minutesOptions,
  meridiemOptions,
} from '@/constants/time';
import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { FC, useMemo, useState, useRef } from 'react';
import { useOutsideClickDetector } from '@/hooks/useOutsideClickDetector';

type TimePickerProps = {
  onSelect: (value: string) => void;
  value: any;
};

export const TimePicker: FC<TimePickerProps> = ({ onSelect, value }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [time, setTime] = useState({
    hour: '9',
    minute: '00',
    meridiem: 'am',
  });

  const hours = useMemo(
    () =>
      hoursOptions.map(hour => (
        <li
          className={clsx(
            hour === time.hour ? 'bg-gray-100' : '',
            'p-1 hover:bg-gray-100 rounded',
          )}
          key={hour}
          onClick={() => {
            setTime(pre => ({
              ...pre,
              hour,
            }));
          }}
        >
          {hour === time.hour ? time.hour : hour}
        </li>
      )),
    [time.hour],
  );

  const minutes = useMemo(
    () =>
      minutesOptions.map((minute, index) => (
        <li
          className={clsx(
            minute === time.minute ? 'bg-gray-100' : '',
            'p-1 hover:bg-gray-100 rounded',
          )}
          key={minute + '_' + index}
          onClick={() => {
            setTime(pre => ({
              ...pre,
              minute: minute ?? '00',
            }));
          }}
        >
          {minute}
        </li>
      )),
    [time.minute],
  );

  const meridiems = useMemo(
    () =>
      meridiemOptions.map(meridiem => (
        <li
          className={clsx(
            meridiem === time.meridiem ? 'bg-gray-100' : '',
            'p-1 hover:bg-gray-100 rounded',
          )}
          key={meridiem}
          onClick={() => {
            setTime(pre => ({
              ...pre,
              meridiem,
            }));
          }}
        >
          {meridiem.toUpperCase() == time.meridiem.toUpperCase()
            ? time.meridiem.toUpperCase()
            : meridiem.toUpperCase()}
        </li>
      )),
    [time.meridiem],
  );

  const mainRef = useRef(null);
  useOutsideClickDetector(mainRef, () => {
    setDropdownOpen(false);
  });

  return (
    <div
      ref={mainRef}
      className={`relative group h-[31px] w-[126px] xl:w-[117px] mt-[10px] mb-[10px] right-1 xl:mt-0 xl:mb-0 xl:right-0 px-3 py-1.5 bg-white rounded shadow border border-border justify-center items-center space-x-2 flex cursor-pointer`}
    >
      <button
        onClick={() => setDropdownOpen(pre => !pre)}
        className='w-full flex items-center justify-center space-x-2'
      >
        <Icon
          icon={'basil:clock-solid'}
          className='w-4 h-4 text-[#656971] group-hover:text-secondary'
          aria-hidden='true'
        />
        <span className='text-neutral-900 text-md font-medium leading-tight group-hover:text-secondary'>
          {value}
        </span>
      </button>
      <div
        className={clsx(
          dropdownOpen
            ? 'pointer-events-auto opacity-100 '
            : 'pointer-events-none opacity-0',
          'absolute top-full mt-1 z-50 transition-opacity text-center bg-white border border-gray-100 rounded text-md w-full right-0 overflow-hidden',
        )}
      >
        <div className='flex justify-between max-h-32 p-1'>
          <ul className='overflow-y-auto small-scrollbar w-full'>{hours}</ul>
          <div className='h-full border border-gray-100 ' />
          <ul className='overflow-y-auto small-scrollbar w-full'>{minutes}</ul>
          <ul className='overflow-y-auto small-scrollbar w-full'>
            {meridiems}
          </ul>
        </div>
        <button
          className='bg-secondary text-white w-full block py-1'
          onClick={() => {
            onSelect(`${time.hour}:${time.minute} ${time.meridiem}`);
            setDropdownOpen(false);
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};
