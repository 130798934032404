import Icon from '@/assets/Icons';
import Icons from '@/assets/Icons/icons.json';
import Input from '@/components/atoms/Input';
import Spinner from '@/components/atoms/Spinner';
import { notify } from '@/helpers/index';
import { useLoyalty } from '@/hooks/useLoyalty';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { loyaltyStore } from '@/state/LoyaltyStore';
import { CardIssueProps } from '@/types/loyalty.types';
import { replaceRouteParam } from '@/utils/routes';
import { Field, FieldProps, Formik, FormikHelpers, FormikValues } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-date-picker';
import { Value } from 'react-date-picker/dist/cjs/shared/types';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

// type DateType = Date | null;

// type DateProp = DateType | [DateType, DateType];

const Download = () => {
  const {
    getLoyalty,
    createTrackPass,
    passDownloadForApple,
    passDownloadForGoogle,
  } = useLoyalty();
  const navigate = useNavigate();

  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<number>(0);
  const [isTerms, setIsTerms] = useState<boolean>(true);
  const [isPersonalData, setIsPersonalData] = useState<boolean>(true);
  const [metaData, setMetaData] = useState<CardIssueProps[]>([]);
  const [code, setCode] = useState<string>('');
  const [showCodeField, setShowCodeField] = useState<boolean>(false);
  const [apiRequest, setAPIRequest] = useState<boolean>(false);

  const yupFields = useMemo(() => {
    if (metaData && metaData.length) {
      const yupObject: {
        [key: string]:
          | Yup.StringSchema<string | undefined>
          | Yup.DateSchema<Date | undefined>;
      } = {};

      for (let i = 0; i < metaData.length; i++) {
        const tmpData = metaData[i];

        if (tmpData['type'] === 'firstName') {
          yupObject.firstName = Yup.string().required('First name is required');
        }
        if (tmpData['type'] === 'lastName') {
          yupObject.lastName = Yup.string().required('Last name is required');
        }
        if (tmpData['type'] === 'email') {
          yupObject.email = Yup.string()
            .required('Email is required')
            .email('Invalid email');
        }
        if (tmpData['type'] === 'phone') {
          yupObject.phone = Yup.string().required('Phone number is required');
        }
        if (tmpData['type'] === 'dob') {
          yupObject.dob = Yup.date().required('Date of Birth is required');
        }
      }

      return yupObject;
    }

    return {};
  }, [metaData]);

  const validationSchema = Yup.object().shape(yupFields);

  const toggleTerms = () => {
    setShowTerms(!showTerms);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idParam: string = urlParams.get('id') || '';
    const workspaceURL = replaceRouteParam(
      `${routeNames.dashboard.home}`,
      'workspaceId',
      AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
    );
    if (idParam) {
      getLoyalty(idParam, (res: any) => {
        if (res.status == 400) {
          navigate(workspaceURL);
        }
      });
    } else {
      navigate(workspaceURL);
    }
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    // Update button text based on the device type
    if (userAgent.match(/Macintosh|MacIntel|iPhone|iPad|iPod/i)) {
      setButtonText(0);
    } else if (userAgent.match(/Windows/i)) {
      setButtonText(2);
      // } else if (userAgent.match(/iPad|iPod/i)) {
      //     setButtonText("Install in Mobile Wallet");
    } else if (userAgent.match(/Android/i) && userAgent.match(/Mobile/i)) {
      setButtonText(1);
    }
  }, []);

  useEffect(() => {
    if (loyaltyStore.loyalty?.metaData) {
      setMetaData(loyaltyStore.loyalty?.metaData);
    }
  }, [loyaltyStore.loyalty?.metaData]);

  const handleInstallClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const idParam: string = urlParams.get('id') || '';
    if (idParam && isTerms && isPersonalData) {
      const req = {
        dePassKitId: idParam,
        metaData: loyaltyStore.loyalty?.metaData || [],
      };

      if (buttonText === 0) {
        createTrackPass(req, (res: any) => {
          if (res.data) {
            passDownloadForApple(res?.data?.trackPassId, (res: any) => {
              //APPLE
              if (res) {
                const url = window.URL.createObjectURL(
                  new Blob([res], {
                    type: 'application/vnd.apple.pkpass',
                  }),
                );
                const a = document.createElement('a');
                a.href = url;
                a.download = 'sticky.pkpass'; // Set the desired file name
                document.body.appendChild(a);
                a.click();
                a.remove();
              }
            });
          }
        });
      } else if (idParam && buttonText === 1) {
        createTrackPass(req, (res: any) => {
          if (res.data) {
            passDownloadForGoogle(res?.data?.trackPassId, (res: any) => {
              //GOOGLE
              if (res.data) {
                const a = document.createElement('a');
                a.href = res.data;
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                a.remove();
              }
            });
          }
        });
      } else if (idParam && buttonText === 2) {
        setAPIRequest(true);
        createTrackPass(req, (res: any) => {
          //HOME
          if (res.status == '400') {
            setShowCodeField(true);
          } else if (res.data) {
            setShowCodeField(false);
            navigate(
              `${routeNames.pwa.loyalty}?id=${idParam}?trackpassId=${res?.data?.trackPassId}`,
            );
          }
          setTimeout(() => {
            setAPIRequest(false);
          }, 1000);
        });
      } else {
        //..
      }
    } else {
      notify('error', 'Please accepts T&C.');
    }
  };
  const handleDateChange = ({
    value,
    form,
    field,
  }: {
    value: Value;
    form: FormikHelpers<any>;
    field: { name: string };
  }) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          dob: '',
        }}
        validationSchema={validationSchema}
        onSubmit={() => {
          handleInstallClick();
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className='h-screen overflow-y-auto'>
              <div className='text-center flex justify-center h-14 bg-white flex-col mb-8'>
                <div className='text-[16px] leading-[20px] text-gray-400 text-opacity-90'>
                  {loyaltyStore.loyalty?.dePassName}
                </div>
              </div>
              <div className='px-[1.2rem] py-0 my-0 mx-auto max-w-[40rem] mb-8'>
                <h2 className='text-center text-2xl mb-3 font-normal'>
                  {loyaltyStore.loyalty?.dePassName}
                </h2>
                <div>
                  <div className='bg-white rounded-[4px] border border-gray-50 p-4'>
                    {loyaltyStore.loyalty &&
                      loyaltyStore.loyalty.metaData.map((item, index) => {
                        return item.type == 'firstName' ? (
                          <Field name='firstName'>
                            {({ field, form }: FieldProps<FormikValues>) => {
                              const arr: any = loyaltyStore.loyalty || null;
                              arr.metaData[index].value =
                                field.value as unknown as string;
                              return (
                                <div className='mb-4'>
                                  <Input
                                    value={field.value as unknown as string}
                                    placeholder='First Name'
                                    type='text'
                                    onChange={
                                      form.handleChange as (
                                        event: React.ChangeEvent<HTMLInputElement>,
                                      ) => void
                                    }
                                    onBlur={
                                      form.handleBlur as (
                                        event: React.FocusEvent<HTMLInputElement>,
                                      ) => void
                                    }
                                    name='firstName'
                                  />
                                  {form?.touched?.firstName &&
                                    form?.errors?.firstName && (
                                      <div className='text-red-500'>
                                        {form.errors.firstName as string}
                                      </div>
                                    )}
                                </div>
                              );
                            }}
                          </Field>
                        ) : item.type == 'lastName' ? (
                          <Field name='lastName'>
                            {({ field, form }: FieldProps<FormikValues>) => {
                              const arr: any = loyaltyStore.loyalty || null;
                              arr.metaData[index].value =
                                field.value as unknown as string;
                              return (
                                <div className='mb-4'>
                                  <Input
                                    value={field.value as unknown as string}
                                    placeholder='Last Name'
                                    type='text'
                                    onChange={
                                      form.handleChange as (
                                        event: React.ChangeEvent<HTMLInputElement>,
                                      ) => void
                                    }
                                    onBlur={
                                      form.handleBlur as (
                                        event: React.FocusEvent<HTMLInputElement>,
                                      ) => void
                                    }
                                    name='lastName'
                                  />
                                  {form.touched.lastName &&
                                    form.errors.lastName && (
                                      <div className='text-red-500'>
                                        {form.errors.lastName as string}
                                      </div>
                                    )}
                                </div>
                              );
                            }}
                          </Field>
                        ) : item.type == 'email' ? (
                          <Field name='email'>
                            {({ field, form }: FieldProps<FormikValues>) => {
                              const arr: any = loyaltyStore.loyalty || null;
                              arr.metaData[index].value =
                                field.value as unknown as string;
                              return (
                                <div className='mb-4'>
                                  <Input
                                    value={field.value as unknown as string}
                                    placeholder='Email'
                                    type='text'
                                    onChange={
                                      form.handleChange as (
                                        event: React.ChangeEvent<HTMLInputElement>,
                                      ) => void
                                    }
                                    onBlur={
                                      form.handleBlur as (
                                        event: React.FocusEvent<HTMLInputElement>,
                                      ) => void
                                    }
                                    name='email'
                                  />
                                  {form.touched.email && form.errors.email && (
                                    <div className='text-red-500'>
                                      {form.errors.email as string}
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          </Field>
                        ) : item.type == 'phone' ? (
                          <Field name='phone'>
                            {({ field, form }: FieldProps<FormikValues>) => {
                              const arr: any = loyaltyStore.loyalty || null;
                              arr.metaData[index].value =
                                field.value as unknown as string;
                              return (
                                <div className='mb-4'>
                                  <ReactPhoneInput
                                    country={'us'}
                                    value={field.value as unknown as string}
                                    onChange={value =>
                                      form.setFieldValue('phone', value)
                                    }
                                    onBlur={
                                      form.handleBlur as (
                                        event: React.FocusEvent<HTMLInputElement>,
                                      ) => void
                                    }
                                    inputStyle={{
                                      width: '100%',
                                      height: 'calc(2.25rem + 2px)',
                                      padding: '-0.625rem 0.75rem',
                                      fontSize: '1rem',
                                      fontWeight: '400',
                                      lineHeight: '1.5',
                                      color: '#495057',
                                      backgroundColor: '#fff',
                                      border: '1px solid #ced4da',
                                      borderRadius: '0.25rem',
                                      transition:
                                        'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                    }}
                                  />
                                  {form.touched.phone && form.errors.phone && (
                                    <div className='text-red-500'>
                                      {form.errors.phone as string}
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          </Field>
                        ) : (
                          <Field name='dob'>
                            {({ field, form }: FieldProps<FormikValues>) => {
                              const arr: any = loyaltyStore.loyalty || null;
                              arr.metaData[index].value =
                                field.value as unknown as string;
                              return (
                                <div className='mb-4'>
                                  <DatePicker
                                    onChange={(value: Value) =>
                                      handleDateChange({ value, form, field })
                                    }
                                    value={field.value as unknown as string}
                                    className='w-[100%]'
                                    format='MM/dd/yyyy'
                                    yearPlaceholder='yyyy'
                                    monthPlaceholder='mm'
                                    dayPlaceholder='dd'
                                  />
                                  {form.touched.dob && form.errors.dob && (
                                    <div className='text-red-500'>
                                      {form.errors.dob as string}
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          </Field>
                        );
                      })}
                    <div className='mb-6 mt-6'>
                      <div
                        className='flex mb-4'
                        style={{ alignItems: 'center' }}
                      >
                        <input
                          type='checkbox'
                          checked={isTerms}
                          onChange={() => setIsTerms(!isTerms)}
                        />
                        <div className='ml-4'>
                          <label
                            htmlFor='checkbox1'
                            className='text-[13px] leading-[20px] font-[300]'
                          >
                            I have read and accept the terms of use.
                          </label>
                        </div>
                      </div>
                      <div
                        className='flex mb-4'
                        style={{ alignItems: 'center' }}
                      >
                        <input
                          type='checkbox'
                          checked={isPersonalData}
                          onChange={() => setIsPersonalData(!isPersonalData)}
                        />
                        <div className='ml-4'>
                          <label
                            htmlFor='checkbox2'
                            className='text-[13px] leading-[20px] font-[300]'
                          >
                            I agree that my personal data can be used and
                            provided for direct marketing purposes.
                          </label>
                        </div>
                      </div>
                    </div>

                    {apiRequest ? (
                      <div className='h-[10vh] flex justify-center bg-white rounded-[16px] m-6'>
                        <Spinner color='lightGray' size={50} />
                      </div>
                    ) : showCodeField ? (
                      <>
                        <div className='w-full mb-[24px]'>
                          <div className='flex flex-col mt-[15px]  flex-1'>
                            <Input
                              value={code}
                              placeholder='Code from SMS'
                              type='text'
                              onChange={e => {
                                setCode(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className='flex justify-center w-full mb-6'>
                          <button
                            type='submit'
                            className='bg-black text-white w-full h-12 rounded-[4px] font-[200]'
                          >
                            Continue with card issue
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='flex justify-center w-full mb-6'>
                          <button
                            type='submit'
                            className='bg-black text-white w-full h-12 rounded-[4px] font-[200]'
                          >
                            {buttonText === 0
                              ? 'Install in Apple Wallet'
                              : buttonText === 1
                              ? 'Install in Google Wallet'
                              : 'Install on the Home screen'}
                          </button>
                        </div>
                        <div className='text-center mb-10'>
                          <div className='text-[16px] leading-[20px] font-[200] text-gray-400 text-opacity-90'>
                            After installing in the card preview
                            <br /> press the &quot;Add&quot; button
                          </div>
                        </div>
                      </>
                    )}

                    <div className='flex flex-col'>
                      <div
                        className=' w-full cursor-pointer  rounded-[4px] border border-gray-50 '
                        onClick={toggleTerms}
                      >
                        <div className='flex justify-between p-6'>
                          <h3 className=''>Terms of use</h3>
                          <div className=''>
                            {showTerms ? (
                              <Icon
                                icon={Icons.arrowUp}
                                size={20}
                                color='#00000'
                              />
                            ) : (
                              <Icon
                                icon={Icons.arrowDown}
                                size={20}
                                color='#00000'
                              />
                            )}
                          </div>
                        </div>

                        {showTerms && (
                          <>
                            <hr className='border border-gray-50 mt-1' />
                            <div className='p-6'>
                              <ol className='list-decimal list-inside   text-[14px] leading-[20px] font-[200]'>
                                <li>Get bonus points for every purchase.</li>
                                <li>
                                  Earn rewards for clilected points amount.
                                </li>
                                <li>Redeem rewards with your next purchase.</li>
                                <li>
                                  Cards, points and rewards expiration dates are
                                  unlimited.
                                </li>
                                <li>
                                  Cards, points and rewards cannot be exchanged,
                                  returned, replaced or purchased for cash.
                                </li>
                                <li>
                                  Cards cannot be transferred or combined with
                                  other cards.
                                </li>
                                <li>
                                  The company has the right to refuse to provide
                                  services.
                                </li>
                              </ol>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default observer(Download);
