import { FC } from 'react';

type Size = 'sm' | 'md' | 'lg';

type LogoProps = {
  size: Size;
};

const Logo: FC<LogoProps> = ({ size }) => {
  let logoSize = '';
  switch (size) {
    case 'sm':
      logoSize = 'h-6 w-6';
      break;
    case 'md':
      logoSize = 'h-8 w-8';
      break;
    case 'lg':
      logoSize = 'h-10 w-10';
      break;
    default:
      logoSize = 'h-8 w-8';
  }

  return (
    <svg
      className={`${logoSize}`}
      viewBox='0 0 26 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.2994 20.3057C21.0032 19.5221 20.3177 18.0303 18.8248 16.731C16.4267 14.6445 13.6769 14.5607 12.9511 14.563C11.8416 14.5654 9.80116 14.7755 7.82454 16.181C5.74262 17.6621 4.92597 19.6224 4.66168 20.3517H7.93891C8.20455 19.8891 8.74966 19.083 9.72687 18.4269C11.208 17.432 12.7174 17.4308 13.2804 17.4603C15.1356 17.5595 16.3558 18.5118 16.763 18.8388C17.4121 19.3604 17.8358 19.9139 18.0919 20.3057H21.3008H21.2994Z'
        fill='#24225B'
      />
      <path
        d='M4.94136 14.105C4.94136 14.8297 4.35367 15.4174 3.63011 15.4174C3.23718 15.4174 2.88548 15.2451 2.64587 14.9713L0.535801 12.8458C0.211249 12.6063 0 12.2215 0 11.7872C0 11.0626 0.586552 10.4761 1.31116 10.4761C1.59323 10.4761 1.85527 10.5646 2.06883 10.7168L2.38045 11.0272L4.43022 13.0677L4.6628 13.299C4.83741 13.5209 4.94136 13.8006 4.94136 14.105Z'
        fill='#24225B'
      />
      <path
        d='M21.8543 24.992C21.8543 25.732 21.2535 26.3327 20.5125 26.3327H5.49603C4.75615 26.3327 4.15533 25.732 4.15533 24.992C4.15533 24.252 4.75615 23.6501 5.49603 23.6501H20.5125C21.2535 23.6501 21.8543 24.2509 21.8543 24.992Z'
        fill='#24225B'
      />
      <path
        d='M13.9141 11.1853C13.402 11.6975 12.5711 11.6975 12.0589 11.1853C11.7817 10.908 11.6542 10.5374 11.6788 10.1739L11.6895 7.17867C11.6292 6.77978 11.752 6.35846 12.0589 6.05162C12.5711 5.53943 13.4007 5.53943 13.913 6.05162C14.1125 6.25107 14.2352 6.4989 14.2787 6.75736V7.19756L14.2859 10.0901V10.4182C14.2529 10.6991 14.129 10.9693 13.9141 11.1853Z'
        fill='#24225B'
      />
      <path
        d='M22.3711 15.4174C21.6466 15.4174 21.0587 14.8297 21.0587 14.1062C21.0587 13.7132 21.2311 13.3616 21.5049 13.122L23.6303 11.0119C23.8699 10.6873 24.2546 10.4761 24.689 10.4761C25.4137 10.4761 26 11.0626 26 11.7872C26 12.0693 25.9117 12.3313 25.7593 12.5449L25.449 12.8564L23.4086 14.9064L23.1771 15.1389C22.9554 15.3135 22.6757 15.4174 22.3711 15.4174Z'
        fill='#24225B'
      />
    </svg>
  );
};

export const LogoMark = (props: any) => {
  return (
    <svg
      width='16'
      height='13'
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.1073 9.00802C12.9251 8.52579 12.5031 7.60781 11.5844 6.8082C10.1087 5.5242 8.4165 5.47263 7.96986 5.47409C7.28718 5.47554 6.0315 5.60481 4.81503 6.46977C3.53393 7.38122 3.03136 8.58752 2.86868 9.03634H4.88547C5.04888 8.75165 5.38441 8.25562 5.98574 7.85182C6.89719 7.2396 7.82606 7.23887 8.17248 7.25703C9.31414 7.31804 10.0651 7.90412 10.3156 8.10529C10.7151 8.42629 10.9758 8.7669 11.1334 9.00802H13.1081H13.1073Z'
        fill='#242263'
      />
      <path
        d='M3.04081 5.19232C3.04081 5.63824 2.67914 5.99991 2.23395 5.99991C1.9921 5.99991 1.77568 5.89388 1.62825 5.72539L0.329718 4.41741C0.129999 4.26999 0 4.03323 0 3.76597C0 3.32005 0.360947 2.95911 0.806864 2.95911C0.980438 2.95911 1.14166 3.01358 1.27311 3.10726L1.46485 3.29826L2.72634 4.55395L2.86941 4.69629C2.9769 4.83283 3.04081 5.00495 3.04081 5.19232Z'
        fill='#242263'
      />
      <path
        d='M13.4487 11.892C13.4487 12.3473 13.079 12.717 12.6229 12.717H3.38215C2.92679 12.717 2.55713 12.3473 2.55713 11.892C2.55713 11.4366 2.92679 11.0662 3.38215 11.0662H12.6229C13.079 11.0662 13.4487 11.4359 13.4487 11.892Z'
        fill='#242263'
      />
      <path
        d='M8.56247 3.39558C8.24728 3.71077 7.736 3.71077 7.42081 3.39558C7.25014 3.22491 7.1717 2.99687 7.18696 2.77318L7.19349 0.929963C7.15645 0.684491 7.23199 0.425219 7.42081 0.236394C7.736 -0.078798 8.24655 -0.078798 8.56174 0.236394C8.68448 0.35913 8.76001 0.511643 8.78688 0.670692V0.941584L8.79124 2.72162V2.92352C8.77091 3.09636 8.69465 3.26267 8.56247 3.39558Z'
        fill='#242263'
      />
      <path
        d='M13.7668 5.99991C13.3209 5.99991 12.9592 5.63824 12.9592 5.19305C12.9592 4.95121 13.0652 4.73478 13.2337 4.58736L14.5417 3.28882C14.6891 3.08911 14.9259 2.95911 15.1931 2.95911C15.6391 2.95911 16 3.32005 16 3.76597C16 3.93954 15.9455 4.10077 15.8518 4.23222L15.6608 4.42395L14.4052 5.68545L14.2628 5.82852C14.1263 5.936 13.9542 5.99991 13.7668 5.99991Z'
        fill='#242263'
      />
    </svg>
  );
};

export default Logo;
