import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import { SelectInput } from '@/components/atoms/SelectInput';
import { MdDelete } from 'react-icons/md';
import { loyaltyStore } from '@/state/LoyaltyStore';

interface ActiveProps {
  type: string;
  link: string;
  text: string;
}

const ActiveLinks: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const typeOptions = [
    {
      value: 'firstName',
      label: 'First Name',
    },
    {
      value: 'lastName',
      label: 'Last Name',
    },
  ];

  const [active, setActive] = useState<ActiveProps>({
    type: '',
    link: '',
    text: '',
  });

  useEffect(() => {
    setActive(loyaltyStore.activeLinkObj);
  }, []);

  const onSubmit = () => {
    loyaltyStore.setActiveLinkObj(active);
    handleCancel();
  };

  const onClose = () => {
    setActive(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[7].value
        : {
            type: '',
            link: '',
            text: '',
          },
    );
    loyaltyStore.setActiveLinkObj(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[7].value
        : {
            type: '',
            link: '',
            text: '',
          },
    );
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='w-full mb-[24px]'>
        <div className='flex items-center mt-4'>
          <div className='flex flex-col mr-[24px] flex-1'>
            <p className='text-[16px] leading-[20px] text-gray-400 text-opacity-80'>
              Type
            </p>
            <SelectInput
              options={typeOptions}
              value={active.type}
              onChange={e => {
                setActive({
                  ...active,
                  type: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className='flex flex-col mr-[24px] flex-1'>
            <p className='text-[16px] leading-[20px] text-gray-400 text-opacity-80'>
              Link
            </p>
            <Input
              value={active.link}
              placeholder=''
              type='text'
              className='mt-2'
              onChange={e => {
                setActive({
                  ...active,
                  link: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className='flex flex-col flex-1 mr-[24px]'>
            <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80'>
              Text
            </p>

            <Input
              value={active.text}
              placeholder=''
              type='text'
              className='mt-2'
              onChange={e => {
                setActive({
                  ...active,
                  text: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className='flex mt-6'>
            <div
              className='px-1 py-1 bg-white rounded
                                shadow border border-zinc-200 justify-start
                                items-center gap-3 cursor-pointer flex'
              onClick={() => {
                setActive({
                  ...active,
                  text: '',
                  link: '',
                  type: '',
                });
              }}
            >
              <MdDelete className='w-5 h-5 text-warning' />
            </div>
          </div>
        </div>
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
export default observer(ActiveLinks);
