import { ChatSuggestionIcon } from '@/components/atoms/Icons/ChatSuggestionIcon';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Accordion from '@/components/molecules/Accordion';
import ProgramName from './Cards/ProgramName';
import ContactInfo from './Cards/ContactInfo';
import TermsOfUse from './Cards/TermsOfUse';
import PointsEarned from './Cards/PointsEarned';
import RewardsOffered from './Cards/RewardsOffered';
import NumberIssueCard from './Cards/NumberIssueCard';
import ReferralProgram from './Cards/ReferralProgram';
import NumberForReferrer from './Cards/NumberForReferrer';
import NumberForReferral from './Cards/NumberForReferral';
import ActiveLinks from './Cards/ActiveLinks';
import { ProgramIcon } from '@/components/atoms/Icons/ProgramIcon';
import { EarnIcon } from '@/components/atoms/Icons/EarnIcon';
import { ReferralIcon } from '@/components/atoms/Icons/ReferralIcon';
import { ActivelinkIcon } from '@/components/atoms/Icons/ActivelinkIcon';
import { ServiceIcon } from '@/components/atoms/Icons/ServiceIcon';
import { loyaltyStore } from '@/state/LoyaltyStore';

const Setting = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const accord1 = [
    {
      Title: 'Program Name',
      Phrase: `Lorem ipsum lorem ipsum lorem ipsum lorem`,
      selected: false,
      Content: ProgramName,
      Icon: ProgramIcon,
      section: 0,
      showToggle: false,
    },
    {
      Title: 'Points Earned',
      Phrase: 'Lorem ipsum lorem ipsum lorem ipsum lorem',
      selected: false,
      Content: PointsEarned,
      Icon: EarnIcon,
      section: 1,
      showToggle: false,
    },
    {
      Title: 'Rewards Offered',
      Phrase:
        'Eg: 1000 points gets a reward $10. You can also create reward levels like silver or gold',
      selected: false,
      Content: RewardsOffered,
      Icon: EarnIcon,
      section: 2,
      showToggle: false,
    },
  ];

  const accord2 = [
    {
      Title: 'Number of points when issuing a card',
      Phrase: 'Lorem ipsum lorem ipsum lorem opsum lorem',
      selected: false,
      Content: NumberIssueCard,
      Icon: EarnIcon,
      section: 3,
      showToggle: false,
    },
  ];

  const accord3 = [
    {
      Title: 'Referral program',
      Phrase: 'Lorem ipsum lorem ipsum lorem ipsum lorem',
      selected: false,
      Content: ReferralProgram,
      Icon: ReferralIcon,
      section: 4,
      showToggle: false,
    },
    {
      Title: 'Number of points for the referrer',
      Phrase:
        'Indicate how many points will be rewarded to the person who shared the card',
      selected: false,
      Content: NumberForReferrer,
      Icon: EarnIcon,
      section: 5,
      showToggle: false,
    },
    {
      Title: 'Number of points for the referral',
      Phrase:
        'Specify how manu points will be rewarded to the person who shared the card',
      selected: false,
      Content: NumberForReferral,
      Icon: EarnIcon,
      section: 6,
      showToggle: false,
    },
  ];

  const accord4 = [
    {
      Title: 'Active links',
      Phrase: 'Lorem ipsum lorem ipsum lorem ipsum lorem',
      selected: false,
      Content: ActiveLinks,
      Icon: ActivelinkIcon,
      section: 7,
      showToggle: false,
    },
  ];

  const accord5 = [
    {
      Title: 'Terms of use',
      Phrase: 'Lorem ipsum lorem ipsum lorem opsum lorem',
      selected: false,
      Content: TermsOfUse,
      Icon: ServiceIcon,
      section: 9,
      showToggle: true,
    },
  ];

  const accord6 = [
    {
      Title: 'Contact Information',
      Phrase: 'Lorem ipsum lorem ipsum lorem opsum lorem',
      selected: false,
      Content: ContactInfo,
      Icon: ChatSuggestionIcon,
      section: 10,
    },
  ];

  useEffect(() => {
    if (loyaltyStore.showProgramNameError) {
      setSelectedIndex(0);
      document
        .getElementById('programName')
        ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    } else if (loyaltyStore.showTermDataError) {
      setSelectedIndex(9);
      document
        .getElementById('termForm')
        ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    } else if (
      loyaltyStore.showCompanyNameError ||
      loyaltyStore.showCompanyEmailError ||
      loyaltyStore.showCompanyNoError
    ) {
      setSelectedIndex(10);
      document
        .getElementById('contactForm')
        ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [
    loyaltyStore.showProgramNameError,
    loyaltyStore.showTermDataError,
    loyaltyStore.showCompanyNameError,
    loyaltyStore.showCompanyEmailError,
    loyaltyStore.showCompanyNoError,
  ]);

  const handleTermUseTitle = () => {
    loyaltyStore.setShowTermUse(!loyaltyStore.showTermUse);
  };

  return (
    <>
      <div
        id='programName'
        className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded'
      >
        {accord1.map((item, index: number) => {
          const isLastItem = accord1.length - 1 === index;
          return (
            <>
              <Accordion
                key={item.section}
                Content={
                  <item.Content
                    handleCancel={() =>
                      selectedIndex === item.section
                        ? setSelectedIndex(null)
                        : setSelectedIndex(item.section)
                    }
                  />
                }
                Icon={<item.Icon />}
                payload={{
                  ...item,
                  index,
                  selected: selectedIndex === item.section,
                }}
                handleClick={() =>
                  selectedIndex === item.section
                    ? setSelectedIndex(null)
                    : setSelectedIndex(item.section)
                }
                containerClass={
                  loyaltyStore.showProgramNameError
                    ? 'border-darkRed  bg-lightRed'
                    : ''
                }
              />
              {!isLastItem && (
                <div className='border border-borderLight w-full h-[1px]'></div>
              )}
            </>
          );
        })}
      </div>

      <div className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'>
        {accord2.map((item, index: number) => {
          const isLastItem = accord2.length - 1 === index;
          return (
            <>
              <Accordion
                key={item.section}
                Content={
                  <item.Content
                    handleCancel={() =>
                      selectedIndex === item.section
                        ? setSelectedIndex(null)
                        : setSelectedIndex(item.section)
                    }
                  />
                }
                Icon={<item.Icon />}
                payload={{
                  ...item,
                  index,
                  selected: selectedIndex === item.section,
                }}
                handleClick={() =>
                  selectedIndex === item.section
                    ? setSelectedIndex(null)
                    : setSelectedIndex(item.section)
                }
              />
              {!isLastItem && (
                <div className='border border-borderLight w-full h-[1px]'></div>
              )}
            </>
          );
        })}
      </div>

      <div className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'>
        {accord3.map((item, index: number) => {
          const isLastItem = accord3.length - 1 === index;
          return (
            <>
              <Accordion
                key={item.section}
                Content={
                  <item.Content
                    handleCancel={() =>
                      selectedIndex === item.section
                        ? setSelectedIndex(null)
                        : setSelectedIndex(item.section)
                    }
                  />
                }
                Icon={<item.Icon />}
                payload={{
                  ...item,
                  index,
                  selected: selectedIndex === item.section,
                }}
                handleClick={() =>
                  selectedIndex === item.section
                    ? setSelectedIndex(null)
                    : setSelectedIndex(item.section)
                }
              />
              {!isLastItem && (
                <div className='border border-borderLight w-full h-[1px]'></div>
              )}
            </>
          );
        })}
      </div>

      <div className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'>
        {accord4.map((item, index: number) => {
          const isLastItem = accord4.length - 1 === index;
          return (
            <>
              <Accordion
                key={item.section}
                Content={
                  <item.Content
                    handleCancel={() =>
                      selectedIndex === item.section
                        ? setSelectedIndex(null)
                        : setSelectedIndex(item.section)
                    }
                  />
                }
                Icon={<item.Icon />}
                payload={{
                  ...item,
                  index,
                  selected: selectedIndex === item.section,
                }}
                handleClick={() =>
                  selectedIndex === item.section
                    ? setSelectedIndex(null)
                    : setSelectedIndex(item.section)
                }
              />
              {!isLastItem && (
                <div className='border border-borderLight w-full h-[1px]'></div>
              )}
            </>
          );
        })}
      </div>

      <div
        id='termForm'
        className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'
      >
        {accord5.map((item, index: number) => {
          const isLastItem = accord5.length - 1 === index;
          return (
            <>
              <Accordion
                key={item.section}
                Content={
                  <item.Content
                    handleCancel={() =>
                      selectedIndex === item.section
                        ? setSelectedIndex(null)
                        : setSelectedIndex(item.section)
                    }
                  />
                }
                Icon={<item.Icon />}
                payload={{
                  ...item,
                  index,
                  selected: selectedIndex === item.section,
                }}
                handleClick={() =>
                  selectedIndex === item.section
                    ? setSelectedIndex(null)
                    : setSelectedIndex(item.section)
                }
                containerClass={
                  loyaltyStore.showTermDataError
                    ? 'border-darkRed  bg-lightRed'
                    : ''
                }
                showToggle={item.showToggle}
                handleTermUseTitle={handleTermUseTitle}
                showTermUse={loyaltyStore.showTermUse}
              />
              {!isLastItem && (
                <div className='border border-borderLight w-full h-[1px]'></div>
              )}
            </>
          );
        })}
      </div>

      <div
        id='contactForm'
        className='rounded-2xl border border-border bg-white w-full h-auto overflow-auto px-4 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded mt-8'
      >
        {accord6.map((item, index: number) => {
          const isLastItem = accord6.length - 1 === index;
          return (
            <>
              <Accordion
                key={item.section}
                Content={
                  <item.Content
                    handleCancel={() =>
                      selectedIndex === item.section
                        ? setSelectedIndex(null)
                        : setSelectedIndex(item.section)
                    }
                  />
                }
                Icon={<item.Icon />}
                payload={{
                  ...item,
                  index,
                  selected: selectedIndex === item.section,
                }}
                handleClick={() =>
                  selectedIndex === item.section
                    ? setSelectedIndex(null)
                    : setSelectedIndex(item.section)
                }
                containerClass={
                  loyaltyStore.showCompanyNameError ||
                  loyaltyStore.showCompanyEmailError ||
                  loyaltyStore.showCompanyNoError
                    ? 'border-darkRed  bg-lightRed'
                    : ''
                }
              />
              {!isLastItem && (
                <div className='border border-borderLight w-full h-[1px]'></div>
              )}
            </>
          );
        })}
      </div>
    </>
  );
};

export default observer(Setting);
