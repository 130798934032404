import { Fragment, useState, useCallback, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Calendar from '@/components/atoms/Calendar';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';

interface DateDropdownProps {
  onSelect: (date: string) => void;
  currentDay: number;
  currentDate: string;
}

const DateDropdown = ({
  onSelect,
  currentDay,
  currentDate,
}: DateDropdownProps) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(
    currentDate?.split('-').reverse().join('/') || null,
  );
  const [day, setDay] = useState<number | null>(currentDay || null);
  const handleDateSelection = useCallback(
    (date: string) => {
      const day = parseInt(date?.split('/')[0]);
      setDay(day);
      onSelect(date);
      setSelectedDate(date);
    },
    [onSelect],
  );

  const sendTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const fullDate = `${day}/${month}/${year}`;
    onSelect(fullDate);
    setSelectedDate(fullDate);
    setDay(today.getDate());
  };

  useEffect(() => {
    //This of updating the placeholder
  }, [selectedDate]);

  return (
    <Menu as='div' className='relative inline-block text-left w-full'>
      <Menu.Button className='flex w-full justify-between gap-x-1.5 rounded-[4px] bg-white px-1 py-0.5 text-[13px] font-medium text-gray-900  hover:border-border/30  border-2 border-transparent'>
        {selectedDate || 'dd/mm/yyyy'}
        <Icon
          icon='ph:calendar-thin'
          className='-mr-1 h-5 w-5 text-gray-400'
          aria-hidden='true'
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='bg-white rounded-[10px] border border-border/50 shadow-sm absolute left-[212px] z-[1000000!important] mt-2 w-[400px] origin-top-right bottom-7  focus:outline-none'>
          <Menu.Item>
            <Calendar onSelect={handleDateSelection} selectedDay={day} />
          </Menu.Item>
          <Menu.Item>
            <div className='flex justify-between items-center px-4 pb-4'>
              <div className='text-[15px] text-secondary font-medium cursor-pointer'>
                Clear
              </div>
              <div
                onClick={sendTodayDate}
                className='text-[15px] text-secondary font-medium cursor-pointer'
              >
                Today
              </div>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default observer(DateDropdown);
