import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import { SelectInput } from '@/components/atoms/SelectInput';
import { loyaltyStore } from '@/state/LoyaltyStore';
import { FieldDefault } from '@/types/loyalty.types';
export type CustomField = {
  Reward: number;
  Balance: number;
};
const FieldOnCard: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  // for apple device
  const [field1, setField1] = useState<string>('');
  const [field2, setField2] = useState<string>('');

  const [field1Value, setField1Value] = useState<number>(0);
  const [field2Value, setField2Value] = useState<number>(0);

  const appleOptions = [
    {
      value: 'Reward',
      label: 'Reward',
    },
    {
      value: 'Balance',
      label: 'Balance',
    },
  ];

  useEffect(() => {
    setField1(Object.keys(loyaltyStore.fieldObj)[0] || '');
    setField1Value(
      loyaltyStore.fieldObj[
        Object.keys(loyaltyStore.fieldObj)[0] == 'Reward' ? 'Reward' : 'Balance'
      ] || 0,
    );

    setField2(Object.keys(loyaltyStore.fieldObj)[1] || '');
    setField2Value(
      loyaltyStore.fieldObj[
        Object.keys(loyaltyStore.fieldObj)[1] == 'Reward' ? 'Reward' : 'Balance'
      ] || 0,
    );
  }, []);

  const onClose = () => {
    const secondaryFields: FieldDefault[] =
      loyaltyStore.loyalty?.applePayload.secondaryFields || [];
    if (secondaryFields.length && getMode == 'update') {
      setField1(secondaryFields[0].label || '');
      setField1Value(secondaryFields[0].value);

      setField2(secondaryFields[1].label || '');
      setField2Value(secondaryFields[1].value);
      const obj = {
        Reward:
          secondaryFields[0].label == 'Reward'
            ? secondaryFields[0].value
            : secondaryFields[1].value,
        Balance:
          secondaryFields[0].label == 'Balance'
            ? secondaryFields[0].value
            : secondaryFields[1].value,
      };
      loyaltyStore.setFieldObj(obj);
    } else {
      setField1('');
      setField1Value(0);

      setField2('');
      setField2Value(0);

      loyaltyStore.setFieldObj({
        Reward: 0,
        Balance: 0,
      });
    }
    handleCancel();
  };

  const onSumbit = () => {
    loyaltyStore.setFieldObj({
      Reward: field1 == 'Reward' ? field1Value : field2Value,
      Balance: field2 == 'Balance' ? field2Value : field1Value,
    });
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='flex flex-row w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px] mr-[24px] flex-1'>
          <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80'>
            Field
          </p>
        </div>
        <div className='flex flex-col mt-[15px]  flex-1'>
          <p className='text-[12px] leading-[20px] text-gray-400 text-opacity-80'>
            Field name
          </p>
        </div>
      </div>
      <div className='flex flex-row w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px] mr-[24px] flex-1 mt-[9px]'>
          <SelectInput
            options={appleOptions.filter(e => e.value !== field2)}
            value={field1}
            onChange={e => {
              setField1Value(0);
              setField1(e.currentTarget.value);
            }}
          />
        </div>

        <div className='flex flex-col mt-[15px]  flex-1'>
          <Input
            value={field1Value}
            placeholder='0'
            type='text'
            disabled={field1 == ''}
            onChange={e => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setField1Value(Number(e.target.value));
              }
            }}
            maxLength={16}
          />
        </div>
      </div>

      <div className='flex flex-row w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px] mr-[24px] flex-1 mt-[9px]'>
          <SelectInput
            options={appleOptions.filter(e => e.value !== field1)}
            value={field2}
            onChange={e => {
              setField2Value(0);
              setField2(e.currentTarget.value);
            }}
          />
        </div>

        <div className='flex flex-col mt-[15px]  flex-1'>
          <Input
            value={field2Value}
            placeholder='0'
            type='text'
            disabled={field2 == ''}
            onChange={e => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setField2Value(Number(e.target.value));
              }
            }}
            maxLength={16}
          />
        </div>
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          loadingText='Saving'
          size='sm'
          className='bg-lightBlue'
          onClick={onSumbit}
        />
      </div>
    </div>
  );
};
export default observer(FieldOnCard);
