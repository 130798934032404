import EmailBuilder from '@/pages/EmailMarketing/EmailBuilder';

const EmailTemplatesBuilder = () => {
  return (
    <div className='overflow-y-auto'>
      <EmailBuilder />
    </div>
  );
};
export default EmailTemplatesBuilder;
