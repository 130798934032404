import Search from '@/components/atoms/Search';
import ActivityList from './activityList';

const Activities = () => {
  return (
    <div className='flex flex-col py-4'>
      <div className='flex justify-between mb-4'>
        <div className='relative flex items-center mr-2 sm:w-96 md:w-[500px]'>
          <Search
            placeholder='Search a Activity'
            transparent
            showCommand={false}
          />
        </div>
      </div>
      <ActivityList />
    </div>
  );
};
export default Activities;
