import React from 'react';
import WhatsApp from './whatsapp.png';
import Messenger from './messenger.png';
import Email from './mail.png';
import Chat from './chat.png';
import SMS from './phone.png';
interface IconProps {
  hieght?: number;
  width?: number;
}
export const ChatIcon: React.FC<IconProps> = () => {
  return <img src={Chat} alt='chat' className='w-[16px] h-[16px]' />;
};

export const EmailIcon: React.FC = () => {
  return <img src={Email} alt='emael' className='w-[16px] h-[16px]' />;
};

export const SMSIcon: React.FC = () => {
  return <img src={SMS} alt='sms' className='w-[16px] h-[16px]' />;
};

export const MessengerIcon: React.FC = () => {
  return <img src={Messenger} alt='messenger' className='w-[16px] h-[16px]' />;
};

export const WhatsAppIcon: React.FC = () => {
  return <img src={WhatsApp} alt='whatsapp' className='w-[16px] h-[16px]' />;
};
