import { DeviceIconProps } from '@/types/icon.type';
import { FC } from 'react';

export const AppleIcon: FC<DeviceIconProps> = ({
  className,
  width,
  height,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 30 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0441 10.6239C16.4791 10.6239 19.1698 8.62892 22.0026 8.91986C23.1882 8.97018 26.5155 9.40769 28.6527 12.5949C28.4821 12.7087 24.6801 14.964 24.7261 19.6606C24.7742 25.2738 29.5496 27.1376 29.6043 27.166C29.5605 27.2929 28.8408 29.826 27.0864 32.4423C25.5705 34.7021 23.9998 36.9508 21.5235 36.9968C19.0888 37.0449 18.3057 35.5246 15.521 35.5246C12.7384 35.5246 11.87 36.9508 9.56433 37.0449C7.17337 37.1368 5.35334 34.6036 3.82643 32.3527C0.702645 27.7435 -1.68394 19.3347 1.52079 13.658C3.11113 10.8383 5.95489 9.05331 9.0437 9.00736C11.3909 8.96362 13.6091 10.6239 15.0441 10.6239ZM22.6674 0C22.9189 2.13285 21.998 4.27443 20.6395 5.81662C19.2745 7.35665 17.0411 8.55542 14.8513 8.39353C14.556 6.30007 15.6607 4.12568 16.9207 2.75628C18.3295 1.21627 20.7074 0.0743841 22.6674 0Z'
        fill='black'
      />
    </svg>
  );
};
