import Spinner from '@/components/atoms/Spinner';
import { useOutsideClickDetector } from '@/hooks/useOutsideClickDetector';
import { ConStore } from '@/state/ConversationStore';
import { AISuggestions } from '@/types/message.type';
import { FC, useRef } from 'react';

interface AISuggestionsProps {
  suggestions: AISuggestions[];
  closeAIModal: () => void;
  handleGetAISuggestions: () => void;
  handle_send_message: () => void;
  fetchingSuggestions: boolean;
}

const AISuggestionsModal: FC<AISuggestionsProps> = ({
  suggestions,
  fetchingSuggestions,
  closeAIModal,
  handleGetAISuggestions,
  handle_send_message,
}) => {
  const parentRef = useRef(null);

  const handleSuggestionClick = (text: string) => {
    ConStore.set_text_area_value(text);
    closeAIModal();
  };
  useOutsideClickDetector(parentRef, () => {
    closeAIModal();
  });

  return (
    <div
      ref={parentRef}
      className={`bg-white flex flex-col p-4 ${
        fetchingSuggestions ? 'items-center justify-center' : ''
      }  h-[280px] max-w-[400px] min-w-[200px] shadow-lg outline outline-1 outline-gray-300 rounded-md absolute right-[50px] bottom-[75px]`}
    >
      {fetchingSuggestions ? (
        <div className='w-[300px]'>
          <Spinner size={40} />
        </div>
      ) : (
        <div>
          <div className=''>
            <p className='text-[13px] mb-2'>Ideas for you</p>
          </div>
          {suggestions.length <= 0 ? (
            <div className='w-[300px] h-full mt-[50px] flex justify-center items-center flex-col'>
              <p className='text-[13px] text-gray-375'>
                No suggestions available at this time
              </p>
            </div>
          ) : (
            <div>
              <div className='flex-col flex overflow-auto pt-1 px-1 w-[300px] max-h-[190px]'>
                {suggestions.map((suggestion: any, index: any) => (
                  <div
                    key={index}
                    onClick={() => {
                      handleSuggestionClick(suggestion?.content?.text);
                      handle_send_message();
                    }}
                    className=' cursor-pointer mb-[15px] hover:bg-[#033EB5] hover:text-white w-fit p-1 bg-gray-25 outline-1 outline outline-gray-300 rounded-sm text-[12px] text-wrap'
                  >
                    <p className='text-[12px]'>{suggestion?.content?.text}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {suggestions.length > 0 && (
            <div
              onClick={() => handleGetAISuggestions()}
              className='hover:bg-[#033EB5] group hover:text-white flex flex-row cursor-pointer mb-[15px] w-fit p-1 bg-gray-25 outline-1 outline outline-gray-300 rounded-sm text-[12px] mt-[10px]'
            >
              <svg
                className=' ml-1 mr-2 group-hover:text-white text-[#7E8B99]'
                width='20'
                height='19'
                viewBox='0 0 20 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8.71875 0.425781C8.75391 0.320312 8.85938 0.25 9 0.25C9.10547 0.25 9.21094 0.320312 9.24609 0.425781L9.73828 1.76172L11.0742 2.25391C11.1797 2.28906 11.25 2.39453 11.25 2.5C11.25 2.64062 11.1797 2.74609 11.0742 2.78125L9.73828 3.27344L9.24609 4.57422C9.21094 4.67969 9.10547 4.75 9 4.75C8.85938 4.75 8.75391 4.67969 8.71875 4.57422L8.22656 3.27344L6.89062 2.78125C6.78516 2.74609 6.75 2.64062 6.75 2.5C6.75 2.39453 6.78516 2.28906 6.89062 2.25391L8.22656 1.76172L8.71875 0.425781ZM17.4023 0.777344L18.5977 1.97266C19.2656 2.64062 19.2656 3.69531 18.5977 4.36328L5.20312 17.7578C4.53516 18.4258 3.48047 18.4258 2.8125 17.7578L1.61719 16.5625C0.949219 15.8945 0.949219 14.8398 1.61719 14.1719L15.0117 0.777344C15.6797 0.109375 16.7344 0.109375 17.4023 0.777344ZM16.207 1.9375L12.3398 5.80469L13.5703 7.03516L17.4023 3.16797L16.207 1.9375ZM4.00781 16.5625L12.375 8.23047L11.1445 7L2.8125 15.3672L4.00781 16.5625ZM0.246094 4.39844L2.25 3.625L2.98828 1.65625C3.02344 1.48047 3.19922 1.375 3.375 1.375C3.51562 1.375 3.69141 1.48047 3.72656 1.65625L4.5 3.625L6.46875 4.39844C6.64453 4.43359 6.75 4.60938 6.75 4.75C6.75 4.92578 6.64453 5.10156 6.46875 5.13672L4.5 5.875L3.72656 7.87891C3.69141 8.01953 3.51562 8.125 3.375 8.125C3.19922 8.125 3.02344 8.01953 2.98828 7.87891L2.25 5.875L0.246094 5.13672C0.0703125 5.10156 0 4.92578 0 4.75C0 4.60938 0.0703125 4.43359 0.246094 4.39844ZM12.6211 13.3984L14.625 12.625L15.3633 10.6562C15.3984 10.4805 15.5742 10.375 15.75 10.375C15.8906 10.375 16.0664 10.4805 16.1016 10.6562L16.875 12.625L18.8438 13.3984C19.0195 13.4336 19.125 13.6094 19.125 13.75C19.125 13.9258 19.0195 14.1016 18.8438 14.1367L16.875 14.875L16.1016 16.8789C16.0664 17.0195 15.8906 17.125 15.75 17.125C15.5742 17.125 15.3984 17.0195 15.3633 16.8789L14.625 14.875L12.6211 14.1367C12.4453 14.1016 12.375 13.9258 12.375 13.75C12.375 13.6094 12.4453 13.4336 12.6211 13.3984Z'
                  fill='currentColor'
                />
              </svg>
              <p className='text-[12px]'>More</p>
            </div>
          )}
        </div>
      )}

      {/* <div className='h-full w-full bg-inherit'>
        <div className='h-full w-full '>
          <Input
            placeholder='Type a message...'
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(false)}
            rightIcon={<SendMessageIcon isActive={isActive} />}
          />
        </div>
      </div> */}
    </div>
  );
};

export default AISuggestionsModal;
