import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import RadioInput from '@/components/atoms/RadioInput';
import { loyaltyStore } from '@/state/LoyaltyStore';

const ReferralProgram: FC<{ handleCancel: () => void }> = ({
  handleCancel,
}) => {
  const [referral, setReferral] = useState<number>(0);

  useEffect(() => {
    setReferral(loyaltyStore.referralProgramType);
  }, []);

  const onClose = () => {
    setReferral(
      getMode == 'update' ? loyaltyStore.loyalty?.backFields[4].value : 0,
    );
    loyaltyStore.setReferralProgramType(
      getMode == 'update' ? loyaltyStore.loyalty?.backFields[4].value : 0,
    );
    handleCancel();
  };

  const onSubmit = () => {
    loyaltyStore.setReferralProgramType(referral);
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='w-full mb-[24px]'>
        <div className='flex flex-1'>
          <RadioInput
            label=''
            key={0}
            checked={referral == 0}
            onChange={() => setReferral(0)}
          />
          <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 ml-1'>
            Active
          </p>
        </div>
      </div>

      <div className='w-full mb-[24px]'>
        <div className='flex flex-1'>
          <RadioInput
            label=''
            key={0}
            checked={referral == 1}
            onChange={() => setReferral(1)}
          />
          <p className='text-[14px] leading-[20px] text-gray-400 text-opacity-80 ml-1'>
            Inactive
          </p>
        </div>
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
export default observer(ReferralProgram);
