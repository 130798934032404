export const UploadIcon = () => {
  return (
    <svg
      width='40'
      height='28'
      viewBox='0 0 40 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.9375 8.9375C19.5 8.375 20.4375 8.375 21 8.9375L26 13.9375C26.625 14.5625 26.625 15.5 26 16.0625C25.4375 16.6875 24.5 16.6875 23.9375 16.0625L21.5 13.625V22C21.5 22.875 20.8125 23.5 20 23.5C19.125 23.5 18.5 22.875 18.5 22V13.625L16 16.0625C15.4375 16.6875 14.5 16.6875 13.9375 16.0625C13.3125 15.5 13.3125 14.5625 13.9375 13.9375L18.9375 8.9375ZM9 28C4 28 0 24 0 19C0 15.125 2.5 11.75 6 10.5625V10.5C6 4.75 10.6875 0 16.5 0C19.625 0 22.5 1.4375 24.4375 3.6875C25.375 3.25 26.375 3 27.5 3C31.625 3 35 6.375 35 10.5C35 10.9375 34.9375 11.3125 34.875 11.75C37.875 13.0625 40 16.0625 40 19.5C40 24.0625 36.4375 27.75 32 28H9ZM16.5 3C12.3125 3 9 6.375 9 10.5V12.6875L6.9375 13.375C4.625 14.1875 3 16.4375 3 19C3 22.3125 5.625 25 9 25H31.625H31.8125C34.6875 24.875 37 22.4375 37 19.5C37 17.25 35.625 15.3125 33.6875 14.5L31.5625 13.5625L31.9375 11.25C31.9375 11 32 10.75 32 10.5C32 8.0625 29.9375 6 27.5 6C26.8125 6 26.1875 6.1875 25.625 6.4375L23.625 7.3125L22.125 5.625C20.75 4.0625 18.75 3 16.4375 3H16.5Z'
        fill='#7E8B99'
      />
    </svg>
  );
};
