import { BsFilter } from 'react-icons/bs';
import Button from '@/components/atoms/Button';
import { filterOperators } from '@/constants/index';
import { User } from '@/types/user.types';
import { Menu, Transition } from '@headlessui/react';
import { FC, Fragment, useState } from 'react';
import { FilterPicker } from '@/components/molecules/FilterPicker';
import { OperatorPicker } from '@/components/molecules/OperatorPicker';

type FilterValue = {
  field: keyof User;
  operator: (typeof filterOperators)[number]['value'];
  value: string;
};

interface FilterProps {
  onFilter: (data: FilterValue) => void;
  count?: number;
}

export const Filter: FC<FilterProps> = ({ onFilter }) => {
  const [values, setValues] = useState<FilterValue>({
    field: 'user_name',
    operator: 'equals',
    value: '',
  });
  const onSelectOperator = (index: number) => {
    setValues({ ...values, operator: filterOperators[index].value });
  };

  const onFilterValue = (option: { label: string; value: keyof User }) => {
    setValues({ ...values, field: option.value });
  };

  return (
    <Menu as='div' className='relative'>
      {({ open }) => (
        <>
          <Menu.Button
            className={`w-10 h-10 bg-mainGray/10 border border-border rounded-[4px] cursor-pointer flex items-center justify-center`}
          >
            <BsFilter className='text-neutral-700 h-5 w-5' />
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Menu.Items
              className='absolute  z-10 mt-2 -left-[200px]  origin-top-right rounded-[4px] bg-white shadow-lg
                    ring-1 ring-border focus:outline-none flex flex-col  items-center p-4 gap-y-4 w-[300px]'
            >
              <div className='flex space-x-4 justify-between w-full'>
                <FilterPicker onSelect={onFilterValue} />
                <OperatorPicker onSelect={onSelectOperator} right={0} />
              </div>
              <div className='flex space-x-4 justify-between w-full '>
                <input
                  placeholder='value to'
                  type='search'
                  className='py-0.5 outline-none border-b border-border w-full'
                  style={{ borderRadius: '0' }} // Add this line to override default border-radius
                  onKeyDown={e => e.stopPropagation()}
                  onChange={({ target }) => {
                    setValues({ ...values, value: target.value });
                  }}
                />

                <Button
                  text='Filter'
                  variant='outline'
                  onClick={() => onFilter(values)}
                />
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
