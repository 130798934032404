import { fetchRequestWithToken } from '@/config/axios';
import { CardIssueProps, LoyaltyProps } from '@/types/loyalty.types';
import { notify } from '@/helpers/index';
import { loyaltyStore } from '@/state/LoyaltyStore';

interface createTrackPassProps {
  dePassKitId: string;
  metaData: CardIssueProps[];
}

interface enableNotifyProps {
  FCMToken: string;
}

interface sendNotificationProps {
  dePassKitId: string;
  notificationTitle: string;
}

interface passDownloadForHomeProps {
  pushToken: string;
}

export const useLoyalty = () => {
  const createLoyalty = (data: LoyaltyProps, cd: any) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/de-pass-kit`,
      method: 'POST',
      data,
    })
      .then(response => {
        if (response.data.error) {
          notify('error', response.data.error);
          return;
        }
        notify('success', 'Created successfully');
        loyaltyStore.setLoyalty(response.data.data);
        cd(response.data.data);
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      })
      .finally(() => {
        //..
      });
  };

  const updateLoyalty = (id: string, data: LoyaltyProps, cd: any) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/de-pass-kit/${id}`,
      method: 'POST',
      data,
    })
      .then(response => {
        if (response.data.error) {
          notify('error', response.data.error);
          return;
        }
        notify('success', 'Updated successfully');
        loyaltyStore.setLoyalty(response.data.data);
        cd(response.data.data);
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      })
      .finally(() => {
        //..
      });
  };

  const getLoyalty = (id: string, cd: any) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/de-pass-kit/${id}`,
      method: 'GET',
    })
      .then(response => {
        if (response.data.error) {
          cd(response.data);
        }
        if (response.data.data) {
          cd(response.data);
          loyaltyStore.setLoyalty(response.data.data);
        }
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      })
      .finally(() => {
        //..
      });
  };

  const getCardType = (type: string) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/de-pass-kit?type=${type}`,
      method: 'GET',
    })
      .then(response => {
        if (response.data.error) {
          notify('error', response.data.error);
          return;
        }
        loyaltyStore.setCardTypeList(response.data.data);
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      })
      .finally(() => {
        //..
      });
  };

  const createTrackPass = (data: createTrackPassProps, cd: any) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/track-pass`,
      method: 'POST',
      data,
    })
      .then(res => {
        cd(res.data);
        // notify('success', res.data.message);
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      })
      .finally(() => {
        //..
      });
  };

  const passDownloadForHome = async (
    id: string,
    data: passDownloadForHomeProps,
    cd: any,
  ) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/pass-download/home/${id}`,
      method: 'POST',
      data,
    })
      .then(response => {
        if (response.data.error) {
          notify('error', response.data.error);
          return;
        }
        if (response.data) {
          cd(response.data);
        }
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      })
      .finally(() => {
        //..
      });
  };

  const passDownloadForGoogle = (id: string, cd: any) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/pass-download/google/${id}`,
      method: 'GET',
    })
      .then(response => {
        if (response.data.error) {
          notify('error', response.data.error);
          return;
        }
        if (response.data) {
          cd(response.data);
        }
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      })
      .finally(() => {
        //..
      });
  };

  const passDownloadForApple = (id: string, cd: any) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/pass-download/apple/${id}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => {
        if (response.data.error) {
          notify('error', response.data.error);
          return;
        }
        if (response.data) {
          cd(response.data);
        }
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      })
      .finally(() => {
        //..
      });
  };

  const enableNotification = async (id: string, data: enableNotifyProps) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/track-pass/${id}/enable-notification`,
      method: 'POST',
      data,
    })
      .then(res => {
        return res.data;
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      });
  };

  const sendNotification = (data: sendNotificationProps, cd: any) => {
    fetchRequestWithToken({
      url: `/api/sticky-cards/de-pass-kit/send-notification`,
      method: 'POST',
      data,
    })
      .then(res => {
        if (res.data.error) {
          notify('error', res.data.error);
          return;
        }
        notify('success', res.data.message);
        cd(res.data);
      })
      .catch(() => {
        notify('error', 'Something went wrong');
      });
  };

  return {
    createLoyalty,
    updateLoyalty,
    getLoyalty,
    getCardType,
    createTrackPass,
    passDownloadForHome,
    passDownloadForGoogle,
    passDownloadForApple,
    enableNotification,
    sendNotification,
  };
};
