import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import { loyaltyStore } from '@/state/LoyaltyStore';

const NumberForReferrer: FC<{ handleCancel: () => void }> = ({
  handleCancel,
}) => {
  const [referrer, setReferrer] = useState<number>(0);

  useEffect(() => {
    setReferrer(loyaltyStore.numberForReferrer);
  }, []);

  const onClose = () => {
    setReferrer(
      getMode == 'update' ? loyaltyStore.loyalty?.backFields[5].value : 0,
    );
    loyaltyStore.setNumberForReferrer(
      getMode == 'update' ? loyaltyStore.loyalty?.backFields[5].value : 0,
    );
    handleCancel();
  };

  const onSubmit = () => {
    loyaltyStore.setNumberForReferrer(referrer);
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px]  flex-1'>
          <Input
            value={referrer}
            placeholder='0'
            type='text'
            onChange={e => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setReferrer(Number(e.target.value));
              }
            }}
            maxLength={16}
          />
        </div>
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
export default observer(NumberForReferrer);
