import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import { SelectInput } from '@/components/atoms/SelectInput';
import { CreateCustomFieldPayload } from '@/hooks/useCustomField';
import { customFieldStore } from '@/state/CustomFieldStore';
import { CustomField } from '@/types/customField.types';
import { Form, Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import * as yup from 'yup';

const addCustomFieldScheme = yup.object().shape({
  field: yup.string().trim().required('Name is required'),
  type: yup.string().trim().required('Type is required'),
  metaData: yup.array().when('type', {
    is: 'select',
    then: schema =>
      schema
        .min(1, 'At least one option is required')
        .of(yup.string().required('Option is required')),
    otherwise: schema => schema.notRequired(),
  }),
});

type CustomFieldType = {
  type: string;
};

type CustomFieldFormProps = {
  handleSubmit: (values: CreateCustomFieldPayload) => void;
  customFieldType?: CustomFieldType[];
  updateCustomFieldData?: CustomField | null;
};

type FormValues = {
  field: string;
  type: string;
  metaData: string[];
};

const CustomFieldForm: FC<CustomFieldFormProps> = ({
  handleSubmit: onSubmit,
  customFieldType,
  updateCustomFieldData,
}) => {
  const [options, setOptions] = useState<string[]>(
    updateCustomFieldData?.metaData || [''],
  );

  return (
    <Formik<FormValues>
      initialValues={{
        field: updateCustomFieldData?.field ?? '',
        type: updateCustomFieldData?.type ?? '',
        metaData: updateCustomFieldData?.metaData ?? [],
      }}
      validationSchema={addCustomFieldScheme}
      onSubmit={(values, formikHelpers: FormikHelpers<FormValues>) => {
        const finalValues = { ...values, metaData: options.filter(Boolean) };
        onSubmit(finalValues as CreateCustomFieldPayload);
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
      }}
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        validateForm,
        isSubmitting,
      }) => {
        const handleAddOption = () => {
          const newOptions = [...options, ''];
          setOptions(newOptions);
          setFieldValue('metaData', newOptions);
        };

        const handleOptionChange = (index: number, value: string) => {
          const newOptions = [...options];
          newOptions[index] = value;
          setOptions(newOptions);
          setFieldValue('metaData', newOptions);
        };

        const handleRemoveOption = (index: number) => {
          const newOptions = options.filter((_, i) => i !== index);
          setOptions(newOptions);
          setFieldValue('metaData', newOptions);
        };

        return (
          <Form className='px-5'>
            <Input
              placeholder='Field Name'
              name='field'
              value={values.field}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.field && errors.field ? errors.field : undefined}
              label='Field Name'
            />

            <div className='mt-3' />

            <SelectInput
              name='type'
              value={values.type}
              options={
                customFieldType?.map(type => ({
                  label:
                    type.type === 'select'
                      ? 'PICK LIST'
                      : type.type.toUpperCase(),
                  value: type.type,
                })) ?? []
              }
              onChange={async (e: React.ChangeEvent<HTMLSelectElement>) => {
                const { value } = e.target;
                await setFieldValue('type', value);
                setFieldTouched('type', true, false);
                if (value !== 'select') {
                  await setFieldValue('metaData', []);
                  setOptions([]);
                } else {
                  await setFieldValue('metaData', ['']);
                  setOptions(['']);
                }
                validateForm();
              }}
              onBlur={handleBlur}
              label='Field Type'
              placeholder='Select Field Type'
              disabled={!!updateCustomFieldData?.field}
              error={touched.type && errors.type ? errors.type : undefined}
            />

            {values.type === 'select' && (
              <div className='my-5'>
                {options.map((option, index) => (
                  <div key={index} className='mb-5 flex items-center'>
                    <Input
                      value={option}
                      placeholder={`Option ${index + 1}`}
                      onChange={e => handleOptionChange(index, e.target.value)}
                      onBlur={async () => {
                        await setFieldTouched(
                          `metaData[${index}]`,
                          true,
                          false,
                        );
                        validateForm();
                      }}
                      error={
                        touched.metaData &&
                        Array.isArray(errors.metaData) &&
                        errors.metaData[index]
                          ? errors.metaData[index]
                          : undefined
                      }
                    />
                    {index > 0 && (
                      <button
                        type='button'
                        onClick={() => handleRemoveOption(index)}
                        className='ml-2 text-red-500'
                      >
                        <AiOutlineClose />
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type='button'
                  className='text-secondary text-md'
                  onClick={handleAddOption}
                >
                  + Add Option
                </button>
              </div>
            )}

            <div className='mt-4 text-center'>
              <Button
                disabled={isSubmitting || Object.keys(errors).length > 0}
                loading={isSubmitting || customFieldStore.loading}
                type='submit'
                className='mt-3 w-full'
                variant='primary'
                size='md'
                text={updateCustomFieldData ? 'Update' : 'Create'}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(CustomFieldForm);
