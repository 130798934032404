import Device from '../../../../assets/images/iPhone14.png';

import { observer } from 'mobx-react-lite';
import { loyaltyStore } from '@/state/LoyaltyStore';

function NotificationDevices() {
  const myStyle = {
    backgroundImage: `url(${Device})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <div>
        <div style={myStyle} className='flex w-[324.48px] h-[643px]  relative'>
          <div className='ml-[35px] mt-[150px]'>
            <div className='rounded-[5px]  bg-gray-600 shadow-2xl w-[252px] py-3 px-4'>
              <div className='flex justify-between'>
                <div className='flex  items-center'>
                  <img
                    src={loyaltyStore.notificationLogo}
                    height={18}
                    width={18}
                  />
                  <p className='text-[12px] leading-[20px] font-[200] text-white text-opacity-80 ml-2'>
                    {loyaltyStore.cardName || '-'}
                  </p>
                </div>
                <p className='text-[12px] leading-[20px] font-[400] text-white text-opacity-80'>
                  now
                </p>
              </div>
              <div>
                <p className='text-[13px] leading-[20px] font-[400] text-white text-opacity-80 mt-2'>
                  {loyaltyStore.notifyMsg ||
                    'Push text in a side preview of the service'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(NotificationDevices);
