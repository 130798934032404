import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import { SelectInput } from '@/components/atoms/SelectInput';
import { loyaltyStore } from '@/state/LoyaltyStore';

interface RewardProps {
  name: string;
  pointEarn: string;
  type: string;
  value: string;
}

const RewardsOffered: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const typeOptions = [
    {
      value: 'off',
      label: 'Order ($OFF)',
    },
    {
      value: 'on',
      label: 'Order ($ON)',
    },
  ];

  const valueOptions = [
    {
      value: '25.00',
      label: '25.00',
    },
    {
      value: '50.00',
      label: '50.00',
    },
  ];

  const initialstate = [
    {
      name: '',
      pointEarn: '',
      type: '',
      value: '',
    },
    {
      name: '',
      pointEarn: '',
      type: '',
      value: '',
    },
    {
      name: '',
      pointEarn: '',
      type: '',
      value: '',
    },
    {
      name: '',
      pointEarn: '',
      type: '',
      value: '',
    },
    {
      name: '',
      pointEarn: '',
      type: '',
      value: '',
    },
  ];

  const [rewardObj, setRewardObj] = useState<RewardProps[]>(initialstate);

  useEffect(() => {
    setRewardObj(loyaltyStore.rewardOfferedObj);
  }, []);

  const handleChange = (value: string, type: string, index: number) => {
    const newArray = [...rewardObj];
    newArray[index] = { ...newArray[index], [type]: value };
    setRewardObj(newArray);
  };

  const onClose = () => {
    setRewardObj(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[2].value
        : initialstate,
    );
    loyaltyStore.setRewardOfferedObj(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[2].value
        : initialstate,
    );
    handleCancel();
  };

  const onSubmit = () => {
    loyaltyStore.setRewardOfferedObj(rewardObj);
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      {rewardObj.map((reward, index) => {
        return (
          <div className='w-full mb-[24px]' key={index}>
            <p className='text-[14px] leading-[20px] text-black'>
              {index + 1}.Reward level name
            </p>

            <div>
              <Input
                value={reward.name}
                placeholder='Reward level name'
                type='text'
                className='mt-2'
                onChange={e => {
                  handleChange(e.target.value, 'name', index);
                }}
              />
            </div>

            <div className='flex items-center mt-4'>
              <div className='flex flex-col flex-1 mr-[24px]'>
                <p className='text-[12px] leading-[20px] text-black'>
                  Reward points earned
                </p>
                <Input
                  value={reward.pointEarn}
                  placeholder='25 Points'
                  type='text'
                  className='mt-2'
                  maxLength={16}
                  onChange={e => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      handleChange(e.target.value, 'pointEarn', index);
                    }
                  }}
                />
              </div>

              <div className='mr-[24px] mt-2'> = </div>

              <div className='flex flex-col flex-1 mr-[24px]'>
                <p className='text-[12px] leading-[20px] text-black'>
                  Reward Type
                </p>

                <SelectInput
                  options={typeOptions}
                  value={reward.type}
                  onChange={e => {
                    handleChange(e.currentTarget.value, 'type', index);
                  }}
                />
              </div>

              <div className='flex flex-col flex-1 '>
                <p className='text-[12px] leading-[20px] text-black'>
                  Reward Value
                </p>

                <SelectInput
                  options={valueOptions}
                  value={reward.value}
                  onChange={e => {
                    handleChange(e.currentTarget.value, 'value', index);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
export default observer(RewardsOffered);
