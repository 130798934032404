import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import { loyaltyStore } from '@/state/LoyaltyStore';

const CardDescr: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const [description, setDescr] = useState<string>('');
  const [descriptionError, setDescrError] = useState<string>('');

  useEffect(() => {
    setDescr(loyaltyStore.cardDescr);
  }, []);

  const onSubmit = () => {
    if (description.trim() == '') {
      setDescrError('Card description is required');
    } else {
      loyaltyStore.setCardDescr(description.trim());
      handleCancel();
    }
  };

  const onClose = () => {
    setDescr(getMode == 'update' ? loyaltyStore.loyalty?.dePassDesc || '' : '');
    loyaltyStore.setCardDescr(
      getMode == 'update' ? loyaltyStore.loyalty?.dePassDesc || '' : '',
    );
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px]  flex-1'>
          <Input
            value={description}
            placeholder='Card Description'
            type='text'
            onChange={e => {
              setDescr(e.target.value);
              loyaltyStore.setShowCardDescripError(false);
            }}
          />
        </div>
        {descriptionError && (
          <p className='mb-[6px] mt-1' style={{ color: 'red' }}>
            {descriptionError}
          </p>
        )}
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};
export default observer(CardDescr);
