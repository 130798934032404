import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ChevronDown,
  Search,
  Star,
  HelpCircle,
  Trash2,
  AlertTriangle,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Checkbox } from '@/components/ui/checkbox';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from '@/components/ui/tooltip';
import { useNavigate } from 'react-router-dom';

type Recipient = {
  id: string;
  name: string;
  count: number;
  starred?: boolean;
};

type UTMParameter = {
  id: string;
  name: string;
  value: string;
};

const Recipients = () => {
  const navigate = useNavigate();
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const [excludedRecipients, setExcludedRecipients] = useState<string[]>([]);
  const [utmTracking, setUtmTracking] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [estimatedCount, setEstimatedCount] = useState(0);
  const [utmParameters, setUtmParameters] = useState<UTMParameter[]>([
    { id: '1', name: 'Source (utm_source)', value: 'Klaviyo' },
    { id: '2', name: 'Medium (utm_medium)', value: 'campaign' },
    { id: '3', name: 'Campaign (utm_campaign)', value: 'Campaign name' },
    { id: '4', name: 'Id (utm_id)', value: 'Campaign id' },
    { id: '5', name: 'Term (utm_term)', value: 'Value' },
  ]);
  const [showPersonalDataWarning, setShowPersonalDataWarning] = useState(false);

  useEffect(() => {
    // Fetch recipients (mock data for now)
    setRecipients([
      { id: '1', name: 'SMS Subscribers', count: 0 },
      { id: '2', name: 'Newsletter', count: 1, starred: true },
      { id: '3', name: 'Preview List', count: 1 },
      { id: '4', name: 'BEST CANNABIST TEST', count: 323 },
      { id: '5', name: 'BEST CANNABIS_2', count: 209 },
    ]);
  }, []);

  useEffect(() => {
    // Calculate estimated count based on selected recipients and excluded recipients
    const count = recipients
      .filter(
        r =>
          selectedRecipients.includes(r.id) &&
          !excludedRecipients.includes(r.id),
      )
      .reduce((sum, r) => sum + r.count, 0);
    setEstimatedCount(count);
  }, [selectedRecipients, excludedRecipients, recipients]);

  const filteredRecipients = recipients.filter(r =>
    r.name.toLowerCase().includes(filterQuery.toLowerCase()),
  );

  const RecipientSelector = ({
    value,
    onChange,
    placeholder,
  }: {
    value: string[];
    onChange: (value: string[]) => void;
    placeholder: string;
  }) => (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={false}
          className='w-full justify-between'
        >
          {value.length > 0
            ? `${value.length} list${value.length > 1 ? 's' : ''} selected`
            : placeholder}
          <ChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[400px] p-0'>
        <div className='p-2'>
          <div className='flex items-center border rounded-md'>
            <Search className='w-4 h-4 ml-2 text-gray-400' />
            <Input
              placeholder='Filter'
              value={filterQuery}
              onChange={e => setFilterQuery(e.target.value)}
              className='border-0 focus-visible:ring-0 focus-visible:ring-offset-0'
            />
          </div>
        </div>
        <div className='max-h-[300px] overflow-auto'>
          {filteredRecipients.map(recipient => (
            <div
              key={recipient.id}
              className='flex items-center space-x-2 p-2 hover:bg-gray-100'
            >
              <Checkbox
                id={recipient.id}
                checked={value.includes(recipient.id)}
                onCheckedChange={(checked: any) => {
                  onChange(
                    checked
                      ? [...value, recipient.id]
                      : value.filter(id => id !== recipient.id),
                  );
                }}
              />
              <label
                htmlFor={recipient.id}
                className='flex items-center justify-between w-full text-md font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
              >
                <span className='flex items-center'>
                  {recipient.name} ({recipient.count})
                </span>
                {recipient.starred && (
                  <Star className='w-4 h-4 text-yellow-400' />
                )}
              </label>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );

  const getTooltipContent = (paramName: string) => {
    const tooltips: Record<string, string> = {
      'Source (utm_source)':
        "<strong>Use utm_source to identify where the traffic is coming from.</strong><br/>This is usually the website or platform that links to your content, such as 'Google', 'Facebook', or 'Newsletter'.",
      'Medium (utm_medium)':
        "<strong>Use utm_medium to specify the type of marketing channel.</strong><br/>Examples include 'email', 'cpc' (cost-per-click), 'social', or 'display'. This helps you understand which channel drove the traffic.",
      'Campaign (utm_campaign)':
        "<strong>Use utm_campaign to identify the specific campaign that is driving the traffic.</strong><br/>For example, 'holiday_sale' or 'spring_launch'. This helps you track the performance of different campaigns.",
      'Id (utm_id)':
        '<strong>Use utm_id as a unique identifier for the campaign.</strong><br/>This is especially helpful for differentiating similar campaigns or tracking ads individually.',
      'Term (utm_term)':
        "<strong>Use utm_term to track keywords for paid search campaigns.</strong><br/>This helps you see which keywords are performing best. Example: 'running_shoes'.",
      custom:
        "<strong>Add a custom parameter to track additional details unique to your campaign.</strong><br/>For example, 'product_variant' or 'region'.",
    };
    return tooltips[paramName] || tooltips['custom'];
  };

  const handleAddUTMParameter = () => {
    setUtmParameters([...utmParameters, { id: 'custom', name: '', value: '' }]);
  };

  const handleRemoveUTMParameter = (id: string) => {
    setUtmParameters(utmParameters.filter(param => param.id !== id));
  };

  const handleUTMParameterChange = (
    id: string,
    field: 'name' | 'value',
    value: string,
  ) => {
    setUtmParameters(
      utmParameters.map(param =>
        param.id === id ? { ...param, [field]: value } : param,
      ),
    );
  };

  return (
    <div className='max-w-4xl mx-auto p-6 mb-8'>
      <div className='flex justify-between items-center mb-6'>
        <h1 className='text-2xl font-bold text-secondary'>
          Email Campaign - Oct 27, 2024
        </h1>
        <div className='flex items-center gap-2'>
          <Button variant='outline' onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button className='bg-secondary text-white'>Next</Button>
        </div>
      </div>

      <Card className='mb-6'>
        <CardHeader>
          <CardTitle className='text-secondary'>Audience</CardTitle>
        </CardHeader>
        <CardContent>
          <div className='space-y-4'>
            <div>
              <Label htmlFor='recipientList'>Send to</Label>
              <RecipientSelector
                value={selectedRecipients}
                onChange={setSelectedRecipients}
                placeholder='Select a list or segment'
              />
            </div>

            <div>
              <Label htmlFor='excludedList'>Don&apos;t send to</Label>
              <RecipientSelector
                value={excludedRecipients}
                onChange={setExcludedRecipients}
                placeholder='Select a list or segment'
              />
            </div>
            <div className='mt-4 p-4 bg-gray-100 rounded-lg'>
              <div className='flex items-center justify-between mb-2'>
                <span className='font-semibold'>Estimated recipient count</span>
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <HelpCircle className='h-4 w-4 text-gray-400' />
                    </TooltipTrigger>
                    <TooltipContent className='absolute right-[10px] w-[330px] h-[50px]'>
                      <p>
                        This is the estimated number of recipients based on your
                        selected and excluded lists.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div className='text-4xl font-bold'>{estimatedCount}</div>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className='mb-6'>
        <CardHeader>
          <CardTitle className='text-blue-900'>Tracking</CardTitle>
        </CardHeader>
        <CardContent>
          <div className='space-y-4'>
            <div className='flex items-center space-x-2'>
              <Switch
                id='utm-tracking'
                checked={utmTracking}
                onCheckedChange={setUtmTracking}
                className='data-[state=checked]:bg-secondary'
              />
              <Label htmlFor='utm-tracking'>Enable UTM Tracking</Label>
            </div>
            <p className='text-md text-gray-500'>
              Links will include additional tracking information, called UTM
              parameters. This allows source tracking within third-party
              reporting tools such as Google Analytics.
            </p>

            {utmTracking && (
              <>
                <Alert
                  variant='default'
                  className='bg-yellow-50 border-yellow-100'
                >
                  <AlertTriangle className='h-4 w-4' />
                  <AlertDescription className='flex items-center justify-between'>
                    <span>Don&apos;t use personal data.</span>
                    <Button
                      variant='link'
                      className='h-auto p-0 text-secondary'
                      onClick={() =>
                        setShowPersonalDataWarning(!showPersonalDataWarning)
                      }
                    >
                      {showPersonalDataWarning ? 'Less' : 'Learn more'}
                    </Button>
                  </AlertDescription>
                </Alert>

                {showPersonalDataWarning && (
                  <Alert
                    variant='default'
                    className='bg-yellow-50 border-yellow-100'
                  >
                    <AlertDescription>
                      <p className='mb-2 text-md'>
                        In order to protect the privacy of your customers, do
                        not use any profile properties that include personal
                        data as custom tracking parameters.
                      </p>
                      <p className='mb-2 text-md'>
                        <strong>What is personal data?</strong>
                      </p>
                      <p className='text-md'>
                        Personal data includes things like names, addresses,
                        email addresses, or any similar data, including any
                        sensitive data or &quot;special categories&quot; of data
                        under applicable laws (like sexual orientation, gender
                        identity, religion, race, ethnicity, health data,
                        government ID number, etc.).
                      </p>
                    </AlertDescription>
                  </Alert>
                )}

                <div className='space-y-2'>
                  {utmParameters.map(param => (
                    <div
                      key={param.id}
                      className='grid grid-cols-[auto_1fr_1fr_auto] gap-2 items-center'
                    >
                      <Checkbox
                        id={`utm-${param.id}`}
                        checked={param.name !== '' || param.value !== ''}
                        onCheckedChange={(checked: any) => {
                          if (!checked) {
                            handleUTMParameterChange(param.id, 'name', '');
                            handleUTMParameterChange(param.id, 'value', '');
                          }
                        }}
                      />
                      <div className='relative'>
                        <Input
                          placeholder='UTM parameter'
                          value={param.name}
                          onChange={e =>
                            handleUTMParameterChange(
                              param.id,
                              'name',
                              e.target.value,
                            )
                          }
                          className='pr-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                        />
                        <TooltipProvider>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                              <Button
                                variant='ghost'
                                size='icon'
                                className='absolute right-1 top-1/2 -translate-y-1/2'
                              >
                                <HelpCircle className='h-4 w-4' />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p
                                className='max-w-xs'
                                dangerouslySetInnerHTML={{
                                  __html: getTooltipContent(param.name),
                                }}
                              />
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      <Input
                        placeholder='Value'
                        value={param.value}
                        onChange={e =>
                          handleUTMParameterChange(
                            param.id,
                            'value',
                            e.target.value,
                          )
                        }
                        className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                      />
                      {param.id === 'custom' && (
                        <Button
                          variant='ghost'
                          size='icon'
                          onClick={() => handleRemoveUTMParameter(param.id)}
                        >
                          <Trash2 className='h-4 w-4' />
                        </Button>
                      )}
                    </div>
                  ))}
                </div>

                <Button variant='outline' onClick={handleAddUTMParameter}>
                  Add UTM parameter
                </Button>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default observer(Recipients);
