import two from './imgs/two.png';

function Management() {
  return (
    <div className='lg:my-[150px] mt-24'>
      <div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
        <div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2'>
          <div className='mt-6'>
            <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
              All-in-one customer engagement and sales pipeline management
            </h2>
            <p className='mt-4 text-lg text-gray-500'>
              Are you tired of using multiple tools to manage your customer
              engagement and sales pipeline? Look no further than SparkHub - the
              ultimate all-in-one solution that combines the power of LiveChat
              and CRM in one seamless platform.
            </p>
          </div>
        </div>
        <div className='mt-12 sm:mt-16 lg:mt-0 lg:col-start-1'>
          <div className='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
            <img
              className='w-full rounded-xl shadow-xl ring-1 ring-gray-50 ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none'
              src={two}
              alt='Customer profile user interface'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Management;
