import { makeAutoObservable } from 'mobx';

type DomainVerificationStatus =
  | 'awaiting-submission'
  | 'pending'
  | 'verified'
  | 'failed';

export enum EmailCampaignFilter {
  ALL = 'All',
  DRAFT = 'Draft',
  SENT = 'Sent',
  ARCHIVED = 'Archived',
  SCHEDULED = 'Scheduled',
}

type DomainRecordType = 'TXT' | 'MX' | 'CNAME';

type DomainRecord = {
  type: DomainRecordType;
  key: string;
  value: string;
  priority: string;
};

type SetupResponse = {
  domain: string;
  verified: boolean;
  status: string;
  senderName: string;
  domainRecords: DomainRecord[];
};
type Template = {
  _id: string;
  html: any;
  name: string;
  thumbnail: string;
  json: string;
};
type Campaign = {
  clickCount: number;
  createdAt: string;
  goals: any;
  campaigns: any;
  listId: string;
  openCount: number;
  sendAt: string;
  scheduledDate: string;
  type: string;
  sentCount: number;
  status: EmailCampaignFilter;
  template: string;
  templateId: string;
  title: string;
  updatedAt: string;
  workspace: string;
  _id: string;
};

type EmailMarketingStats = {
  sent: number;
  opened: number;
  clicked: number;
};

type CampaignStatusStats = {
  label: EmailCampaignFilter;
  count: number;
  isActive?: boolean;
}[];

class EmailMarketingStore {
  constructor() {
    makeAutoObservable(this);
  }

  isReady = false;
  loading = false;
  showSpinner = false;
  sendingTestEmail = false;
  verificationStatus: DomainVerificationStatus = 'awaiting-submission';
  setupResponse: SetupResponse | null = null;
  templates: Template[] | null = null;
  template: Template | null = null;
  campaigns: Campaign[] = [];
  campaign: Campaign | null = null;
  campaignStatusStats: CampaignStatusStats = [];

  emailMarketingStats: EmailMarketingStats = {
    sent: 0,
    opened: 0,
    clicked: 0,
  };
  setReady() {
    this.isReady = true;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setShowSpinner(showSpinner: boolean) {
    this.showSpinner = showSpinner;
  }

  setEmailCampaignStatusStats(stats: CampaignStatusStats) {
    this.campaignStatusStats = stats;
  }

  setVerificationStatus(status: DomainVerificationStatus) {
    this.verificationStatus = status;
  }

  setSetupResponse(response: SetupResponse | null) {
    this.setupResponse = response;
  }
  setTemplates(templates: Template[] | null) {
    this.templates = templates;
  }
  setTemplate(template: Template | null) {
    this.template = template;
  }
  setCampaigns(campaigns: Campaign[]) {
    this.campaigns = campaigns;
  }
  setCampaign(campaign: Campaign | null) {
    this.campaign = campaign;
  }

  setEmailMarketingStats(stats: EmailMarketingStats) {
    this.emailMarketingStats = stats;
  }
  setSendingTestEmail(state: boolean) {
    this.sendingTestEmail = state;
  }
}

export const emailMarketingStore = new EmailMarketingStore();
