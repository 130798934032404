import { observer } from 'mobx-react-lite';
import Logo from '@/components/atoms/Logo';
import ForgotPasswordForm from '@/components/templates/forms/ForgotPasswordForm';
import { useAuth } from '@/hooks/useAuth';
import { AuthStore } from '@/state/AuthenticationStore';
import routeNames from '@/routes/routeNames';
import { Link } from 'react-router-dom';

const SuccessMsg = () => {
  return (
    <div className='flex flex-col items-center justify-center py-[30px]'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='w-[50px] h-[50px] text-[#1bb157]'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 0 0118 0z'
        />
      </svg>
      <p className='text-[#1bb157] text-[14px] leading-[18px]'>
        Your email has been sent
      </p>
    </div>
  );
};

export const ForgotPassword = observer(() => {
  const { passwordResetSuccess } = AuthStore;
  const { forgotPassword } = useAuth();

  return (
    <div className='h-screen flex justify-center items-center bg-white'>
      <div className='w-[500px] px-5 rounded-md pb-5 pt-3'>
        <div className='flex flex-col items-center justify-center mb-6'>
          <Link to={'/'} className='flex flex-col items-center justify-center'>
            <Logo size='lg' />
            <h5>
              <span className='font-bold text-primary'>Spark</span>
              Hub
            </h5>
          </Link>
          <p className='text-center w-[70%] mt-5'>
            Enter your email address and we will send you a link to reset your
          </p>
        </div>
        {passwordResetSuccess ? (
          <SuccessMsg />
        ) : (
          <>
            <ForgotPasswordForm handleSendCode={forgotPassword} />
            <p className='text-center underline text-gray-400 mt-5'>
              <Link to={routeNames.authentication.login}>Back to Login</Link>
            </p>
          </>
        )}
      </div>
    </div>
  );
});
