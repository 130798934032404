export const ProgramIcon = () => {
  return (
    <svg
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.7207 4.35536H0V1.56858C0 1.32221 0.0978689 1.08593 0.272077 0.911725C0.446284 0.737517 0.682561 0.639648 0.928928 0.639648H15.7918C16.0381 0.639648 16.2744 0.737517 16.4486 0.911725C16.6228 1.08593 16.7207 1.32221 16.7207 1.56858V4.35536ZM0 16.4314V6.21322H16.7207V16.4314C16.7207 16.6778 16.6228 16.9141 16.4486 17.0883C16.2744 17.2625 16.0381 17.3604 15.7918 17.3604H0.928928C0.682561 17.3604 0.446284 17.2625 0.272077 17.0883C0.0978689 16.9141 0 16.6778 0 16.4314ZM2.78678 10.8579H13.9339V9H2.78678V10.8579ZM2.78678 14.5736H8.36035V12.7157H2.78678V14.5736Z'
        fill='#2173F0'
      />
    </svg>
  );
};
