export const InboxIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.8906 16.5898L12.2227 9.80859C12.1133 9.39844 11.7578 9.125 11.375 9.125H2.625C2.21484 9.125 1.85938 9.39844 1.75 9.78125L0.0820312 16.5898C0.0273438 16.8633 0 17.1641 0 17.4375V19.625C0 20.6094 0.765625 21.375 1.75 21.375H12.25C13.207 21.375 14 20.6094 14 19.625V17.4375C14 17.1641 13.9453 16.8633 13.8906 16.5898ZM2.95312 10.4375H11.0195L12.4141 16.125H10.0625C9.78906 16.125 9.57031 16.2891 9.46094 16.5078L8.77734 17.875H5.19531L4.51172 16.5078C4.40234 16.2891 4.18359 16.125 3.9375 16.125H1.55859L2.95312 10.4375ZM12.6875 19.625C12.6875 19.8711 12.4688 20.0625 12.25 20.0625H1.75C1.50391 20.0625 1.3125 19.8711 1.3125 19.625V17.4375H3.52734L4.21094 18.832C4.32031 19.0508 4.53906 19.1875 4.8125 19.1875H9.1875C9.43359 19.1875 9.65234 19.0508 9.76172 18.832L10.4453 17.4375H12.6875V19.625Z'
        fill='white'
      />
    </svg>
  );
};
