import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import SparkyHome from './components/SparkyHome';
import SparkyChatArea from './components/SparkyChatArea';
import AllFAQs from './components/AllFAQs';
import { observer } from 'mobx-react-lite';
import { WidgetConfigStore } from '@/state/WidgetConfigStore';

const AppWidget = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [prevIndex, setPrevIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(
    WidgetConfigStore.should_show_chat ? 1 : 0,
  );
  const navigate = (index: number) => {
    setPrevIndex(currentIndex);
    setCurrentIndex(index);
  };
  const goBack = () => {
    setCurrentIndex(prevIndex);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigation = {
    navigate,
    goBack,
  };

  const routes = [
    { path: 'home', element: <SparkyHome /> },
    { path: 'chat', element: <SparkyChatArea /> },
    { path: 'allfaqs', element: <AllFAQs /> },
  ];

  const handleOpen = () => {
    if (WidgetConfigStore.not_show_widget == true) {
      setIsOpen(false);
    } else {
      setIsOpen(current => !current);
    }
  };

  useEffect(() => {
    if (WidgetConfigStore.not_show_widget) {
      setIsOpen(false);
    } else if (!WidgetConfigStore.not_show_widget) {
      setIsOpen(true);
    }
  });

  useEffect(() => {
    setCurrentIndex(WidgetConfigStore.should_show_chat ? 1 : 0);
  }, [WidgetConfigStore.should_show_chat]);
  return (
    <div className='h-full w-full flex flex-col items-end  justify-end p-[20px]  max-h-[850px] min-h-[400px] overflow-y-hidden'>
      {WidgetConfigStore.not_show_widget == false && (
        <AnimatePresence>
          {isOpen && (
            <div
              className={`relative rounded-[8px] w-full h-full flex  bg-white flex-1 sm:mb-[20px]  sm:w-[384px] overflow-y-hidden z-10 `}
            >
              <div className='p-[8px] rounded-[8px] fixed right-[24px] top-[20px] sm:hidden flex flex-col justify-center items-center cursor-pointer z-50'>
                <div className='rounded-[8px] absolute bg-black opacity-30 w-full h-full'></div>
                <svg
                  className='w-[24px] h-[24px] text-white z-10'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </div>
              <AnimatePresence>{routes[currentIndex].element}</AnimatePresence>
            </div>
          )}
        </AnimatePresence>
      )}
      <div
        onClick={handleOpen}
        className={`bg-headerBgColor absolute -bottom-0 right-0 m-[20px] sm:m-0 cursor-pointer rounded-full flex sm:static  w-[60px] h-[60px] flex-shrink-0  flex-col justify-center items-center`}
      >
        {WidgetConfigStore.not_show_widget}
        {isOpen && (
          <svg
            className='w-[32px] h-[32px] text-chevronColor'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
              clipRule='evenodd'
            ></path>
          </svg>
        )}
        {!isOpen && (
          <div>
            {WidgetConfigStore.config.value.images.widget_logo_url ? (
              <img
                className='h-[32px] w-[32px]'
                src={WidgetConfigStore.config.value.images.widget_logo_url}
                alt='Branding'
              />
            ) : (
              <svg
                className={`w-[32px] h-[32px] text-chevronColor `}
                viewBox='0 0 26 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21.2994 20.3057C21.0032 19.5221 20.3177 18.0303 18.8248 16.731C16.4267 14.6445 13.6769 14.5607 12.9511 14.563C11.8416 14.5654 9.80116 14.7755 7.82454 16.181C5.74262 17.6621 4.92597 19.6224 4.66168 20.3517H7.93891C8.20455 19.8891 8.74966 19.083 9.72687 18.4269C11.208 17.432 12.7174 17.4308 13.2804 17.4603C15.1356 17.5595 16.3558 18.5118 16.763 18.8388C17.4121 19.3604 17.8358 19.9139 18.0919 20.3057H21.3008H21.2994Z'
                  fill='currentColor'
                />
                <path
                  d='M4.94136 14.105C4.94136 14.8297 4.35367 15.4174 3.63011 15.4174C3.23718 15.4174 2.88548 15.2451 2.64587 14.9713L0.535801 12.8458C0.211249 12.6063 0 12.2215 0 11.7872C0 11.0626 0.586552 10.4761 1.31116 10.4761C1.59323 10.4761 1.85527 10.5646 2.06883 10.7168L2.38045 11.0272L4.43022 13.0677L4.6628 13.299C4.83741 13.5209 4.94136 13.8006 4.94136 14.105Z'
                  fill='currentColor'
                />
                <path
                  d='M21.8543 24.992C21.8543 25.732 21.2535 26.3327 20.5125 26.3327H5.49603C4.75615 26.3327 4.15533 25.732 4.15533 24.992C4.15533 24.252 4.75615 23.6501 5.49603 23.6501H20.5125C21.2535 23.6501 21.8543 24.2509 21.8543 24.992Z'
                  fill='currentColor'
                />
                <path
                  d='M13.9141 11.1853C13.402 11.6975 12.5711 11.6975 12.0589 11.1853C11.7817 10.908 11.6542 10.5374 11.6788 10.1739L11.6895 7.17867C11.6292 6.77978 11.752 6.35846 12.0589 6.05162C12.5711 5.53943 13.4007 5.53943 13.913 6.05162C14.1125 6.25107 14.2352 6.4989 14.2787 6.75736V7.19756L14.2859 10.0901V10.4182C14.2529 10.6991 14.129 10.9693 13.9141 11.1853Z'
                  fill='currentColor'
                />
                <path
                  d='M22.3711 15.4174C21.6466 15.4174 21.0587 14.8297 21.0587 14.1062C21.0587 13.7132 21.2311 13.3616 21.5049 13.122L23.6303 11.0119C23.8699 10.6873 24.2546 10.4761 24.689 10.4761C25.4137 10.4761 26 11.0626 26 11.7872C26 12.0693 25.9117 12.3313 25.7593 12.5449L25.449 12.8564L23.4086 14.9064L23.1771 15.1389C22.9554 15.3135 22.6757 15.4174 22.3711 15.4174Z'
                  fill='currentColor'
                />
              </svg>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default observer(AppWidget);
