import {
  ActiveProps,
  CardIssueProps,
  ContactProps,
  FieldProps,
  LoyaltyProps,
  PointEarnedProps,
  RewardProps,
  TermUseProps,
} from '@/types/loyalty.types';
import { makeAutoObservable } from 'mobx';

class LoyaltyStore {
  constructor() {
    makeAutoObservable(this);
  }

  public loyalty: LoyaltyProps | null = null;
  public cardTypeList: LoyaltyProps[] = [];
  public programName = '';
  public bgImage = '';
  public bannerLogo = '';
  public notificationLogo = '';
  public cardDescr = '';
  public cardIssueObj: CardIssueProps[] = [
    {
      title: '',
      type: '',
      isRequired: false,
      isUnique: false,
    },
    {
      title: '',
      type: '',
      isRequired: false,
      isUnique: false,
    },
    {
      title: '',
      type: '',
      isRequired: false,
      isUnique: false,
    },
    {
      title: '',
      type: '',
      isRequired: false,
      isUnique: false,
    },
    {
      title: '',
      type: '',
      isRequired: false,
      isUnique: false,
    },
  ];
  public cardName = '';
  public cardBGcolor = '';
  public textLabelColor = '';
  public textValueColor = '';
  public activeDevice = 0;
  public fieldObj: FieldProps = {};
  public activeLinkObj: ActiveProps = {
    type: '',
    link: '',
    text: '',
  };
  public contactObj: ContactProps = {
    companyName: '',
    companyEmail: '',
    companyNo: '',
  };
  public numberForReferral = 0;
  public numberForReferrer = 0;
  public numberIssueCard = 0;
  public pointEarnsObj: PointEarnedProps = {
    earnedType: 'Spend',
    earnPoints: 0,
    pointToGet: 0,
  };
  public referralProgramType = 0;
  public rewardOfferedObj: RewardProps[] = [
    {
      name: '',
      pointEarn: '',
      type: 'off',
      value: '25.00',
    },
    {
      name: '',
      pointEarn: '',
      type: 'off',
      value: '25.00',
    },
    {
      name: '',
      pointEarn: '',
      type: 'off',
      value: '25.00',
    },
    {
      name: '',
      pointEarn: '',
      type: 'off',
      value: '25.00',
    },
    {
      name: '',
      pointEarn: '',
      type: 'off',
      value: '25.00',
    },
  ];
  public termsObj: TermUseProps = {
    termsData: '',
    termsLink: '',
    legalAge: 0,
  };

  public showTermUse = true;
  public notifyMsg = '';
  public showLogosError = false;
  public showCardTitleError = false;
  public showCardDescripError = false;
  public showCardIssueError = false;
  public showProgramNameError = false;
  public showTermDataError = false;
  public showCompanyNameError = false;
  public showCompanyEmailError = false;
  public showCompanyNoError = false;

  public setLoyalty(loyalty: LoyaltyProps) {
    this.loyalty = loyalty;
  }

  public setCardTypeList(loyalty: LoyaltyProps[]) {
    this.cardTypeList = loyalty;
  }

  public setProgramName(payload: string) {
    this.programName = payload;
  }

  public setBgImage(payload: string) {
    this.bgImage = payload;
  }

  public setBannerLogo(payload: string) {
    this.bannerLogo = payload;
  }

  public setNotificationLogo(payload: string) {
    this.notificationLogo = payload;
  }

  public setCardDescr(payload: string) {
    this.cardDescr = payload;
  }

  public setCardIssueObj(payload: CardIssueProps[]) {
    this.cardIssueObj = payload;
  }

  public setCardName(payload: string) {
    this.cardName = payload;
  }

  public setCardBGcolor(payload: string) {
    this.cardBGcolor = payload;
  }

  public setTextLabelColor(payload: string) {
    this.textLabelColor = payload;
  }

  public setTextValueColor(payload: string) {
    this.textValueColor = payload;
  }

  public setActiveDevice(payload: number) {
    this.activeDevice = payload;
  }

  public setFieldObj(payload: FieldProps) {
    this.fieldObj = payload;
  }

  public setActiveLinkObj(payload: ActiveProps) {
    this.activeLinkObj = payload;
  }

  public setContactObj(payload: ContactProps) {
    this.contactObj = payload;
  }

  public setNumberForReferral(payload: number) {
    this.numberForReferral = payload;
  }

  public setNumberForReferrer(payload: number) {
    this.numberForReferrer = payload;
  }

  public setNumberIssueCard(payload: number) {
    this.numberIssueCard = payload;
  }

  public setPointsEarnsObj(payload: PointEarnedProps) {
    this.pointEarnsObj = payload;
  }

  public setReferralProgramType(payload: number) {
    this.referralProgramType = payload;
  }

  public setRewardOfferedObj(payload: RewardProps[]) {
    this.rewardOfferedObj = payload;
  }

  public setTermsObj(payload: TermUseProps) {
    this.termsObj = payload;
  }

  public setShowTermUse(payload: boolean) {
    this.showTermUse = payload;
  }

  public setNotifyMsg(payload: string) {
    this.notifyMsg = payload;
  }

  public setShowLogosError(payload: boolean) {
    this.showLogosError = payload;
  }

  public setShowCardTitleError(payload: boolean) {
    this.showCardTitleError = payload;
  }

  public setShowCardDescripError(payload: boolean) {
    this.showCardDescripError = payload;
  }

  public setShowCardIssueError(payload: boolean) {
    this.showCardIssueError = payload;
  }

  public setShowProgramNameError(payload: boolean) {
    this.showProgramNameError = payload;
  }

  public setShowTermDataError(payload: boolean) {
    this.showTermDataError = payload;
  }

  public setShowCompanyNameError(payload: boolean) {
    this.showCompanyNameError = payload;
  }

  public setShowCompanyEmailError(payload: boolean) {
    this.showCompanyEmailError = payload;
  }

  public setShowCompanyNoError(payload: boolean) {
    this.showCompanyNoError = payload;
  }
}

export const loyaltyStore = new LoyaltyStore();
