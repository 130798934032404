import { FC, useEffect, useMemo, useState } from 'react';
import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import Input from '@/components/atoms/Input';
import { loyaltyStore } from '@/state/LoyaltyStore';

interface ContactProps {
  companyName: string;
  companyEmail: string;
  companyNo: string;
}

const ContactInfo: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const [contact, setContact] = useState<ContactProps>({
    companyName: '',
    companyEmail: '',
    companyNo: '',
  });

  const [contactError, setContactError] = useState<ContactProps>({
    companyName: '',
    companyEmail: '',
    companyNo: '',
  });

  useEffect(() => {
    setContact(loyaltyStore.contactObj);
  }, []);

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const onSubmit = () => {
    if (
      contact.companyName.trim() == '' ||
      contact.companyEmail.trim() == '' ||
      contact.companyNo.trim() == ''
    ) {
      setContactError({
        ...contactError,
        companyName:
          contact.companyName.trim() == '' ? 'Company name is required' : '',
        companyEmail:
          contact.companyEmail.trim() == ''
            ? 'Company email is required'
            : !validateEmail(contact.companyEmail.trim())
            ? 'Invalid email'
            : '',
        companyNo:
          contact.companyNo.trim() == '' ? 'Phone number is required' : '',
      });
    } else if (!validateEmail(contact.companyEmail.trim())) {
      setContactError({
        ...contactError,
        companyEmail: 'Invalid email',
      });
    } else {
      loyaltyStore.setContactObj(contact);
      setContactError({
        ...contactError,
        companyName: '',
        companyEmail: '',
        companyNo: '',
      });
      handleCancel();
    }
  };

  const onClose = () => {
    setContact(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[9].value
        : {
            companyName: '',
            companyEmail: '',
            companyNo: '',
          },
    );
    loyaltyStore.setContactObj(
      getMode == 'update'
        ? loyaltyStore.loyalty?.backFields[9].value
        : {
            companyName: '',
            companyEmail: '',
            companyNo: '',
          },
    );
    handleCancel();
  };

  const getMode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeParam = urlParams.get('mode');
    return modeParam;
  }, [window.location]);

  return (
    <div className='w-full auto flex flex-col h-full'>
      <div className='w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px]  flex-1'>
          <Input
            value={contact.companyName}
            placeholder='Company name'
            type='text'
            onChange={e => {
              setContact({ ...contact, companyName: e.target.value });
              loyaltyStore.setShowCompanyNameError(false);
            }}
          />
        </div>
        {contactError.companyName && (
          <p className='mb-[6px] mt-1' style={{ color: 'red' }}>
            {contactError.companyName}
          </p>
        )}
      </div>

      <div className='w-full mb-[24px]'>
        <div className='flex'>
          <div className='flex flex-col mr-[24px] flex-1'>
            <div>
              <Input
                value={contact.companyEmail}
                placeholder='Email'
                type='text'
                onChange={e => {
                  setContact({ ...contact, companyEmail: e.target.value });
                  loyaltyStore.setShowCompanyEmailError(false);
                  if (!validateEmail(e.target.value.trim())) {
                    setContactError({
                      ...contactError,
                      companyEmail: 'Invalid email',
                    });
                  } else {
                    setContactError({
                      ...contactError,
                      companyEmail: '',
                    });
                  }
                }}
              />
              {contactError.companyEmail && (
                <p className='mb-[6px] mt-1' style={{ color: 'red' }}>
                  {contactError.companyEmail}
                </p>
              )}
            </div>
          </div>

          <div className='flex flex-col flex-1'>
            <div>
              <Input
                value={contact.companyNo}
                placeholder='Phone number'
                type='text'
                onChange={e => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setContact({ ...contact, companyNo: e.target.value });
                    loyaltyStore.setShowCompanyNoError(false);
                  }
                }}
              />
              {contactError.companyNo && (
                <p className='mb-[6px] mt-1' style={{ color: 'red' }}>
                  {contactError.companyNo}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          onClick={onClose}
          variant='outline'
        />
        <Button
          type='button'
          text={'Save'}
          size='sm'
          className='bg-lightBlue'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
export default observer(ContactInfo);
